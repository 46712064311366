import * as React from 'react';
// @ts-ignore
import logoSrc from '../../assets/svg/dcodeit.svg';
import Navigation from '../navigation';
import { Link } from 'react-router-dom';
import { isIE} from "../../utils";
import SVG from 'react-inlinesvg';
import './index.less';

export default ({isMobile,isNavOpen}) => {
	const e = new CustomEvent('scrollToBeginningOfPage')
	return <header
		style={isIE() ? {display: 'flex', flexDirection: 'row', alignItems: 'center'} : null }
		id={'main-header'}
		className={'main-header'}>
		<Link className={'header-logo'} to={'/'} onClick={() => window.dispatchEvent(e)}>
			{isIE() ? <img style={{width: '11.4rem', height: '2.88rem'}} onClick={() => window.postMessage('CLOSE_NAV', '*')} src={logoSrc}/> :	<SVG onClick={() => window.postMessage('CLOSE_NAV', '*')} alt={'d:code:it'} width={114} src={logoSrc}/> }
		</Link>
		<Navigation isMobile={isMobile} isNavOpen={isNavOpen}/>
	</header>;
}