import * as React from 'react';
import './index.less';
import {isIE, isIPad, isMobile} from "../../../utils";

function splitIntoWords(div) {
	function removeEmptyStrings(k) {
		return k !== '';
	}
	const rWordBoundary = /[\s\n\t]+/; // Includes space, newline, tab
	const output = [];
	for (let i = 0; i < div.childNodes.length; ++i) { // Iterate through all nodes
		const node = div.childNodes[i];
		if (node.nodeType === Node.TEXT_NODE) { // The child is a text node
			const words = node.nodeValue.split(rWordBoundary).filter(removeEmptyStrings);
			if (words.length) {
				output.push.apply(output, words);
			}
		} else if (node.nodeType === Node.COMMENT_NODE) {
			// What to do here? You can do what you want
		} else {
			output.push(node.outerHTML);
		}
	}
	return output;
}
interface IHState {
	wordCount: number;
	isMobile: boolean;
	words: string[];
}
interface IHProps {
	type: number;
	s?: string;
	className?: string;
	tabIndex?: number;
}
class H extends React.PureComponent<IHProps, IHState> {
	public heading = null;
	public tag = null;
	public readonly state: IHState = {
		wordCount: 0,
		isMobile: window.innerWidth < 768,
		words:[]
	};

	constructor(props: IHProps) {
		super(props);
		this.tag = 'h' + props.type;
	}
	public resize = (e) => {
		if (window.innerWidth < 768) {
			this.setState({isMobile: true});
		} else {
			this.setState({isMobile: false});
		}
	}
	public componentDidMount(): void {
		window.addEventListener('resize', this.resize, false);
		this.createSpannedHeading();
	}
	public componentWillUnmount(): void {
		window.removeEventListener('resize', this.resize, false);

	}

	public createSpannedHeading() {
		if (isMobile() || isIE() || isIPad()) {

			this.setState({words:[$(this.heading).html()]})

		}else {
			const spannedHeading = splitIntoWords(this.heading).map((w, i) => {
				const h = $(this.heading).height();
				if(w.match(/<[^>]*>/g)) return w;
				return `<span class='__spanned__word'><span class='__spanned__word_content word-${i}'>${w}</span></span>`;
			});
			this.setState({wordCount: spannedHeading.length,words:spannedHeading});
		}

	}

	public render() {

		const Comp = this.tag;
		const separator = this.props.s ? <div className={'with-animation-delay-' + this.state.wordCount + ' ' + this.props.s}></div> : null;
		return <>
			<Comp
				ref={(ref) => this.heading = ref}
				className={`${this.props.className} sr-only`}
				aria-hidden={true}
			>
				{this.props.children}

			</Comp>
			{this.state.words.length > 0 &&
			<Comp className={this.props.className} dangerouslySetInnerHTML={{ __html: this.state.words.join(' ') }} >
			</Comp>}
			{separator}
		</>;
	}
}

interface IAnyHProps {
	s?: string;
	className?: string;
	tabIndex?: number;
	children?: any;
}

export const H1 = ({s, className, tabIndex, children}: IAnyHProps) => <H type={1} s={s} className={className} tabIndex={tabIndex}>{children}</H>;
export const H2 = ({s, className, tabIndex, children}: IAnyHProps) => <H type={2} s={s} className={className} tabIndex={tabIndex}>{children}</H>;
export const H3 = ({s, className, tabIndex, children}: IAnyHProps) => <H type={3} s={s} className={className} tabIndex={tabIndex}>{children}</H>;
export const H4 = ({s, className, tabIndex, children}: IAnyHProps) => <H type={4} s={s} className={className} tabIndex={tabIndex}>{children}</H>;
export const H5 = ({s, className, tabIndex, children}: IAnyHProps) => <H type={5} s={s} className={className} tabIndex={tabIndex}>{children}</H>;
export const H6 = ({s, className, tabIndex, children}: IAnyHProps) => <H type={6} s={s} className={className} tabIndex={tabIndex}>{children}</H>;
