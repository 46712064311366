import * as React from 'react';

import Page from '../../components/page';
import { SectionProps } from '../../lib/types/section';

import './index.less';
import '../../lib/styles/plyr.css';

import Apps from './apps';
import { solutions } from '../../data/pages';
import SVG from 'react-inlinesvg';

class Solutions extends React.Component<any, {}> {

	public render() {

		const sections = solutions.items.map((s, i) => {
			const apps = s.appsSection && s.appsSection.title && s.appsSection.apps ? <Apps page={s}/> : null;
			const section: SectionProps = {

				id: s.id,
				title: s.name,
				subscroll: true,
				color: 'white',
				content: <>
					<SVG className={'solutions-mobile-icon'} src={s.icon} style={{maxWidth: '50px', maxHeight: '50px'}}/>
					<h2>{s.name}</h2>
					<div className={'separator s blue'}></div>
					<p>{s.s1h1}</p>
					{apps}
			</>,
			};
			return section;
		});

		return <Page
			isMobile={this.props.isMobile}
			id={'solutions-mobile-page'}
			sections={sections}
		/>;

	}
}

export default Solutions;
