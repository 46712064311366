import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import NavData, { routeData } from './data/pages';

import 'react-perfect-scrollbar/dist/css/styles.css';
import Header from './components/header';
import AcceptCookie from './components/accept-cookie';
import './lib/pagepiling/jquery.pagepiling';
import './lib/pagepiling/jquery.pagepiling.less';
import './index.less';

import ClassSet from './utils/classSet';
import { getBreakPoints } from './utils/mediaQuery';

import {isMobile, isIPad, isPortrait, isIE, isIPhone, isFirefox, isSafari} from "./utils";
import Page404 from './pages/404';
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'custom-event-polyfill';
import 'url-polyfill';
import Wheel from "./components/wheel";
import { AppContext } from "./components/app-context";
require('objectFitPolyfill');

(window as any).__PAGES__ = NavData;
(window as any).scrollingSpeed = 700;
(window as any).scrollAnimationDelay = 200;

if (isMobile()) {
	const MobileCss =  require('./mobile.less');
}
/*(window as any).mp = function(){
	if(typeof performance){
		const start = performance.now();
		for(let i = 0; i < 1000; i++){
			createHash();
		}
		console.log("performance: ", (performance.now() - start));
	}

}
function createHash(){
	var hash = 0;
	var source = 'eyg0RoCSw4wnO3hd23poMSCO6dFfOyVTOqODFQURkDlYQELGGf8Wa0G4bgK2efpDimfkaemNnC8Ihh50slWN6ACDaxflAvZRqBHUcg04U5';
	for (var i = 0; i < source.length; i++) {
		var char = source.charCodeAt(i);
		hash = ((hash<<5)-hash)+char;
		hash = hash & hash;
	}
	return hash;
}*/
const find = require('lodash/fp/find');
interface IAppContainerProps {
	isMobile: boolean;
}
const AppContainer: React.FC<IAppContainerProps> = ({isMobile, children}) => {
	const [currentSection, setCurrentSection] = React.useState(0)
	return <AppContext.Provider value={{
		currentSection,
		setCurrentSection,
		isMobile
	}}>{children}</AppContext.Provider>
}
class App extends React.Component {
	public state = {
		isMobile: isMobile(),
		isMobileNavOpen:false
	};
	public scrollingTimeout = undefined;
	public deltaTime = 0;
	public prevEventTime = 0;
	public scrollings = 0;
	constructor(props: any) {
		super(props);
		if(performance) {
			const start = performance.now();
			this.makeHash('dcodeit.com');
			const end = performance.now();
		}
	}
	private makeHash(source) {
		var hash = 0;
		if (source.length === 0) return hash;
		for (var i = 0; i < source.length; i++) {
			var char = source.charCodeAt(i);
			hash = ((hash<<5)-hash)+char;
			hash = hash & hash; // Convert to 32bit integer
		}
		return hash;
	}
	public componentDidMount(): void {
		this.setState({isMobile: isMobile()});
		const appHeight = () => {
			const doc = document.documentElement
			doc.style.setProperty('--app-height', `${window.innerHeight}px`)
		}
		window.addEventListener('resize', () => {
			this.setState({isMobile: isMobile()});
			appHeight();

		});
		appHeight()

	}

	setMobileNavStatus =(isOpen:boolean)=>{
		this.setState({isMobileNavOpen:isOpen})
	}

	public renderComp(props, Comp, item) {
		let metaImage = require('./assets/images/open-graph/dcodeit-share-004@2x.jpg');
		if (item.metaImage) {
			metaImage = item.metaImage;
		}
		document.querySelector('head title').innerText = item.metaTitle;
		document.querySelector('meta[name="description"]').setAttribute('content', item.metaDescription);
		document.querySelector('meta[property="og:title"]').setAttribute('content', item.metaTitle);
		document.querySelector('meta[property="og:description"]').setAttribute('content', item.metaDescription);
		document.querySelector('meta[property="og:image"]').setAttribute('content', metaImage);
		return <Comp data={item} isMobile={this.state.isMobile}/>;
	}

	public detectScroll() {
		const wheel = (e) => {
			const target = e.target;
			if (this.deltaTime > 100 || this.scrollings > 100) {
				let direction;
				this.scrollingTimeout = undefined;
				const delta = e.deltaY;
				if (delta > 0) {direction = 'down'; } else {direction = 'up'; }
				const Event = new CustomEvent('mousewheelstart', {bubbles: true, detail: {dir: direction, deltaTime: this.deltaTime}, target});
				window.dispatchEvent(Event);
				this.scrollings = 0;

			}

			this.deltaTime = e.timeStamp - this.prevEventTime;
			this.scrollings ++;
			this.prevEventTime = e.timeStamp;
			return false;
		};
		window.addEventListener('wheel', wheel, false);

	}
	public render() {
		const breakPoint = getBreakPoints();
		const classes = ClassSet({
			[breakPoint]: true,
			'app-layout': true,
			'is-mobile': this.state.isMobile,
			'is-ipad': isIPad() && isPortrait(),
			'is-ipad-landscape': isIPad() && !isPortrait(),
			'is-ie': isIE(),
			'is-iphone': isIPhone(),
			'is-ff': isFirefox(),
			'is-safari': isSafari(),
			'is-mobile-nav-open':this.state.isMobileNavOpen
		});
		const classesContainer = ClassSet({
			[breakPoint]: true,
			'app-main-container': true,
			'is-mobile': this.state.isMobile,
			'is-ipad': isIPad() && isPortrait(),
			'is-ipad-landscape': isIPad() && !isPortrait(),
			'is-ie': isIE(),
			'is-iphone': isIPhone(),
			'is-ff': isFirefox(),
			'is-safari': isSafari(),
			'is-mobile-nav-open':this.state.isMobileNavOpen
		});

		return <AppContainer isMobile={this.state.isMobile}><Router>
			<div className={classesContainer}>
				<Header isMobile={this.state.isMobile} isNavOpen={this.setMobileNavStatus}/>
			<div className={classes}>

				<Route render={({ location }) => {
					const allParents = [];
					const allChildren = [];

					routeData.forEach((item) => {
						const path = item.path || '/' + item.id;
						const Comp = this.state.isMobile && item.compMobile ? item.compMobile : item.comp;
						if (item.items) {
							item.items.forEach((subItem) => {
								const subPath = subItem.path || '/' + item.id + '/' + subItem.id;
								const SubComp = this.state.isMobile && subItem.compMobile ? subItem.compMobile : subItem.comp;
								allChildren.push(<Route strict exact key={subItem.id} path={subPath} render={(props) => this.renderComp({...props, ...this.state}, SubComp, subItem)} data={subItem}/>);
							});
						}
						allParents.push(<Route strict exact key={item.id} path={path} isMobile={this.state.isMobile} render={(props) => this.renderComp({...props, ...this.state}, Comp, item)} data={item}/>);
					});

					return <>
						<Switch>
							{allChildren}
							{allParents}
							<Route component={Page404}/>
						</Switch>
					</>;
				}}/>
				<AcceptCookie/>
				<Wheel isMobile={this.state.isMobile}/>
			</div>
			</div>
		</Router></AppContainer>;

	}

}

ReactDOM.render(<App/>, document.getElementById('app-container'));
