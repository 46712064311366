import * as React from 'react';
import classSet from 'react-classset';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { Portal } from 'react-portal';
import { withRouter } from 'react-router';
import ProductCard from './../product-card';
import { Products } from './../../data/products';
import SVG from 'react-inlinesvg';
import './index.less';
import './mobile.less';
import List from './List';
import {solutions} from "../../data/pages";
import SolutionCard from "../solution-card";
import {isIE, isMobile, isTouch} from "../../utils";
import {isEqual} from 'lodash'

interface NavigationState {
	subNavs: any;
	isMobile: boolean;
	clicked: boolean;
}

class Navigation extends React.PureComponent<any, NavigationState> {
	public state: NavigationState = {
		subNavs: {
			products: { visible: false },
			solutions: { visible: false }
		},
		isMobile: false,
		clicked: false,
	};

	constructor(props) {
		super(props);

		//this.animate = this.animate.bind(this);
		this.itemOnMouseEnter = this.itemOnMouseEnter.bind(this);
		this.onBodyClick = this.onBodyClick.bind(this);
		this.closeLayers = this.closeLayers.bind(this);
		(window as any).closeAllNavigationLayers = this.closeLayers.bind(this)
	}
	/*shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<NavigationState>, nextContext: any): boolean {
		if(!isEqual(this.state, nextState) || this.props.isMobile !== nextProps.isMobile) {
			return true;
		}
		return false
	}*/

	public componentDidUpdate(prevProps, prevState) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			//this.animate();
			if (this.props.isMobile) {
				window.scrollTo(0, 0);
			}
		}
		//window.addEventListener('resize', this.animate, false);
		if(this.props.isMobile && this.state.clicked !== prevState.clicked){
			const method = this.state.clicked ? 'add' : 'remove'
			document.querySelector('body')?.classList[method]('mobile-navigation-open')
		}

	}

	private onBodyClick({target}) {
		if ($(target).parents('#navigation-portal-container').length === 0 && $(target).parents('#navigation-item-products').length === 0 && $(target).parents('#navigation-item-solutions').length === 0) {
			this.closeLayers();
		}
	}
	public componentDidMount() {
		window.addEventListener('message', (event) => {
			if (event.data === 'CLOSE_NAV' && this.props.isMobile) {
				this.handleClose();
				this.setState({ clicked: false });
			}
		});
		//setTimeout(this.animate, 200);
		window.addEventListener('keyup', (e) => {
			if (e.key?.toLowerCase() === 'Escape'.toLowerCase()) {
				this.closeLayers();
			}
		}, false);
		window.addEventListener('click', this.onBodyClick, false);

	}

	renderSolutionsNav(item){
		return item.items.map((el, index) => {
			const item = <SolutionCard count={index} key={index} id={el.id} description={el.name} icon={el.icon} text={el.s1h1}/>;
			return item;
		})
	}

	private renderChildren(item) {

		const node = document.getElementById('navigation-portal-container');

		if (!item.items || !Array.isArray(item.items)) { return null; }

		return <Portal node={node} key={`portal-item-${item.id}`}>
			<div key={`secondary-item-${item.id}`} className={(
				(this.state.subNavs[item.id]?.visible) ? 'visible ' : '') + 'secondary-navigation-container ' + item.id + (this.props.isMobile ? ' mobile' : '')}>
				<ul className={'sub-nav'} id={'sub-nav-' + item.id}>
					{this.props.isMobile && item.id === 'products' ?
						<div className={'column'}>
							{item.items.map((el, index) => {
									const card = Products.find((prod) => prod.product.toLowerCase() === el.id);
										return <ProductCard className="mobile" handleClick={this.handleClose} key={index} {...card} isMobile />;
								})
							}
						</div>
					: null}
					{!this.props.isMobile ?
						<>
							<div className={'row 1'}>
							{item.items.map((el, index) => {
								if (index <= 2) {
									if(item.id === 'products') {
										const card = Products.find((prod) => prod.product.toLowerCase() === el.id);
										return <ProductCard className="header" handleClick={this.handleClose} key={index} {...card} isLink/>;
									} else if(item.id === 'solutions') {
										return <SolutionCard count={index} key={el.id} id={el.id} description={el.name} icon={el.icon} text={el.h1} nav={true}/>
									}

								}
								return;
							})}
							</div>
							<div className={'row 2'}>
							{item.items.map((el, index) => {
								if (index > 2) {
									if (item.id === 'products') {
										const card = Products.find((prod) => prod.product.toLowerCase() === el.id);
										return <ProductCard className="header" handleClick={this.handleClose}
															key={index} {...card} isLink/>;
									} else if (item.id === 'solutions') {
										return <SolutionCard count={index+3} key={el.id} id={el.id} description={el.name} icon={el.icon} text={el.h1} nav={true}/>
									}
								}
								return;
								})}
							</div>
						</>: null }

					{/*!this.props.isMobile && item.id === 'solutions' ? this.renderSolutionsNav(item) : null*/}
					{/*item.items.map((el, index) => {
						const style = el.navigationBgImage ? { 'backgroundImage': 'url(' + el.navigationBgImage + ')' } : {};
						const icon = el.icon ? <SVG width={60} height={60} src={el.icon}/> : null;
						const selected = this.isSelected(el);
						if(item.id !== 'products' && item.id!=='solutions') {
							return <List key={index} selected={selected} el={el} style={style} item={item} handleClick={this.handleClose}/>;
						}
					})*/}

				</ul>
			</div>
		</Portal>;
	}

	public handleClose = () => {

		setTimeout(() => {
			this.closeLayers()
			this.setState({clicked: false})
		}, 300);
	}

	private closeLayers(cb?: () => void) {
		const state = { ...this.state };

		this.setState({
			...this.state,
			subNavs: {
				products: {
					visible: false,
				},
				solutions: {
					visible: false,
				}
			}
		},() => {
			if (cb && typeof cb === 'function') { cb(); }
		})
	}

	private isSelected(item): boolean {
		const products = (window as any).__PAGES__[1].items?.map(p => p.id);

		const currentPath = this.props.location.pathname;
		const regExp = new RegExp(item.id);

		if(item.id === 'products' && products && products.some(p => {
			const rg = new RegExp(p);
			const result = rg.test(currentPath);
			//console.error(rg, result);
			return result;
		})) {
			return true;
		}
		return regExp.test(currentPath);
	}

	private itemOnMouseEnter(item) {
		this.closeLayers(() => {
			if (!item || !item.id) { return; }


			this.setState({
				...this.state,
				subNavs: {
					...this.state.subNavs,
					[item.id]: {
						visible: true
					}
				}
			},() => {
				const itemToFOcus = $('.secondary-navigation-container.' + item.id + ' button, .secondary-navigation-container.' + item.id + ' a')[0];
				if (itemToFOcus) { itemToFOcus.focus(); }
			})
		});

	}

	private itemOnMouseLeave(item, e) {
		if (!item || !item.id) { return; }
		if (e && e.target && $(e.target).hasClass('navigation-item')) { return; }

		this.setState({
			...this.state,
			subNavs: {
				...this.state.subNavs,
				[item.id]: {
					visible: false
				}
			}
		},() => {
			$('#navigation-item-' + item.id).focus();
		})

	}
	private reloadRoute(route: string){
		this.closeLayers()
	}
	public renderMobile() {
		if (!this.props.isMobile) { return null; }
		const classes = classSet({
			'navigation-mobile': true,
			'primary-mobile-navigation': true,
			'active': this.state.clicked,
		});
		const node = document.getElementById('navigation-portal-container');
		const navItems = (window as any).__PAGES__.filter((item) => item.hideInNav !== true).map((item) => {
			const selected = this.isSelected(item);
			const isClientPortal = item.id === 'client-portal';
			return <li
				key={item.id}
				id={'navigation-item-' + item.id}
				onClick={()=>this.setState({clicked: false})}
				className={'navigation-item' + (selected ? ' selected' : '') + (isClientPortal ? ' client-portal': '')}>
				{item.items?.length ? <a style={{ cursor: 'pointer' }}>{item.name}</a> :
					<Link onClick={()=>this.reloadRoute( item.path || '/' + item.id)} to={(item.path || '/' + item.id)}>{item.name}</Link>}
				{this.renderChildren(item)}
			</li>;
		});
		const ButtonAsset = (props: any) => isIE() ? <img {...props}/> : <SVG {...props}/>
		const src = isIE() && this.state.clicked ? require('../../assets/svg/navigation-close.svg') : require('../../assets/svg/navigation.svg')
		return <nav className={classes}>
			<button type={'button'} onClick={() => this.setState({ clicked: !this.state.clicked },()=>{this.props.isNavOpen(this.state.clicked)})}>
				<ButtonAsset alt={'navicon'} id={'navicon'} src={src}/>
			</button>
			<Portal node={node}>
				<div className={'mobile-navigation-main-layer' + (this.state.clicked ? ' visible' : '')}>
					<ul>{navItems}</ul>
				</div>
			</Portal>
		</nav>;
	}

	public renderDesktop() {

		if (this.props.isMobile) { return null; }
		const classes = classSet({
			'navigation': true,
			'primary-navigation': true,
		});

		const navItems = (window as any).__PAGES__.filter((item) => item.hideInNav !== true).map((item) => {
			const selected = this.isSelected(item);
			const hasPopup = item.items && item.items.length;
			const opensOverlay = hasPopup ? <span className={'sr-only'}>(opens overlay)</span> : null;
			const isClientPortal = item.id === 'client-portal';

			const onMenuItemClick = () => {
				if (!item.items || !item.items.length) {
					this.closeLayers();
				}
				this.itemOnMouseEnter(item);
			}
			if(!(window as any).navigationItemClickHandler) {
				(window as any).navigationItemClickHandler = {};
			}
			(window as any).navigationItemClickHandler[item.id] = onMenuItemClick;
			return <li
				onMouseEnter={(e) => this.itemOnMouseEnter(item)}
				onMouseLeave={(e) => this.itemOnMouseLeave(item, e)}
				key={item.id}
				id={'navigation-item-' + item.id}
				onClick={isTouch() ? onMenuItemClick: () => {}}
				aria-selected={selected}
				aria-haspopup={hasPopup}
				className={'navigation-item' + (selected ? ' selected' : '') + (isClientPortal ? ' client-portal' : '')}>
				{item.items?.length && isTouch() ? <a style={{ cursor: 'pointer' }}>{item.name}</a> : <Link onClick={this.closeLayers} to={(item.path || '/' + item.id)}>{item.name}{opensOverlay}</Link>}
				{this.renderChildren(item)}
			</li>;
		});
		return <nav className={classes} aria-label={'Main'}>
			<ul>{navItems}</ul>

		</nav>;
	}

	public render() {
		return this.props.isMobile ? this.renderMobile() : this.renderDesktop();
	}
}

export default withRouter(Navigation);
