import * as React from 'react';

import './products.less';
import Classet from '../../../utils/classSet';
import Button from '../../../components/button';
import { isMobile, isSquare } from '../../../utils';
import { useHistory } from 'react-router';
import { SlidingText } from '../../../components/sliding-text/SlidingText';
import {H2} from "../../../components/animated-container/h";

interface IActiveSectionChangeEvent extends Event {
	detail?: {
		activeSection: number,
	};
}
interface IProduct {
	id: number;
	label: string;
	description: string;
	headline: string;
	headlineSplit: string[];
	color: 'dark-blue' | 'yellow' | 'blue' | 'purple' | 'green' | 'orange';
}
let interval: ReturnType<typeof setInterval>;

export const Products = React.memo(() => {
	let containerRef:HTMLDivElement|null = null;
	const products: IProduct[] = [
		{
			id: 0,
			label: 'Arthos',
			description: 'A 360 approach to transformation.',
			headline: 'The workspace built for the enterprise.',
			headlineSplit: ['The workspace built for', 'the enterprise.'],
			color: 'dark-blue',
		},
		{
			id: 1,
			label: 'Matrix',
			description: 'Data interaction of the future.',
			headline: 'Making sense of big data.',
			//headlineSplit: ['Stream enterprise','data sets.'],
			headlineSplit: ['Data interaction of the future.'],
			color: 'yellow',
		},
		{
			id: 2,
			label: 'Intro-X',
			description: 'Digital adoption platform.',
			headline: 'Increase adoption rates.',
			headlineSplit: ['Increase adoption rates.'],
			color: 'blue',
		},
		{
			id: 3,
			label: 'Analytix',
			description: 'Real-time analytics and insights.',
			headline: 'Make informed decisions.',
			headlineSplit: ['Make informed decisions.'],
			color: 'purple',
		},
		{
			id: 4,
			label: 'Axcept',
			description: 'Automated UI testing at scale.',
			headline: 'Deliver with confidence.',
			headlineSplit: ['Deliver with confidence.'],
			color: 'green',
		},

		{
			id: 5,
			label: 'Nexus',
			description: 'Deployment approval platform.',
			headline: 'Unify your applications.',
			headlineSplit: ['Unify your applications.'],
			color: 'orange',
		},
	];

	const history = useHistory();
	const [selectedId, setSelectedId] = React.useState(0);

	React.useEffect(() => {
		window.addEventListener('ActiveSectionChange', sectionChange);
		window.addEventListener('swipe', onSwipe);
		window.addEventListener('resize', resize);

		return () => {
			stopInterval();
			window.removeEventListener('ActiveSectionChange', sectionChange);
		};
	}, []);

	const startInterval = () => {
		// @ts-ignore
		interval = undefined
		if (!interval) {
			interval = setInterval(() => {
				setSelectedId((id) => {
					if (id >= products.length - 1) {
						return 0;
					} else {
						return id + 1;
					}
				});
			}, 5000);
		}
	};
	const resize = () => {
		const pc = document.getElementById('homepage-products-container')
	}
	const stopInterval = () => {
		if (interval) {
			clearInterval(interval);
		}
	};

	const select = (id: number, product: string) => {
		stopInterval();

		if (isMobile()) {
			redirect(product);
		} else {
			setSelectedId(id);
		}
	};

	const redirect = (product: string) => {
		return history.push('/' + product.toLowerCase());
	};

	const sectionChange = (e: IActiveSectionChangeEvent) => {
		if (e.detail && e.detail.activeSection === 3) {
			startInterval();
		} else {
			stopInterval();
		}
	};

	const productsClass = Classet({
		'products': true,
		'square': isSquare(),
	});
	const onSwipe = (e) => {
		const selectedPId = parseInt((document.getElementById('product-navigation-selected-product-id')?.getAttribute('data-id') as string), 10)
		if(selectedPId !== undefined && selectedPId !== null) {
			let foundIndex = undefined
			const selectedProduct = products.filter((p, index) => {
				if(p.id === selectedPId) {
					foundIndex = index;
					return true;
				}
				return false;
			});
			let nextProduct = undefined;
			if(foundIndex !== undefined) {
				if(e?.detail?.dir === 'left') {
					nextProduct = products[foundIndex + 1]
				}
				if(e?.detail?.dir === 'right') {
					nextProduct = products[foundIndex - 1]
				}
				if(nextProduct) {
					return select(nextProduct.id, nextProduct.label)
				}
			}
			return false;

		}
		//console.error(selectedPId, selectedId)
	}
	return (
		<>
			<div className={productsClass} id={'homepage-products-container'}>
				{!isMobile() &&
					<div className="products-carousel">
						<div className={`products-wrapper id-${selectedId} row-0`}>
							{products.map((product) => {
								return (
									<div id={`products-${product.label.toLowerCase()}`} key={product.id} className={'products-section row-0'}>
										<div className={'products-image'}></div>
									</div>
								);
							})}
						</div>
						<div className={`products-wrapper id-${selectedId} row-1`}>
							{products.map((product) => {
								return (
									<div id={`products-${product.label.toLowerCase()}`} key={product.id} className={'products-section row-1'}>
										<div className={'products-image'}></div>
									</div>
								);
							})}
						</div>
						<div className={`products-wrapper id-${selectedId} row-2`}>
							{products.map((product) => {
								return (
									<div id={`products-${product.label.toLowerCase()}`} key={product.id} className={'products-section row-2'}>
										<div className={'products-image'}></div>
									</div>
								);
							})}
						</div>
						<div className={`products-wrapper id-${selectedId} row-3`}>
							{products.map((product) => {
								return (
									<div id={`products-${product.label.toLowerCase()}`} key={product.id} className={'products-section row-3'}>
										<div className={'products-image'}></div>
									</div>
								);
							})}
						</div>
						<div className={`products-wrapper id-${selectedId} row-4`}>
							{products.map((product) => {
								return (
									<div id={`products-${product.label.toLowerCase()}`} key={product.id} className={'products-section row-4'}>
										<div className={'products-image'}></div>
									</div>
								);
							})}
						</div>
						{products.map((product) => {
							return (
								<div className={'products-content'} key={`product-${product.id}`} id={`pc-product-${product.id}`}>
									<SlidingText active={product.id === selectedId} className="label">
										<h2 className="products-label">{product.label}</h2>
									</SlidingText>
									<SlidingText active={product.id === selectedId}>
										<div className={'separator s ' + product.color} />
									</SlidingText>
									<SlidingText active={product.id === selectedId}>
										<h3 className="products-headline"><span className={'title-line'}>{product.headlineSplit[0]}</span><span className={'title-line'}>{product.headlineSplit[1]}</span></h3>
									</SlidingText>
									<SlidingText active={product.id === selectedId}>
										<Button color={products[selectedId].color} className={'small'} label={'DISCOVER MORE'}
											onClick={() => redirect(products[selectedId].label)} />
									</SlidingText>
								</div>
							);
						})}
					</div>
				}
				<div className="products-navigation">
					{!isMobile() &&
						<div id={"product-navigation-selected-product-id"} data-id={selectedId} className={`products-selector id-${selectedId}`}></div>
					}
					{products.map((product) => {
						return (
							<>
								<div onClick={() => select(product.id, product.label)} className={"product-tab " + (selectedId === product.id ? 'selected': '')} id={`product-tab-${product.label.toLowerCase()}`} key={product.id}>
									<p className="label">{product.label}</p>
									<div className={'separator s ' + product.color} />
									<p className={'description'}>{product.description}</p>
								</div>
								<div onClick={() => redirect(product.label)} className={"product-tab mobile " + (selectedId === product.id ? '' : '')} id={`product-tab-${product.label.toLowerCase()}`} key={product.id + '-mobile'}>
									<p className="label">{product.label}</p>
									<div className={'separator s ' + product.color} />
									<p className={'description'}>{product.description}</p>
								</div>
							</>
						);
					})}
				</div>
			</div>
		</>
	);
});
