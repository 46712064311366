import * as React from 'react';
import ProductCard from './../product-card';
import SolutionCard from './../solution-card';
import { solutions } from '../../data/pages';
// @ts-ignore
import './index.less';
import Button from '../button';
import { withRouter } from 'react-router-dom';
import { H2 } from '../animated-container/h';

const solutionsExploreGrid = []; //window['__PAGES__'].solutions.items

interface ExploreSolutionsProps {
	selected: string;
	isMobile: boolean;
	hideTitle:boolean
}

class ExploreSolutions extends React.Component<ExploreSolutionsProps, {}> {
	constructor(props: ExploreSolutionsProps) {
		super(props);
	}

	public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

		if (this.props.isMobile) {
			return <div id={'explore-solutions'}>
				<div>
					<Button color={'white'} className={'small'} label={'EXPLORE SOLUTIONS'}
							onClick={() => this.props.history.push('/solutions')}/>
				</div>
			</div>;
		}
		return <div id={'explore-solutions'}>
			{!this.props.hideTitle && <div className={'header-section'}>
				<H2 s={'separator l blue'} className="header">
					Explore solutions
				</H2>
			</div>}
			<div className={'solutions-grid'}>
				{solutions.items.filter((s) => s.id !== this.props.selected).map((solution, i) => {
					const item = <SolutionCard count={i} key={i} id={solution.id} description={solution.name} icon={solution.icon} text={solution.s1h1}/>;
					return item;
				})}
			</div>
		</div>;
	}
}

export default withRouter(ExploreSolutions);
