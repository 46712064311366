import * as React from 'react';
import Page from '../../components/page';
import './index.less';
import { contactData } from '../../data/pages';
import { H1 } from '../../components/animated-container/h';

export default class Contact extends React.Component {
	constructor(props) {
		super(props);
	}

	public render():
		| React.ReactElement<any, string | React.JSXElementConstructor<any>>
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {
		const { addresses, emails } = contactData;
		const renderAddresss = addresses.map((a, index) => {
			const p = a.lines.map((l, index2) => <p className={'l'} key={index2}>{l}</p>);
			return (
				<div
					className={`contact ${a.title.toLowerCase()}`}
					key={index}
				>
					<h2 className="s">{a.title}</h2>
					<div className={'separator sx blue'}/>
					{p}
				</div>
			);
		});

		const renderEmail = emails.map((e, index) => <a key={index} href={'mailto: ' + e}>{e}<span className={'bg'}>{e}</span></a>);
		const contact = (window as any).__PAGES__.find((el) => el.id === 'contact');
		return (
			<Page
				isMobile={this.props.isMobile}
				id="contact-us"
				sections={[
					{
						id: 'main',
						title: 'Get in touch.',
						color: 'white',
						className: 'full-page',
						content: (
							<>
								<div className="main-section">
									<div className="headline">
										<div className="email-wrapper">
										<H1 tabIndex={-1}>{contact.h1}</H1>
										{renderEmail}
										</div>
									</div>
									<div className="contact-box">
										{renderAddresss}
									</div>
								</div>
							</>
						),
					},
				]}
			/>
		);
	}
}
