import * as React from 'react';
import './index.less';
interface IButtonProps {
	color: string;
	onClick: (e: any) => void;
	label: string;
	loading?: boolean;
	disabled?: boolean;
	className?: string;
	xLarge?: boolean;
	type?: 'button'| 'submit' |'reset';
	style?: React.CSSProperties;
}
export default ({color, onClick, label, loading, disabled, className, xLarge,type, style}: IButtonProps) => (
	<button
		type={type?type:'button'}
		style={style}
		onClick={(event) => {
			//const parent = $(target).parents('button')
			const target = event.target;
			$(target).addClass('clicked');
			setTimeout(() => $(target).removeClass('clicked'), 1000);
			if(type=='submit'){
				onClick(event)
			} else {
				onClick({target});
			}
		}}
		className={'big-button' + (className ? ' ' + className : '') + (xLarge ? ' x-large'  : '') + (loading ? ' loading' : '') + (color ? ' ' + color : '')}
		disabled={disabled ? true : false}
		aria-label={label}
	>{label}<span className="inner">{label}</span></button>
);
