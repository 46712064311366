import * as React from 'react';
import Page from '../../../components/page';
import './index.less';
import {Products, ProductsMap} from './../../../data/products';
const IntroVideoMP4 = require('../../../assets/videos/nexus/Nexus_Cover_1920x1080.mp4');
const FullVideoMP4 = require('../../../assets/videos/nexus/Nexus_Cover_1920x1080.mp4');
const FullVideoPoster = require('../../../assets/videos/nexus/Nexus_Cover.jpg');
import Plyr from '../../../lib/plyr';
import Explore from '../../../components/explore-products';
import { H1, H2, H3 } from '../../../components/animated-container/h';
import {isIE, isMobile} from "../../../utils";

const data = ProductsMap['NEXUS'].data;

export default class Nexus extends React.Component {
    public player = null;
    public state = {
        controls: false,
        textVisibility: true,
    };

    constructor(props) {
        super(props);
        this.stopPlayer = this.stopPlayer.bind(this);
    }

    public sectionOnLeave(index, nextIndex, direction) {
    }

    public componentDidMount(): void {
        window.addEventListener('ready', (event) => {
            const instance = event.detail.plyr;
            this.player && this.player.decreaseVolume(1);
            instance && instance.on('click', (e) => {
                if (instance.stopped) {
                    this.setState({ textVisibility: true });
                }
            });
        });
        window.addEventListener('keyup', this.stopPlayer, false);
    }

    public stopPlayer(e) {
        if (this.state.textVisibility === true) { return; }
        if (e.key.toLowerCase() === 'escape') {
            this.player.stop();
            this.setState({ textVisibility: true });
        }
    }

    public componentWillUnmount(): void {
        window.removeEventListener('keyup', this.stopPlayer, false);

    }

    public render():

        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | string
        | number
        | {}
        | React.ReactNodeArray
        | React.ReactPortal
        | boolean
        | null
        | undefined {
        const introVideoPlayer = ProductsMap['NEXUS']['hasVideoPlayer']
        const hero = isIE() ? <img className={'hero'} src={FullVideoPoster}/> : <video poster={FullVideoPoster} autoPlay={true} loop={true} id="video-background" muted={true} playsInline={true} data-object-fit="cover"><source src={IntroVideoMP4} type="video/mp4"/></video>

        const sections = [
            {
                id: 'main',
                title: 'Real-time analytics and insights.',
                className: 'full-page',
                color: 'black',
                valign: 'center',
                content:
                    <>
                        <div id={'nexus-intro-video'}	className={this.state.textVisibility ? 'no-controls' : ''}>
                            <div style={!this.state.textVisibility ? { display: 'none', visibility: 'hidden'} : {}}>
                                {hero}
                            </div>
                            <div style={this.state.textVisibility ? { display: 'none', visibility: 'hidden' } : {}}>
                                <Plyr
                                    poster={FullVideoPoster}
                                    hideControls={false}
                                    keyboard={{ global: true }}
                                    ref={(player) => this.player = player}
                                    type="video"
                                    className={'no-auto-play nexus-intro-video'}
                                    controls={['play', 'progress', 'mute', 'volume', 'fullscreen']}
                                    muted={true}
                                    volume={0}
                                    autoplay={false}
                                    loop={{ active: true }}
                                    sources={[{
                                        src: FullVideoMP4,
                                        type: 'video/mp4',
                                    },
                                    ]}/>
                            </div>
                        </div>

                        <div id={'nexus-intro-video-text'}
                             className={this.state.textVisibility ? 'display-controls' : 'no-display-controls'}>
                            <div className="nexus-logo"/>
                            <H1 className={'s'}>Deployment approval platform.</H1>
                            <button onClick={() => {
                                this.setState({
                                    textVisibility: false,
                                }, () => {
                                    this.player.restart();
                                    this.player.play();
                                    this.player.increaseVolume(0.5);
                                })
                                ;
                            }}
                                    className={'play-button no-margin'}
                                    type={'button'}/>
                        </div>
                        <div id={'nexus-intro-video-close'}>
                            <button
                                className={this.state.textVisibility ? 'close-button-no-display' : 'close-button-wrapper'}
                                onClick={(e) => {
                                    this.setState({
                                        textVisibility: true,
                                    }, () => {
                                        this.player.stop();
                                    });
                                }}/>
                        </div>
                    </>,
            },
            {
                id: 'definition',
                title: 'nexus is unique',
                color: 'white',
                valign: 'center',
                content:
                    <>
                        <div id={'nexus-definition-text'}>
                            <H2 s={'separator l nexus'} className={'text-transform-unset'}>Without acceptance guidelines and processes, dispersed teams create disjointed products, applications and experiences. Nexus is a central deployment platform that connects teams to deliver unified results at a global scale.</H2>
                        </div>
                    </>,
            },
            {
                id: 'explore',
                title: 'Explore more products',
                color: 'white',
                valign: 'center',
                subscroll: true,
                content:
                    <>
                        <div id={'nexus-explore'}>
                            <Explore product={'nexus'} title allProducts={false} noWidth/>
                        </div>
                    </>,
            }
        ];

        data.forEach((dataSection, i) => {
            const presentation = dataSection.img  && (isIE() || !dataSection.video)?
                <img src={dataSection.img} key={i}/> :  dataSection.video ?
                    <video poster={dataSection.img} key={i} className={'short-video bg-video'} autoPlay={this.props.isMobile ? true : false} loop={true} id={'nexus-product-video-' + dataSection.key} muted={true} playsInline={true}>
                        { dataSection.video.map((source: any, j: number) => <source key={j} src={source.file} type={'video/' + source.type}/>) }

                    </video> : null;
            const newSection = {
                id: dataSection.key,
                title: dataSection.title,
                color: 'white',
                valign: 'center',
                content:
                    <>
                        <div id={'nexus-' + dataSection.key + '-grid'}>
                            <div id={'nexus-' + dataSection.key + '-text'}
                                               className={'section-left perc40'}>

                                <H2 className={'s title'} s={'separator sx nexus'}>
                                    {dataSection.title}
                                </H2>
                                <p
                                    className={
                                        'l info-detail description'
                                    }
                                >
                                    {dataSection.description}
                                </p>
                                {dataSection.key === 'combination' && <div id="nexus-platform-icons">
                                    <div className="web-logo"/>
                                    <div className="apple-logo"/>
                                    <div className="windows-logo"/>
                                    <div className="linux-logo"/>
                                </div>}
                            </div>
                            {!this.props.isMobile && <div id={"nexus-" + dataSection.key + "-image"}
                                  className={"presentation section-right perc60"}>
                                {presentation}
                            </div>}

                        </div>
                    </>,
            };
            sections.splice(sections.length - 1, 0, newSection);
        });

        if (this.props.isMobile) {
            for (let i = 0; i < sections.length; i++) {
                if (sections[i].id === 'explore') {
                    sections.splice(i, 1);
                }
            }
            sections.push({
                id: 'explore',
                title: 'Explore more products',
                color: 'white',
                valign: 'center',
                subscroll: true,
                content:
                    <>
                        <div id={'nexus-explore'}>
                            <Explore isMobile product={'nexus'} title allProducts={false} noWidth/>
                        </div>
                    </>,
            });
        }
        return (
            <Page
                isProduct={true}
                isMobile={this.props.isMobile}
                id="nexus-page"
                sectionOnLeave={(index, nextIndex, direction) =>
                    this.sectionOnLeave(index, nextIndex, direction)
                }
                sections={
                    sections
                }
            />
        );
    }
}
