import * as React from 'react';
import Button from '../components/button';
import { getHistory, GlobalHistory } from '../lib/history';

export default () => <div style={{display: 'flex', flexDirection: 'column', top: '60px', height: 'calc(100vh - 60px)', position: 'absolute', left: 0, bottom: 0, right: 0, justifyContent: 'center', alignItems: 'center'}}>
	<GlobalHistory/>
	<h1 style={{color: '#009fe3', marginBottom: '30px'}}>404</h1>
	<p>The page you’re looking for can’t be found.</p>
	<p><Button color={'white'} xLarge={true} label={'Go to homepage'} onClick={() => getHistory().push('/')}/></p>
</div>;
