import * as React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
// @ts-ignore
import './index.less';
import Button from '../../components/button';
import {isMobile, isTouch} from "../../utils";
import {RefObject} from "react";

class ProductCard extends React.PureComponent<any,{clicked: boolean, selected: boolean}> {
	public text: any = null;
	readonly productCard: RefObject<HTMLDivElement> = React.createRef();
	constructor(props: any) {
		super(props);
		this.state = {
			clicked: false,
			selected: false
		}

		this.onClick = this.onClick.bind(this);
		this.text = null;
	}

	public async onClick(product: any) {

		if(this.isSelected() && isMobile()) {
			return document.getElementById("page-start-anchor")?.scrollIntoView();
		}
		this.productCard.current?.classList.add('clicked');
		if (this.props.handleClick && typeof this.props.handleClick === 'function') {
			this.props.handleClick();
		}
		setTimeout(()=>this.props.history.push('/' + product.toLowerCase()), 300);
		setTimeout(()=>this.productCard.current?.classList.remove('clicked'), 1500);

		return;
	}
	private isSelected (): boolean {
		const isSelected = this.props.product?.trim().toLowerCase() === this.props.location?.pathname?.replace("/", "")?.trim().toLowerCase()
		return isSelected;
	}
	public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
		const { product, description, color, key, isLink, noWidth, className, isMobile , layout } = this.props;
		const selected = this.isSelected() ? ' selected ' : ''
		if (isLink) {
			return (
				<div
					ref={this.productCard}
					className={(className + ' product-card ' + color + ' ' +selected).concat(noWidth ? ' no-width' : '').concat(' ' + product && product.toLowerCase() + '-image-card')}
					key={key}
					id={'product-card-'+this.props.product?.trim().toLowerCase()}
					onClick={() => this.onClick(product)}
					onMouseOver={isTouch() ? () => this.onClick(product) : () => {}}
				>
					<div className={layout === 'rows' ? 'card-content' : 'left'}>
						<div className={'text'} ref={ref => this.text = ref}>
							<h2>
								{product && product}
							</h2>
							<div className={'separator s ' + color}/>
							<h3 className={'description'}>
								{description && description.length > 0 && description[0] + '\n' + description[1]}
							</h3>
						</div>
						<div className={'toggle'}>
							<Button
								color={color}
								className={'small'}
								label={'DISCOVER MORE'}
								onClick={() => this.onClick(product)}
							/>
						</div>
					</div>
				</div>);
		}
		if (isMobile) {
			return (
				<div ref={this.productCard} id={'product-card-'+this.props.product?.trim().toLowerCase()}  className={selected + (this.state.clicked ? 'clicked ' : '')+(className + ' product-card ' + color + ' ' + (isMobile ? ' mobile ' : '')).concat(noWidth ? ' no-width' : '').concat(' ' + product && product.toLowerCase() + '-image-card')} key={key}>
					<div className={'left'} onClick={({target}) => {
						this.onClick(product)
					}}>
						<h2>{product && product}</h2>
						<div className={'separator s ' + color}/>
						<h3 className={'description'}>{description && description.length > 0 && description[0] + '\n' + description[1]}</h3>
					</div>
				</div>);
		}
		return (
			<div ref={this.productCard} id={'product-card-'+this.props.product?.trim().toLowerCase()} className={(className + ' product-card' + selected).concat(noWidth ? ' no-width' : '')} key={key}>
				<div className={product && product.toLowerCase() + '-image-card'}>
				</div>
				<h2>{product}</h2>
				<div className={'separator s ' + color}/>
				<h3 className={'description'}>{description}</h3>
				<div className={'toggle'}><Button color={color} className={'small'} label={'LEARN MORE'} onClick={() => this.onClick(product)}/></div>
			</div>
		);
	}
}

export default withRouter(ProductCard);
