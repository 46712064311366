import * as React from 'react';
import Page from '../../../components/page';
import './index.less';
import {Products, ProductsMap} from './../../../data/products';
const IntroVideoMP4 = require('../../../assets/videos/matrix/Matrix_Loop.mp4');
const IntroVideoPoster = require('../../../assets/videos/matrix/Matrix_Loop.jpg');
import Plyr from '../../../lib/plyr';
import Explore from '../../../components/explore-products';
import { H1, H2, H3 } from '../../../components/animated-container/h';
import {hasWindow, isIE, isMobile} from '../../../utils';

const icon_close_video = require('./../../../assets/svg/close-video.svg');

const data = ProductsMap['MATRIX'].data;
const handleTryDemo = () => {
	let src = 'https://dev-arthos.dcodeit.com/demos/art/web/arthos/public/art-instrument-screener-matrix/index.html';

	if (hasWindow() && window.location.hostname.indexOf('dcodeit.com') === -1) {
		src = 'https://dev-arthos.dcodeit.com/demos/art/web/arthos/public/art-instrument-screener-matrix/index.html';
	}

	window.open(
		src,
		'_blank',
		'toolbar=0,location=0,menubar=0,width=1300,height=800',
	);
};
export default class Matrix extends React.Component {

	public player = null;
	public state = {
		controls: false,
		textVisibility: true,
	};

	constructor(props) {
		super(props);
		this.stopPlayer = this.stopPlayer.bind(this);
	}

	public sectionOnLeave(index, nextIndex, direction) {
	}

	public componentDidMount(): void {
		window.addEventListener('ready', (event) => {
			const instance = event.detail.plyr;
			this.player && this.player.decreaseVolume(1);
			instance && instance.on('click', (e) => {
				if (instance.stopped) {
					this.setState({ textVisibility: true });
				}
			});
		});
		window.addEventListener('keyup', this.stopPlayer, false);
	}
	public stopPlayer(e) {
		if (this.state.textVisibility === true) { return; }
		if (e.key.toLowerCase() === 'escape') {
			this.player.stop();
			this.setState({ textVisibility: true });
		}
	}
	public componentWillUnmount(): void {
		window.removeEventListener('keyup', this.stopPlayer, false);

	}

	public render():

		| React.ReactElement<any, string | React.JSXElementConstructor<any>>
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {
		const hero = isIE() ? <img className={'hero'} src={IntroVideoPoster}/> : <video poster={IntroVideoPoster} autoPlay={true} loop={true} id="video-background" muted={true} playsInline={true} data-object-fit="cover"><source src={IntroVideoMP4} type="video/mp4"/></video>

		const sections = [{
			id: 'main',
			title: 'We change the way enterprises work',
			color: 'black',
			valign: 'center',
			className: 'full-page',
			content:
				<>
					<div id={'matrix-intro-video'} className={this.state.textVisibility ? 'no-controls' : ''}>
						<div style={!this.state.textVisibility ? {display: 'none', visibility: 'hidden'} : {}}>
							{hero}
						</div>
						<div style={this.state.textVisibility ? {display: 'none', visibility: 'hidden'} : {}}>
							<Plyr
								poster={IntroVideoPoster}
								hideControls={false}
								ref={(player) => this.player = player}
								keyboard={{global: true}}
								type="video"
								className={'no-auto-play matrix-intro-video'}
								controls={['play', 'progress', 'mute', 'volume', 'fullscreen']}
								muted={true}
								volume={0.2}
								loop={{ active: true }}
								sources={[{
									src: IntroVideoMP4,
									type: 'video/mp4',
								},
								]}/>
						</div>
					</div>
					<div id={'matrix-intro-video-text'} className={this.state.textVisibility ? 'display-controls' : 'no-display-controls'}>
						<div className="matrix-logo"/>
						<H1 className={'s'}>Data interaction of the future.</H1>
						{/*<Button xLarge color={'transparent'} onClick={handleTryDemo} label={'Try Demo'}/>*/}
					</div>
				</>,
		}, {
			id: 'definition',
			title: 'matrix is unique',
			color: 'white',
			valign: 'center',
			content:
				<>
					<div id={'matrix-definition-text'}>
						<H2 s={'separator l yellow'} className={'text-transform-unset'}>The ultimate data visualisation and reporting tool built to handle large sets of data. Filter, sort, group and aggregate data instantly. Actionable, AI driven insights to fast-track workflows.</H2>
					</div>
				</>,
		},
			{
				id: 'explore',
				title: 'Explore more products',
				color: 'white',
				valign: 'center',
				subscroll: true,
				content:
					<>
						<div id={'matrix-explore'}>
							<Explore product={'matrix'} title allProducts={false} noWidth/>
						</div>
					</>,
			},
		];

		data.forEach((dataSection) => {
			const presentation = dataSection.img  && (isIE() || !dataSection.video)?
				<img src={dataSection.img}/> :
				dataSection.video ?
					<video poster={dataSection.img} className={'short-video bg-video'} autoPlay={this.props.isMobile ? true : false} loop={true} id={'arthos-product-video-' + dataSection.key} muted={true} playsInline={true}>
						{
							dataSection.video.map((source) => <source src={source.file} type={'video/' + source.type}/>)
						}

					</video> : null;
			const newSection = {
				id: dataSection.key,
				title: dataSection.title,
				color: 'white',
				valign: 'center',
				content:
					<>
						<div id={'matrix-' + dataSection.key + '-grid'}>
							<div id={'matrix-' + dataSection.key + '-text'} className={'section-left perc40'}>

								<H2 className={'s title'} s={'separator sx yellow'}>
									{dataSection.title}
								</H2>

								<p
									className={
										'l info-detail description'
									}
								>
									{dataSection.description}
								</p>
							</div>
							{!this.props.isMobile && <div id={"matrix-" + dataSection.key + "-image"}
								  className={"presentation section-right perc60"}>
								{presentation}
							</div>}

						</div>
					</>,
			};
			sections.splice(sections.length - 1, 0, newSection);
		});

		if (this.props.isMobile) {
			for (let i = 0; i < sections.length; i++) {
				if (sections[i].id === 'explore') {
					sections.splice(i, 1);
				}
			}
			sections.push({
				id: 'explore',
				title: 'Explore more products',
				color: 'white',
				valign: 'center',
				subscroll: true,
				content:
					<>
						<div id={'matrix-explore'}>
							<Explore isMobile product={'matrix'} title allProducts={false} noWidth/>
						</div>
					</>,
			});
		}
		return (
			<Page
				isProduct={true}
				isMobile={this.props.isMobile}
				id="matrix-page"
				sectionOnLeave={(index, nextIndex, direction) =>
					this.sectionOnLeave(index, nextIndex, direction)
				}
				sections={
					sections
				}
			/>
		);
	}
}
