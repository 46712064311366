import * as React from 'react';
import {isIE} from "../../utils";
import {withRouter} from "react-router";

const PageContainer = (InnerComponent:any) =>
    withRouter(class PageContainer extends React.PureComponent<any, any> {
        readonly state = {
            redraw: false
        }

        componentDidMount() {
            if(isIE()) {
                window.addEventListener('resize', () => {
                    this.redraw();
                });
            }
        }

        componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
            if(this.props.isMobile !== prevProps.isMobile) {
                this.redraw();
            }
        }
        private redraw(){
            this.setState({redraw: true}, () => setTimeout(()=>this.setState({redraw: false}),70))

        }
        public render() {
            if(this.state.redraw) return null;
            return <InnerComponent {...this.props }/>
        }
    });

export default PageContainer