import * as React from 'react';
import Page from '../../components/page';
import './index.less';
import '../../fonts.less';
import TextField from '@material-ui/core/TextField';
import {H1, H2} from '../../components/animated-container/h';
import Button from '../../components/button';
import {withStyles} from '@material-ui/core';
import axios from 'axios'
import 'url-search-params-polyfill';

const md5 = require('md5')

const CssTextField = withStyles({
    root: {
        '& .MuiInputLabel-root': {
            fontFamily: '"TradeGothic", sans-serif',
            fontStretch: 'condensed',
            fontSize: '24px',
            fontWeight: '600',
            color: '#454857',
        },
        '& .MuiInputBase-input': {
            fontFamily: '"TradeGothic", sans-serif',
            fontStretch: 'condensed',
            fontSize: '24px',
            fontWeight: '600',
            color: '#000000',
        },
    },
})(TextField);

export interface IClientPortalState {
    checked: boolean;
    password: string;
    errorMessage: string;
    expired: boolean;
}

export default class ClientPortal extends React.Component<any, IClientPortalState> {

    constructor(props: any) {
        super(props);
        this.state = {
            checked: false,
            password: '',
            errorMessage: '',
            expired: false
        };
        this.isValid = this.isValid.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public isValid(key: any) {
        return false;
    }

    public handleChange(name, event) {
        return this.setState({...this.state, [name]: event.target.checked});
    }
    componentDidMount(): void {
        const params: URLSearchParams = new URLSearchParams(window.location.search)
        const expired = params.get('expired')
        if(expired){
            this.setState({expired: true})
        }
    }

    private getConfig() {
        return {
            services: `${window.location.origin}/cp/svc`,
            redirectUrl: `${window.location.origin}/cp/`
        };
    }
    private login = (e: Event) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        const conf = this.getConfig();
        const usernameField: HTMLInputElement = document.getElementById('username') as HTMLInputElement
        const passwordField: HTMLInputElement = document.getElementById('password-basic') as HTMLInputElement
        if(usernameField && usernameField.value && passwordField && passwordField.value){
            this.setState({errorMessage: '', expired: false}, () => {
                axios.post(conf.services + '/user/login', {
                    username: usernameField.value.toLowerCase(),
                    password: md5(passwordField.value)
                }, {
                    headers: {'X-Request-Id': this.createUUID()},
                    withCredentials: true
                }).then((response)=>{
                    if(response.data) {
                        if(response.data.logged_in === false && response.data.error && response.data.error.message ) {
                            this.setState({errorMessage: response.data.error.message})
                        } else {
                            let url = conf.redirectUrl;
                            if (window.location.search) {
                                const params: URLSearchParams = new URLSearchParams(window.location.search)
                                const redirect = params.get('redirect')
                                //const params = window.location.search.match(/^\?redirect\=(.*)$/);
                                if (redirect) {
                                    url += `${atob(redirect)}`;
								}
                                //console.error(url)
							}
                            localStorage.setItem("ng.dds.userid", usernameField.value.toLowerCase());
                            window.location.assign(url);
                        }
                    } else {
                        this.setState({errorMessage: 'Something went wrong. Please try again later.'})
                    }
                }).catch(() => {
                    this.setState({errorMessage: 'Something went wrong. Please try again later.'})
                })
            })
        }
    }
    private createUUID() {
        let d = new Date().getTime()
        const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = (d + Math.random()*16)%16 | 0
            d = Math.floor(d/16)
            return (c === 'x' ? r : (r&0x3|0x8)).toString(16)
        })
        return uuid
    }
    public render() {
        const {isMobile} = this.props;

        return (
            <Page
                isMobile={this.props.isMobile}
                id="client-portal"
                sections={[
                    {
                        id: 'main',
                        title: 'Client Portal',
                        color: 'gray',
                        vlign: 'center',
                        className: 'full-page',
                        content: (
                            <>
                                <div
                                    className={('client-portal-main-section main-section ').concat(isMobile ? 'mobile' : '')}>
                                    <form encType={''} noValidate autoComplete="off" onSubmit={this.login}>
                                        <H1 tabIndex={-1} className={'black'}>
                                            Sign in
                                        </H1>
                                        <div id={'error-message'} className={'login-error-message'+(this.state.errorMessage ? ' visible' : '')}>{this.state.errorMessage}</div>
                                        <div className={'login-session-exp-message'+(this.state.expired ? ' visible' : '')}>Your session has timed out. Please login again.</div>
                                        <div className={'client-portal-form'}>
                                            <CssTextField id="username" label="USERNAME" type="text"
                                                inputProps={{
                                                    autoCapitalize: 'none',
                                                }} />
                                            <CssTextField value={this.state.password}
                                                          onChange={({target: {value}}) => this.setState({password: value})}
                                                          id="password-basic" label="PASSWORD" type="password"/>
                                            {/*<div style={{ display: 'flex', justifyContent: 'flex-end'}}>
												<Link className="forgot-password">FORGOT PASSWORD</Link>
											</div>*/}
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                                alignContent: 'center',
                                                marginTop: '14px'
                                            }}>
                                                {/*<div className="checkbox-container">*/}
                                                {/*	<Checkbox*/}
                                                {/*		checked={this.state.checked}*/}
                                                {/*		onChange={(e) => this.handleChange('checked', e)}*/}
                                                {/*		value="checked"*/}
                                                {/*	/>*/}
                                                {/*	<h3>REMEMBER ME</h3>*/}
                                                {/*</div>*/}
                                                <div className={'button-container'}>
                                                    <Button
                                                        className={isMobile ? 'small' : ''}
                                                        color={'blue'}
                                                        label={'Login'}
                                                        type={'submit'}
                                                        onClick={this.login}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </>
                        ),
                    },
                ]}
            />
        );
    }
}
