import * as React from 'react';

import { ParticleImage as PI } from '../../lib/particleimage';
import piContainer from './pi-container';

export interface ParticleImageSettings {
	renderer: 'webgl' | 'default';
	imageUrl: string;
	particleGap: number;
	mouseForce: number;
	clickStrength: number;
	noise: number;
	gravity: number;
	maxWidth?: number;
	maxHeight?: number;
	particleSize: number;
	layerCount: number;
	depth: number;
	layerDistance: number;
	lifeCycle: boolean;
	growDuration: number;
	waitDuration: number;
	shrinkDuration: number;
	shrinkDistance: number;
	className: string;
	addOffsetX: number;
	addOffsetY: number;
	responsiveWidth: boolean;
	id?: string;
	disableInteraction: boolean;
	align: 'left' | 'right' | 'center';
	color: string;
	height?: number;
}
const getParticleProps = () => {
	const resolutions = [
		{
			min: 0,
			max: 767,
			particleGap: 3,
			particleSize: 1,
		},
		{
			min: 768,
			max: 1023,
			particleGap: 2,
			particleSize: 2,
		},
		{
			min: 1024,
			max: 1279,
			particleGap: 2,
			particleSize: 2,
		},
		{
			min: 1280,
			max: 1919,
			particleGap: 3,
			particleSize: 3,
		},
		{
			min: 1920,
			max: 2559,
			particleGap: 3,
			particleSize: 4,
		},
		{
			min: 2560,
			max: 800000,
			particleGap: 3,
			particleSize: 5,
		},
	];
	return resolutions.filter((p) => window.innerWidth >= p.min && window.innerWidth <= p.max )[0];
};

class ParticleImage extends React.Component<ParticleImageSettings, {}> {
	public wrapperRef = React.createRef<HTMLDivElement>();
	public pi: any = undefined;
	public static defaultProps: ParticleImageSettings = {
		renderer: 'webgl',
		imageUrl: require('../../assets/svg/dcodeit.svg'),
		particleGap: 2,
		mouseForce: 60,
		clickStrength: -50,
		noise: 10,
		gravity: 0.09,
		particleSize: 2,
		layerCount: 1,
		depth: 0,
		layerDistance: 5,
		lifeCycle: false,
		growDuration: 60,
		waitDuration: 60,
		shrinkDuration: 80,
		shrinkDistance: 0,
		className: '',
		responsiveWidth: true,
		addOffsetX: 0,
		addOffsetY: 0,
		id: '',
		align: 'center',
		disableInteraction: false,
		color: '#009fe3',
	};
	public state = {
		fieldOfView: 1.2,
		nearPlane: 10,
		farPlane: 100,
		particleProps: getParticleProps(),
	};
	constructor(props: ParticleImageSettings) {
		super(props);
		this.init = this.init.bind(this);
	}
	public init() {
		if (this.pi) {
			this.pi.events?.imageLoaded = [];
			this.pi.stop();
		}
		this.pi = new PI({ ...this.props, ...this.state.particleProps, wrapperElement: this.wrapperRef.current, cameraOptions: this.state });
		if (!this.pi.events?.stopped) {
			this.pi.on('stopped', function(this: any) {
				this.pi.canvas?.remove();
			});
		}
	}
	public componentWillUnmount(): void {
		this.pi?.destroy();
	}
	public async resizeWrapper() {
		if (this.wrapperRef.current.clientHeight > this.wrapperRef.current.clientWidth) {
			const currentHeight = this.wrapperRef.current.clientHeight;
			const top = currentHeight / 2 - this.wrapperRef.current.clientWidth / 2;
			const diff = 0;
			$(this.wrapperRef.current).height(this.wrapperRef.current.clientWidth).css({ top: (top - diff) + 'px', bottom: (top + diff) + 'px'});
		}

	}
	public async componentDidMount() {
		await this.resizeWrapper();
		setTimeout(this.init, 100);
	}
	public render() {
		return <div ref={this.wrapperRef} id={this.props.id} className={'particle-image ' + this.props.className}></div>;
	}
}
export default piContainer(ParticleImage);
