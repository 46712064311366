import * as React from 'react';
import Page from '../../../components/page';
import Explore from '../../../components/explore-products';
import './index.less';
import { ProductsMap } from './../../../data/products';

const IntroVideoMP4 = require('../../../assets/videos/axcept/Axcept_Loop.mp4');
const IntroVideoPoster = require('../../../assets/videos/axcept/Axcept_Loop.jpg');
const FullVideoMP4 = require('../../../assets/videos/axcept/Axcept.mp4');
import Plyr from '../../../lib/plyr';
import { H1, H2, H3 } from '../../../components/animated-container/h';
import {isIE, isMobile} from "../../../utils";

const data = ProductsMap['AXCEPT'].data;
export default class Axcept extends React.Component {

	public player = null;
	public state = {
		controls: false,
		textVisibility: true,
	};

	constructor(props) {
		super(props);
		this.stopPlayer = this.stopPlayer.bind(this);
	}

	public sectionOnLeave(index, nextIndex, direction) {
	}

	public componentDidMount(): void {
		window.addEventListener('ready', (event) => {
			const instance = event.detail.plyr;
			this.player && this.player.decreaseVolume(1);
			instance && instance.on('click', (e) => {
				if (instance.stopped) {
					this.setState({ textVisibility: true });
				}
			});
		});
		window.addEventListener('keyup', this.stopPlayer, false);
	}
	public stopPlayer(e) {
		if (this.state.textVisibility === true) { return; }
		if (e.key.toLowerCase() === 'escape') {
			this.player.stop();
			this.setState({ textVisibility: true });
		}
	}
	public componentWillUnmount(): void {
		window.removeEventListener('keyup', this.stopPlayer, false);

	}

	public render():

		| React.ReactElement<any, string | React.JSXElementConstructor<any>>
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {

		// @ts-ignore
		const hero = isIE() ? <img className={'hero'} src={IntroVideoPoster}/> : <video poster={IntroVideoPoster} autoPlay={true} loop={true} id="video-background" muted={true} playsInline={true} data-object-fit="cover"><source src={IntroVideoMP4} type="video/mp4"/></video>

		const sections = [{
			id: 'main',
			title: 'We change the way enterprises work',
			className: 'full-page',
			color: 'black',
			valign: 'center',
			content:
				<>
					<div id={'axcept-intro-video'}  className={this.state.textVisibility ? 'no-controls' : ''}>
						<div style={!this.state.textVisibility ? {display: 'none', visibility: 'hidden'} : {}}>
							{hero}
						</div>
						<div style={this.state.textVisibility ? {display: 'none', visibility: 'hidden'} : {}}>
							<Plyr
								poster={IntroVideoPoster}
								hideControls={false}
								keyboard={{global: true}}
								ref={(player) => this.player = player}
								type="video"
								className={'no-auto-play axcept-intro-video'}
								volume={0}
								controls={['play', 'progress', 'mute', 'volume', 'fullscreen']}
								muted={true}
								loop={{ active: true }}
								sources={[{
									src: FullVideoMP4,
									type: 'video/mp4',
								},
								]}/>
						</div>
					</div>
					<div id={'axcept-intro-video-text'}  className={this.state.textVisibility ? 'display-controls' : 'no-display-controls'}>
						<div className="axcept-logo"/>
						<H1 className={'s'}>Automated UI testing at scale.</H1>
						<button onClick={() => {
							this.setState({
								textVisibility: false,
							}, () => {
								this.player.restart();
								this.player.play();
								this.player.increaseVolume(0.5);
							})
							;
						}}
								className={'play-button no-margin'}
								type={'button'}/>
					</div>
					<div id={'axcept-intro-video-close'}>
						<button
							className={this.state.textVisibility ? 'close-button-no-display' : 'close-button-wrapper'}
							onClick={(e) => {
								this.setState({
									textVisibility: true,
								}, () => this.player.stop());
							}}/>
					</div>
				</>,
		}, {
			id: 'definition',
			title: 'axcept is unique',
			color: 'white',
			valign: 'center',
			content:
				<>
					<div id={'axcept-definition-text'}>
						<H2 s={'separator l green'} className={'text-transform-unset'}>Axcept is an intuitive, automated visual testing tool for building and executing thousands of tests in a matter of minutes. Deploy updates quickly and with confidence.</H2>
					</div>
				</>,
		},
			{
				id: 'explore',
				title: 'Explore more products',
				color: 'white',
				valign: 'center',
				subscroll: true,
				content:
					<>
						<div id={'axcept-explore'}>
							<Explore product={'axcept'} title allProducts={false} noWidth/>
						</div>
					</>,
			},
		];

		const scrollingSection = ProductsMap['AXCEPT'].scrollingSection;
		if(scrollingSection && !this.props.isMobile){
			scrollingSection.texts.forEach((dataSection,i)=>{

				const newSection = {
					id: dataSection.key,
					title: dataSection.title,
					color: 'white',
					valign: 'center',
					className:'scrolling-section',
					content:
						<>
							<div id={'axcept-' + dataSection.key + '-grid'} className="perc30">
								<div id={'axcept-' + dataSection.key + '-text'} className={'section-left perc40'}>
									<H2 className={'s title'}>
										{dataSection.title}
									</H2>
									<H3 s={'separator sx green'}
										className={'info-detail mobile-description font-body'}>
										{dataSection.mobile}
									</H3>
									<p
										className={
											'l info-detail description'
										}
									>
										{dataSection.description}
									</p>
								</div>


							</div>
						</>,
				};

				sections.splice(sections.length - 1, 0, newSection);

			})
		}

		if (data && Array.isArray(data) && data.length){
			data.forEach((dataSection) => {
				const presentation = dataSection.img && (isIE() || !dataSection.video) ?
					<img src={dataSection.img}/> :
					dataSection.video ?
						<video poster={dataSection.img} className={'short-video bg-video'} autoPlay={this.props.isMobile ? true : false} loop={true} id={'arthos-product-video-' + dataSection.key} muted={true} playsInline={true}>
							{
								dataSection.video.map((source) => <source src={source.file} type={'video/' + source.type}/>)
							}

						</video> : null;
				const newSection = {
					id: dataSection.key,
					title: dataSection.title,
					color: 'white',
					valign: 'center',
					content:
						<>
							<div id={'axcept-' + dataSection.key + '-grid'}>
								<div id={'axcept-' + dataSection.key + '-text'} className={'section-left perc40'}>
									<H2 className={'s title'} s={'separator sx green'}>
										{dataSection.title}
									</H2>
									<p
										className={
											'l info-detail description'
										}
									>
										{dataSection.description}
									</p>
								</div>
								{!this.props.isMobile && <div id={"axcept-" + dataSection.key + "-image"}
									  className={"presentation section-right perc60"}>
									{presentation}
								</div>}

							</div>
						</>,
				};
				sections.splice(sections.length - 1, 0, newSection);
			});
		}


		if (this.props.isMobile) {
			for (let i = 0; i < sections.length; i++) {
				if (sections[i].id === 'explore') {
					sections.splice(i, 1);
				}
			}
			sections.push({
				id: 'explore',
				title: 'Explore more products',
				color: 'white',
				valign: 'center',
				subscroll: true,
				content:
					<>
						<div id={'axcept-explore'}>
							<Explore isMobile product={'axcept'} title allProducts={false} noWidth/>
						</div>
					</>,
			});
		}
		return (
			<Page
				isProduct={true}
				isMobile={this.props.isMobile}
				id="axcept-page"
				sectionOnLeave={(index, nextIndex, direction) =>
					this.sectionOnLeave(index, nextIndex, direction)
				}
				sections={
					sections
				}
				scrollingSection={ProductsMap['AXCEPT'].scrollingSection}

			/>
		);
	}
}
