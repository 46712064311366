import * as React from 'react';
import Page from '../../../components/page';
import './index.less';
import { ProductsMap } from './../../../data/products';

const IntroVideoMP4 = require('../../../assets/videos/arthos/Arthos_Loop.mp4');
const FullVideoMP4 = require('../../../assets/videos/arthos/Arthos_Shorter_v1_ED.mp4');
const FullVideoPoster = require('../../../assets/videos/arthos/Arthos_Loop.jpg');
const ArthosProduct = require('../../../assets/videos/arthos/arthos_product.mp4');
const ArthosProductPoster = require('../../../assets/images/product/Arthos/poster.jpg');

import Plyr from '../../../lib/plyr';
import Explore from '../../../components/explore-products';
import { H1, H2, H3 } from '../../../components/animated-container/h';
import { isIE } from "../../../utils";
import { FloatingVideo } from "../../../components/floating-video";

const data = ProductsMap['ARTHOS'].data;

export default class Arthos extends React.Component {
	public player = null;
	public state = {
		controls: false,
		textVisibility: true,
	};

	constructor(props) {
		super(props);
		this.stopPlayer = this.stopPlayer.bind(this);
	}

	public sectionOnLeave(index, nextIndex, direction) {
	}

	public componentDidMount(): void {
		window.addEventListener('ready', (event) => {
			const instance = event.detail.plyr;
			this.player && this.player.decreaseVolume(1);
			instance && instance.on('click', (e) => {
				if (instance.stopped) {
					this.setState({ textVisibility: true });
				}
			});
		});
		window.addEventListener('keyup', this.stopPlayer, false);
	}

	public stopPlayer(e) {
		if (this.state.textVisibility === true) { return; }
		if (e.key.toLowerCase() === 'escape') {
			this.player.stop();
			this.setState({ textVisibility: true });
		}
	}

	public componentWillUnmount(): void {
		window.removeEventListener('keyup', this.stopPlayer, false);

	}

	public render():

		| React.ReactElement<any, string | React.JSXElementConstructor<any>>
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {
		const hero = isIE() ? <img className={'hero'} src={FullVideoPoster}/> : <video poster={FullVideoPoster} autoPlay={true} loop={true} id="video-background" muted={true} playsInline={true} data-object-fit="cover"><source src={IntroVideoMP4} type="video/mp4"/></video>
		const sections = [
			{
				id: 'main',
				title: 'We change the way enterprises work',
				color: 'black',
				valign: 'center',
				className: 'full-page',
				content:
					<>
						<div id={'arthos-intro-video'}	className={this.state.textVisibility ? 'no-controls' : ''}>
							<div style={!this.state.textVisibility ? { display: 'none', visibility: 'hidden'} : {}}>
								{hero}
							</div>
							<div style={this.state.textVisibility ? { display: 'none', visibility: 'hidden' } : {}}>
								<Plyr
									poster={FullVideoPoster}
									hideControls={false}
									keyboard={{ global: true }}
									ref={(player) => this.player = player}
									type="video"
									className={'no-auto-play arthos-intro-video'}
									controls={['play', 'progress', 'mute', 'volume', 'fullscreen']}
									muted={true}
									volume={0}
									autoplay={false}
									loop={{ active: true }}
									sources={[{
										src: FullVideoMP4,
										type: 'video/mp4',
									},
									]}/>
							</div>
						</div>

						<div id={'arthos-intro-video-text'}
											className={this.state.textVisibility ? 'display-controls' : 'no-display-controls'}>
							<div className="arthos-logo"/>
							<H1 className={'s'}>The workspace built for the enterprise.</H1>
							<button onClick={() => {
								this.setState({
									textVisibility: false,
								}, () => {
									this.player.restart();
									this.player.play();
									this.player.increaseVolume(0.5);
								})
								;
							}}
									className={'play-button no-margin'}
									type={'button'}/>
						</div>
						<div id={'arthos-intro-video-close'}>
							<button
								className={this.state.textVisibility ? 'close-button-no-display' : 'close-button-wrapper'}
								onClick={(e) => {
									this.setState({
										textVisibility: true,
									}, () => {
										this.player.stop();
									});
								}}/>
						</div>
					</>,
			},
			{
				id: 'definition',
				title: 'Arthos is unique',
				color: 'white',
				valign: 'center',
				content:
					<>
						<div id={'arthos-definition-text'} className="w100">
							<H2 s={'separator l darker-blue'} className={'text-transform-unset'}>Eliminate technical debt and drive your digital transformation with Arthos, the leading UI/UX framework and enterprise workspace.</H2>
						</div>
					</>,
			},
			{
				id: 'explore',
				title: 'Explore more products',
				color: 'white',
				valign: 'center',
				subscroll: true,
				content:
					<>
						<div id={'arthos-explore'}>
							<Explore product={'arthos'} title allProducts={false} noWidth/>
						</div>
					</>,
			},
		];

		data.forEach((dataSection, i) => {

			const presentation = dataSection.img && (isIE() || !dataSection.video) ?
				<img src={dataSection.img} key={i}/> :  dataSection.video ?
					<video poster={dataSection.img} key={i} className={'short-video bg-video'} autoPlay={this.props.isMobile ? true : false} loop={true} id={'arthos-product-video-' + dataSection.key} muted={true} playsInline={true}>
						{ dataSection.video.map((source: any, j: number) => <source key={j} src={source.file} type={'video/' + source.type}/>) }

					</video> : null;
			const newSection = {
				id: dataSection.key,
				title: dataSection.title,
				color: 'white',
				valign: 'center',
				content:
					<>
						<div id={'arthos-' + dataSection.key + '-grid'}>
							<div id={'arthos-' + dataSection.key + '-text'}
												className={'section-left perc40'}>

								<H2 className={'s title'} s={'separator sx darker-blue'}>
									{dataSection.title}
								</H2>

								<p className={'l info-detail description'}>
									{dataSection.description}
								</p>
								{dataSection.key === 'streamline' && <div id="arthos-platform-icons">
									<div className="web-logo"/>
									<div className="apple-logo"/>
									<div className="windows-logo"/>
									<div className="linux-logo"/>
								</div>}
							</div>

						</div>
					</>,
			};
			sections.splice(sections.length - 1, 0, newSection);
		});

		if (this.props.isMobile) {
			for (let i = 0; i < sections.length; i++) {
				if (sections[i].id === 'explore') {
					sections.splice(i, 1);
				}
			}
			sections.push({
				id: 'explore',
				title: 'Explore more products',
				color: 'white',
				valign: 'center',
				content:
					<>
						<div id={'arthos-explore'}>
							<Explore isMobile={true} product={'arthos'} title allProducts={false} noWidth/>
						</div>
					</>,
			});
		}
		return (
			<>
				{!this.props.isMobile && <FloatingVideo inSection={[3, 4, 5, 6, 7, 8, 9, 10]} video={{ mp4: ArthosProduct, poster: ArthosProductPoster }}/>}

			<Page
				isProduct={true}
				isMobile={this.props.isMobile}
				id="arthos-page"
				sectionOnLeave={(index, nextIndex, direction) =>
					this.sectionOnLeave(index, nextIndex, direction)
				}
				sections={
					sections
				}
			/>
			</>
		);
	}
}
