import * as React from 'react';
import './section-indicator.less';
import { TweenMax, TimelineMax } from 'gsap';
import { Power2, Elastic } from 'gsap/EasePack';
import { isMobile } from '../../utils';

interface ISectionIndicatorProps {
	sectionsCount: number;
	sectionIndex: number;
	scrollDirection: string;
	orientation: 'vertical' | 'horizontal';
	sectionColor?: string;
	size: number;
	goTo: (index: number) => void;
	customOnClick?: (sectionNo: number) => void;

}
interface ISectionIndicatorState {

}
const mapSectionColorToScrollerNavColors = {
	white: '#000',
	blue: '#fff',
	black: '#fff',
	gray: '#000',
};
class SectionIndicator extends React.Component<ISectionIndicatorProps, ISectionIndicatorState> {
	constructor(props) {
		super(props);
		this.onCircleClick = this.onCircleClick.bind(this);
	}

	public componentDidMount(): void {
		if (this.props.sectionsCount === 1 || isMobile()) { return null; }
		if (this.props.sectionsCount === 1) { return null; }
		const select = (s) => {
			return document.querySelector(s);
		};
		const selectAll = (s) => {
			return document.querySelectorAll(s);
		};

		TweenMax.set('svg#section-indicator-svg', {
			visibility: 'visible',
		});
		select('#joinLine').setAttribute('stroke-width', this.props.size / 2);
		this.onCircleClick({target: $('.section-indicator-circle')[this.props.sectionIndex - 1]}, true);

	}
	public componentDidUpdate(prevProps: Readonly<ISectionIndicatorProps>, prevState: Readonly<ISectionIndicatorState>, snapshot?: any): void {
		if (this.props.sectionsCount === 1 || isMobile()) { return null; }
		if (this.props.sectionIndex !== prevProps.sectionIndex) {
			this.onCircleClick({target: $('.section-indicator-circle')[this.props.sectionIndex - 1]}, true);
		}
	}

	public onCircleClick({target}, noMove) {
		const y = parseInt(target.getAttribute('y')) + this.props.size / 4;
		const tl = new TimelineMax();
		tl.to('#joinLine', 0.3, {
			attr: {
				y2: y,
			},
			strokeWidth: 0,
			ease: Power2.easeIn,
		}).to('#joinLine', 1, {
			attr: {
				y1: y,
			},
			ease: Elastic.easeOut.config(1, 0.76),
		}, '+=0')
			.to('#joinLine', 2, {
				strokeWidth: this.props.size / 2 + 2,
				ease: Elastic.easeOut.config(1, 0.8),
			}, '-=1');

		tl.timeScale(2);
		if (noMove) { return; }

		const dataSection = target.getAttribute('data-section');
		if (dataSection) {
			if(this.props.customOnClick && typeof this.props.customOnClick === 'function') {
				return this.props.customOnClick(dataSection)
			}
			$.fn.pagepiling.moveTo(dataSection);
		}
	}
	public render() {
		if (this.props.sectionsCount === 1 || isMobile()) { return null; }

		const color = mapSectionColorToScrollerNavColors[this.props.sectionColor || 'white'];
		const circles = [];

		let cx;
		let cy;
		for (let i = 1; i <= this.props.sectionsCount; i++) {

			if (this.props.orientation === 'vertical') {
				cx = this.props.size;
				cy = this.props.size + i * (this.props.size + 8);
			} else {
				cx = this.props.size + i * (this.props.size + 8);
				cy = this.props.size;
			}

			circles[i] = <rect className="section-indicator-circle" data-section={i} onClick={this.onCircleClick} key={i}  x={cx} y={cy} width={(this.props.size / 2) + 1} height={(this.props.size / 2) + 1}/>;
		}
		// @ts-ignore
		return <div className={'section-indicator ' + this.props.orientation}>
			<svg id={'section-indicator-svg'} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={cx + this.props.size} height={cy + this.props.size}  preserveAspectRatio="xMidYMid slice">
				<defs>
					<mask id="radioMask"/>
				</defs>
				<g id="mainGroup">
					<g id="circleGroup" fill="transparent" strokeWidth="1" strokeMiterlimit="10" stroke={color}>
						{circles}
					</g>
					<line id="joinLine" fill="none" strokeWidth={this.props.size / 2} strokeLinecap="square" strokeMiterlimit={this.props.size} x1={this.props.size * 1.25} y1={this.props.size} x2={this.props.size * 1.25} y2={this.props.size}/>
				</g>
			</svg>
		</div>;
	}
}
export default SectionIndicator;

/*
*
*  <circle  cx="10" cy="300" r="10"/>
                        <circle  cx="40" cy="300" r="10"/>
                        <circle  cx="70" cy="300" r="10"/>
                        <circle  cx="100" cy="300" r="10"/>
                        <circle  cx="130" cy="300" r="10"/>
                        */
