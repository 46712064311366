import * as React from 'react';
import * as GoogleAnalytics from 'react-ga';
import { withRouter } from 'react-router-dom';

GoogleAnalytics.initialize('UA-25813708-1');

const trackPage = (page, options = {}) => {
	GoogleAnalytics.set({
		page,
		...options,
	});
	GoogleAnalytics.pageview(page);
};

class Tracker extends React.Component {
	public componentDidMount(): void {
		const page = this.props.location.pathname + this.props.location.search;
		trackPage(page);
	}
	public componentDidUpdate(prevProps) {
		const currentPage =  prevProps.location.pathname + prevProps.location.search;
		const nextPage = this.props.location.pathname + this.props.location.search;

		if (currentPage !== nextPage) {
			trackPage(nextPage);
		}
	}
	public render() {
		return null;
	}
}

export default withRouter(Tracker);
