import * as React from "react";
import './FloatingVideo.less'
import { AppContext } from "../app-context";
import ClassSet from "../../utils/classSet"
export interface IFloatingVideoProps {
    inSection?: number[];
    video: {
        mp4?: any;
        webm?: any;
        poster?: any;
    }
}
type TOrientation = 'portrait' | 'landscape';
const wCo = 1.78;

export const FloatingVideo: React.FC<IFloatingVideoProps> = ({ inSection, video }) => {
    const initialWidth = window.innerWidth / wCo;
    const { currentSection, isMobile } = React.useContext(AppContext)

    const initialOrientation: TOrientation = window.innerWidth > window.innerHeight && window.innerWidth > 1023? 'landscape' : 'portrait'
    const [orientation, setOrientation] = React.useState<TOrientation>(initialOrientation)
    const [isVisible, setIsVisible] = React.useState<boolean>(!!(inSection && inSection[0] && inSection.includes(currentSection)))
    const divRef = React.useRef<HTMLDivElement>(null);
    const isVisibleRef = React.useRef<boolean>(false);
    const orientationRef = React.useRef<TOrientation>(initialOrientation);
    //console.log({ isVisible })

    React.useEffect(() => {
        const onWindowResize = (e: Event) => {
            setOrientation(window.innerWidth > window.innerHeight && window.innerWidth > 1023 ? 'landscape' : 'portrait');
        }
        window.addEventListener("resize", onWindowResize, false)
    })

    React.useEffect(() => {
        //console.log({ inSection, currentSection })
        if(inSection && inSection[0] && inSection.includes(currentSection)){
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }, [currentSection])

    React.useEffect(() => {
        if(isVisibleRef.current !== isVisible && isVisible) {
            divRef.current?.classList.add('visible');
            setTimeout(() => {
                divRef.current?.classList.add('enter');
            }, 700)
        }
        if(isVisibleRef.current !== isVisible && !isVisible) {
            divRef.current?.classList.add('exit')
            setTimeout(() => {
                divRef.current?.classList.remove('exit')
                divRef.current?.classList.remove('visible')
                divRef.current?.classList.remove('enter')
            }, 700)
        }
        if(orientation !== orientationRef.current && isVisible){
            divRef.current?.classList.add('visible');
            divRef.current?.classList.add('enter');
        }
        isVisibleRef.current = isVisible;
        orientationRef.current = orientation;
    }, [isVisible, orientation])

    const classes = ClassSet({
        "floating-video": true,
        portrait: orientation === "portrait",
        landscape: orientation === "landscape",
    })
    return <div className={classes} ref={divRef}>
        <video style={{backgroundColor: "white"}} width={"100%"} height={"100%"} poster={video.poster ?? null} className={'short-video bg-video'} autoPlay={true} loop={true} muted={true} playsInline={true}>
            {video.mp4 && <source src={video.mp4} type={"video/mp4"}/>}
            {video.webm && <source src={video.webm} type={"video/webm"}/>}
        </video>
    </div>
}