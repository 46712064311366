import * as React from 'react';
import Classet from '../../utils/classSet';

import './SlidingText.less';

export interface ISlidingTextProps {
	active?: boolean;
	className?: string;
   children?: React.ReactChild;
}
export const SlidingText = (props: ISlidingTextProps) => {
   const [state, setState] = React.useState<'show' | 'out' | 'in' | 'hidden'>('hidden');

   React.useEffect(() => {
		if (props.active) {
			setState('in');

			setTimeout(() => {
				setState('show');
			});
		} else {
			if (state === 'show') {
				setState('out');

				setTimeout(() => setState('hidden'), 500);
			}
		}
	}, [props.active]);

	const classes = Classet({
		'sliding-text': true,
		[state]: true,
		[props.className || '']: true,
	});

	const classesItem = Classet({
		'sliding-text-item': true,
		[state]: true,
		[props.className || '']: true,
	});

	if (state !== 'hidden') {
		return (
			<div className={classes}>
				<div className={classesItem}>
					{props.children}
				</div>
			</div>
		)
	} else {
		return (null);
	}
};
