import * as React from "react";
import Page from "../../components/page";
import "./aboutV4.less";
import InfoSection from "./info-section3";
import { aboutUsData } from "../../data/pages";
import { H1, H2, H3 } from "../../components/animated-container/h";
import { isIPad, isPortrait, isSafari, isIE } from "../../utils";
import SVG from "react-inlinesvg";
import ParticleImage from "../../components/particle-image";
import { SectionProps } from "../../lib/types/section";
import { debounce } from "lodash";
export default class AboutV4 extends React.Component {
    constructor(props) {
        super(props);
    }
    public componentDidMount(): void {
        this.resizeLeadershipImages(true);
        const resizer = debounce(() => this.resizeLeadershipImages(true), 500);
        window.addEventListener(
            "resize",
            () => {
                $(".image-container").removeAttr("style");
                resizer();
            },
            false
        );
    }
    public resizeLeadershipImages(withoutFooter: boolean) {
        if (window.innerWidth < 1280) return;
        setTimeout(() => {
            const imageContainers =
                document.querySelectorAll(".image-container");
            const heights: number[] = [];
            Array.from(imageContainers).forEach((container) => {
                const h =
                    $(container).height() -
                    (withoutFooter ? 0 : $("footer#main-footer").height());
                heights.push(h);
            });
            const min = Math.min.apply(Math, heights);
            $(".image-container").css({
                maxHeight: `${min}px`,
            });
        }, 500);
    }
    public renderInfoSection() {
        const { leadership } = aboutUsData;
        return leadership.map((d, i) => {
            return <InfoSection key={i} data={d} />;
        });
    }
    public render():
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | string
        | number
        | {}
        | React.ReactNodeArray
        | React.ReactPortal
        | boolean
        | null
        | undefined {
        const aboutUs = (window as any).__PAGES__.find(
            (el) => el.id === "about-us"
        );
        const classLeft = isPortrait()
            ? "section-right perc50"
            : "section-left perc50";
        const classRight = isPortrait()
            ? "section-left perc50"
            : "section-right perc50";
        const image = isIE() ? (
            <img
                width={"100%"}
                height={"100%"}
                className="hover"
                src={aboutUs.h1Image}
            />
        ) : isIPad() || isSafari() ? (
            <SVG
                style={{ marginRight: "40px" }}
                width={"100%"}
                height={"100%"}
                className="hover"
                src={aboutUs.h1Image}
            />
        ) : (
            <ParticleImage
                className={"hero-image"}
                imageUrl={aboutUs.h1Image}
            />
        );

        const sectionMain: SectionProps = {
            id: "main",
            title: aboutUs.h1,
            color: "white",
            content: (
                <>
                    <div className={classRight} id={"solutions-intro-image"}>
                        {image}
                    </div>
                    <div className={classLeft} id={"solutions-intro-text"}>
                        <H1 s={"separator l blue"} tabIndex={-1}>
                            {aboutUs.h1}
                        </H1>
                    </div>
                </>
            ),
        };
        const sectionSubtitle: SectionProps = {
            id: "subtitle",
            title: "",
            className: "page-subtitle",
            color: "white",
            content: (
                <div id={"about-us-subtitle-container"}>
                    <H2
                        s={"separator l blue"}
                        className={"text-transform-unset"}
                    >
                        {aboutUs.s1h1}
                    </H2>
                </div>
            ),
        };

        const { backgroundApproach } = aboutUsData;
        const infoBoxes = backgroundApproach.map((ba, index) => {
            return (
                <div className="info-box" key={index}>
                    <h2 className="s">{ba.title}</h2>
                    <div className={"separator sx blue"} />
                    <p className={"l"}>{ba.description}</p>
                </div>
            );
        });
        const additionalSections = aboutUs.additionalSections.map(
            (additionalSection) => {
                return {
                    id: additionalSection.id,
                    title: additionalSection.title,
                    color: "white",
                    valign: "center",
                    className: "additional-section-wrapper",
                    content: (
                        <>
                            <div
                                id={"about-" + additionalSection.id + "-grid"}
                                className={"additional-wrapper"}
                            >
                                <div className={"overlay opac-50"}></div>
                                <div
                                    id={
                                        "analytix-" +
                                        additionalSection.id +
                                        "-text"
                                    }
                                    className={
                                        "additional-section section-left perc40"
                                    }
                                >
                                    <H2
                                        className={"s title"}
                                        s={"separator sx blue"}
                                    >
                                        {additionalSection.title}
                                    </H2>
                                    <p className={"l info-detail description"}>
                                        {additionalSection.description}
                                    </p>
                                </div>
                            </div>
                        </>
                    ),
                };
            }
        );

        return (
            <Page
                isMobile={this.props.isMobile}
                id="about-us"
                sections={[
                    sectionMain,
                    sectionSubtitle,
                    ...additionalSections,
                    {
                        id: "leadership",
                        title: "Leadership",
                        color: "white",
                        valign: "center",
                        subscroll: true,
                        content: (
                            <>
                                <div className="leadership-wrapper">
                                    <div className={"bio-title"}>
                                        <H2
                                            s={"separator sx blue"}
                                            className={"s"}
                                        >
                                            Leadership
                                        </H2>
                                    </div>
                                    <div className="bio-section">
                                        {this.renderInfoSection()}
                                    </div>
                                </div>
                            </>
                        ),
                    },
                ]}
            />
        );
    }
}
