import * as React from 'react';
import Page from '../../components/page';
import './mobile.less';
import { H1, H2, H3 } from '../../components/animated-container/h';

export default class ServiceMobile extends React.Component {
  public player = null;
  public state = {
    controls: false,
    textVisibility: true,
  };

  constructor(props) {
    super(props);
  }

  public componentDidMount(): void {}

  public formatSectionData(sectionData, sectionNumber: number, key: number) {
    const sectionContent = sectionData
      .filter(dd => dd.sectionNumber == sectionNumber)
      .map((sd, i) => {
        return (
            <div key={i} className={`addition-section-box  ${sd.h1.toLowerCase()}`}>
              <div className="section-box">
                <div className={'week-number'}>
                  <div className={"circle"}/>
                  <h2 className={'week-label'}> {sd.h2}</h2>
                </div>

                <H3 s={'l'} className={'week-info'}>
                  {sd.h3}
                </H3>


                <div className={"week-text"}>{sd.text}</div>
              </div>
            </div>
        );
      });

    return {
      id: `services-addition-section-box`,
      title: sectionData[0].h1,
      color: 'black',
      valign: 'center',
      className: `services-addition-section-box ${sectionData[0].h1.toLowerCase()}`,
      content: (
        <div key={key}>
          {sectionNumber == 1 && (
            <H1 key={'h-'+key} s={'l blue'} tabIndex={-1}>
              {sectionData[0].h1}
            </H1>
          )}
          <div key={'c-'+key} className="week-content">{sectionContent}</div>
        </div>
      ),
    };
  }
  public render():
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    const selectedPage = (window as any).__PAGES__.find(
      (el: any) => el.id === 'services'
    );


    let additionalSections = [];

    const designData = selectedPage.additionalSections.filter(
      as => as.h1 == 'DESIGN'
    );
    const developmentData = selectedPage.additionalSections.filter(
      as => as.h1 == 'DEVELOPMENT'
    );

    const designSection1 = this.formatSectionData(designData, 1, 0);
    const designSection2 = this.formatSectionData(designData, 2, 1);
    const developection1 = this.formatSectionData(developmentData, 1, 2);
    const developection2 = this.formatSectionData(developmentData, 2, 3);

    const sections = [
      {
        id: 'main',
        title: selectedPage.h1,
        color: 'black',
        valign: 'center',
        content: (
          <>
            <div id={'services-main-section'}>
              <H1 s={'separator l blue'} tabIndex={-1}>
                {selectedPage.h1}
              </H1>
              <H2 s={'l'} className={' text-transform-unset'}>
                {selectedPage.s1h1}
              </H2>
            </div>
          </>
        ),
      },
      designSection1,
      designSection2,
      developection1,
      developection2,
      {
        id: 'work-with-us',
        title: 'work-with-us',
        color: 'black',
        valign: 'center',
        content: (
            <>

                <H1 s={'l'} tabIndex={-1}>
                  Fixed time, fixed budget.
                </H1>
                <a href={'/contact'}>Work with us</a>
            </>
        ),
      },
    ];

    return (
      <Page
        isMobile={this.props.isMobile}
        id="services-page-mobile"
        sections={sections}
      />
    );
  }
}
