import * as React from 'react';

import Page from '../../components/page';
import Button from '../../components/button';
import isEqual from 'lodash/fp/isEqual';
import './index.less';
import { Products } from '../products/homepage/products';
import { withRouter } from 'react-router-dom';
import { H1, H2, H3, H4, H5, H6 } from '../../components/animated-container/h';
import {isIE, isMobile} from "../../utils";

const IntroVideoMP4 = require('../../assets/videos/intro-loop.mp4');
const IntroVideoWEBM = require('../../assets/videos/intro-loop.webm');
const SolutionsVideo = require('../../assets/videos/home/solutions.mp4');
const MethodologyVideo = require('../../assets/videos/home/methodology.mp4');

const ImgHero = require('../../assets/images/home/Hero.jpg');
const ImgMethodology = require('../../assets/images/home/methodology.jpg');
const ImgSolutions = require('../../assets/images/home/solutions.jpg');

const count = 7;

class Home extends React.Component {
	public _isMounted = false;
	public state = {
		solutionImages: [],
	};
	constructor(props) {
		super(props);
	}
	public shouldComponentUpdate(nextProps, nextState) {
		if (isEqual(this.props, nextProps) && isEqual(this.state, nextState)) {
			return false;
		}
		return true;
	}
	public componentDidMount(): void {
		this._isMounted = true;
		window.addEventListener('scrollToBeginningOfPage', ()=>document.getElementById('page-start-anchor')?.scrollIntoView())

	}
	public componentWillUnmount() {
		this._isMounted = false;
	}
	public scrollToSecondScreen() {
		if (window.innerWidth < 768) {
			window.scrollTo({
				top: window.innerHeight - 60,
				behavior: 'smooth',
			});
		} else {
			$.fn.pagepiling.moveSectionDown();
		}
	}
	public render() {
		const page = (window as any).__PAGES__.find((p:any) => p.id === 'home');
		const Hero = isIE() ? <div style={{position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, background: 'url('+ImgHero+') no-repeat center', backgroundSize: 'cover'}}></div> :
			<video poster={ImgHero} autoPlay={true} loop={true} id="video-background" muted={true} playsInline={true}>
				<source src={IntroVideoMP4} type="video/mp4" />
				<source src={IntroVideoWEBM} type="video/webm" />
			</video>
		const Solutions = isIE() ? <div style={{position: 'relative', width: '100%', minHeight: '30rem', height: '100%', background: 'url('+ImgSolutions+') no-repeat center', backgroundSize: 'cover'}}></div> :
			<video poster={ImgSolutions} className={'short-video bg-video'} autoPlay={true} loop={true}id="solutions-video-background" muted={true} playsInline={true}>
				<source src={SolutionsVideo} type="video/mp4" />
			</video>
		const Methodology = isIE() ? <div style={{position: 'relative', width: '100%', minHeight: '30rem', height: '100%', background: 'url('+ImgMethodology+') no-repeat center', backgroundSize: 'cover'}}></div> :
			<video poster={ImgMethodology} className={'short-video bg-video'} autoPlay={true} loop={true}id="methodology-video-background" muted={true} playsInline={true}>
				<source src={MethodologyVideo} type="video/mp4" />
			</video>
		return <Page
			isMobile={this.props.isMobile}
			id={'home-page'}
			sections={[
				{
					id: 'main',
					title: page.h1,
					color: 'black',
					valign: 'center',
					className: 'full-page',
					content: <>

						<div id={'home-intro-video'}>
							{Hero}
						</div>
						<div id={'home-intro-video-text'}>
							<H1 s={'separator m white'} tabIndex={-1} className={'s'}>{page.h1}</H1>
						</div>
						<button style={isMobile() ? {display: 'none'} : null} aria-label={'go to the next section'} onClick={() => this.scrollToSecondScreen()} className={'scroll-down-arrow'} type={'button'}></button>

					</>,
				},
				{
					id: 'subtitle',
					title: '',
					color: 'white',
					content: <H2 s={'separator l blue'} className={'text-transform-unset'}>{page.s1h1}</H2>,
				},
				{
					id: 'products',
					title: 'Products',
					color: 'white',
					valign: 'center',
					content: <>
						<div id={'home-solutions-text'} className={'section-left perc40'}>
							<H2 className={'s title'} s={'separator sx blue'} >
								CONNECT YOUR LEGACY TO THE FUTURE
							</H2>
							<p className={'xl info-detail description'}>
								Drive your digital transformation with products that make a difference.
							</p>
							<p style={{margin: 0}}><Button style={{marginBottom: "0px"}} color={'blue'} onClick={(e) => this.props.history.push('/products')} label={'DISCOVER PRODUCTS'} /></p>
						</div>
						<div className={'section-right perc60'} id={'home-solutions-image'}>
							<Products />
						</div>
					</>,
				},
				{
					id: 'solutions',
					title: 'Solutions',
					color: 'white',
					valign: 'center',
					content: <>
						<div id={'home-solutions-text'} className={'section-left perc40'}>
							<H2 className={'s title'} s={'separator sx blue'} >
								CUSTOM SOLUTIONS TO FIT YOUR NEEDS
							</H2>
							<p className={'xl info-detail description'}>
								We design, develop micro applications with a fixed budget and in record time.
							</p>
							<p style={{margin: 0}}><Button style={{marginBottom: "0px"}} color={'blue'} onClick={(e) => this.props.history.push('/services')} label={'VIEW SERVICES'} /></p>
						</div>
						<div className={'section-right perc60'} id={'home-solutions-image'}>
							{Solutions}
						</div>
					</>,
				},
				{
					id: 'methodology',
					title: 'Methodology',
					color: 'white',
					valign: 'center',
					content: <>
						<div id={'home-methodology-text'} className={'section-left perc40'}>
							<H2 className={'s title'} s={'separator sx blue'} >
								WE SEE, THINK AND DO THINGS DIFFERENTLY
							</H2>
							<p className={'xl info-detail description'}>
								Global leader in UI/UX frameworks and pioneers of HTML5 and micro app technologies.
							</p>
							<p style={{margin: 0}}><Button style={{marginBottom: "0px"}} color={'blue'} onClick={(e) => this.props.history.push('/about-us')} label={'ABOUT US'} /></p>

						</div>
						<div id={'home-methodology-image'} className={'section-right perc60'}>
							{Methodology}
						</div>
					</>,
				},
			]}
		>

		</Page>;
	}
}

export default withRouter(Home);
