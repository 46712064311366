import * as React from 'react';
import Button from '../button';
import { Link } from 'react-router-dom';
class AcceptCookie extends React.Component {
	public state = {
		visible: false,
	};
	public componentDidMount(): void {
		const accepted = window.localStorage.getItem('privacypolicycookieaccepted');
		const visible = !accepted;
		this.setState({visible});
	}
	public acceptCookies = () => {
		window.localStorage.setItem('privacypolicycookieaccepted', true);
		setTimeout(() => {
			const accepted = window.localStorage.getItem('privacypolicycookieaccepted');
			const visible = !accepted;
			this.setState({visible});
		}, 100);
	}
	public render() {
		if (!this.state.visible) { return null; }
		return <div className={'accept-cookie-box'}>
			<span className={'content'}>We use cookies to ensure you have the best experience on our website. By continuing, you agree to our <Link to={'/privacy-policy'}>privacy policy</Link>.</span>
			<Button color={'white'} label={'ACCEPT'} onClick={this.acceptCookies}/>
		</div>;
	}
}

export default AcceptCookie;
