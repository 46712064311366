import * as React from 'react';
import ProductCard from './../product-card';
import { withRouter } from 'react-router-dom';
// @ts-ignore
import './index.less';
import { Products, productSort } from '../../data/products';
import { H2 } from '../animated-container/h';

export interface IExploreProps {
	product: string;
	title: boolean;
	allProducts: boolean;
	noWidth: boolean;
	layout?: 'grid' | 'rows';
	isMobile?: boolean;
}

class Explore extends React.Component<IExploreProps, {}> {
	private productsGrid: any;
	constructor(props) {
		super(props);
		this.productsGrid = null;
	}
	componentDidMount(): void {
		this.adjustGridHeight();
		window.addEventListener('resize', ()=>this.adjustGridHeight(), false)
	}
	componentWillUnmount(): void {
		window.removeEventListener('resize', ()=>this.adjustGridHeight(), false)
	}

	private adjustGridHeight() {
		let zoom = window.innerHeight < 695 && window.innerWidth > 1279 ? window.innerHeight /735 : 1;
		this.productsGrid?.style?.transformOrigin = `center center`;
		this.productsGrid?.style?.transform = `scale(${zoom})`;
	}
	private isSelected (id: string): boolean {
		const isSelected = id.trim().toLowerCase() === this.props.location?.pathname?.replace("/", "")?.trim().toLowerCase()
		return isSelected;
	}
	public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
		const filteredProducts = Products.sort((a, b) => productSort.indexOf(a.product) - productSort.indexOf(b.product)).filter(p => !this.isSelected(p.product));
		const {product, title, allProducts, noWidth, isMobile, layout} = this.props;
		if(isMobile) {
			return <div id={'explore-more-products'}>
				{title && <H2 className={'section-header with-dots'}>Explore more</H2>}
				<div className={'products-grid'}>
					<div className={'column'}>
						{filteredProducts.map((p, index) => {
							return <ProductCard {...p} key={index + product} id={product} className={"mobile"} isMobile={true} />;
						})}
					</div>
				</div>
			</div>;
		}
		return <div id={'explore-more-products'} ref={ref => this.productsGrid = ref}>
			{title && <H2 className={'s section-header with-dots'}>Explore more<span className={"title-elipsis"}>...</span></H2>}
			<div className={'products-grid'}>
				<div id={'products-grid-row1'} className={'row 1'}>
					{filteredProducts.map((p, index) => {
						if (index <= 2) {
							return <ProductCard {...p} key={index + product} id={product} isLink/>;
						}
						return;
					})}
				</div>
				<div id={'products-grid-row2'} className={'row 2'}>
					{filteredProducts.map((p, index) => {
						if (index > 2) {
							return <ProductCard {...p} key={index + product} id={product} isLink/>;
						}
						return;
					})}
					{/*filteredProducts.length === 5 && !isMobile ? <div className={'product-card'} style={{backgroundColor: 'transparent', visibility: 'hidden', opacity: 0}}></div>: null*/}
				</div>
			</div>
		</div>;
	}
}
export default withRouter(Explore)