import * as React from 'react';
import './apps.less';
import Button from '../../components/button';
import { getHistory, GlobalHistory } from '../../lib/history';
import IPageData from '../../lib/types/pagedata';
import Link from "../../components/link/arrow-right";
interface AppsProps {
	page: IPageData;
}
export default ({page}: AppsProps) => {
	if (!page.appsSection) { return null; }
	const apps = page.appsSection.apps;
	return <div className={'apps-section'}>
		<GlobalHistory/>
		<h3>{page.appsSection.title}</h3>
		<div className={'apps'}>{
			apps.map((app, i) => {
				return <div key={i} className={'app'}>
					<div className={'app-icon ' + app.id}></div>
					<div className={app.id === 'word' ? 'app-label word' : 'app-label' }>{app.title}</div>
				</div>;
			})
		}

		</div>
	</div>;
};
