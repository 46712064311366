import * as React from 'react';

//import initLax  from '../../utils/lax'
import './index.less';
import { SectionProps } from '../../lib/types/section';
import Section from '../section';
import Classet from '../../utils/classSet';
import SectionIndicator from './section-indicator';
import Footer from '../footer';
import { withRouter } from 'react-router-dom';
import Tracker from '../tracker';
import {isIE, isMobile} from '../../utils';
import PageContainer from './PageContainer'
import { AppContext } from "../app-context";


interface IPageProps {
	classes?: string;
	id: string;
	sections: SectionProps[];
	sectionOnLeave?: (index: number, nextIndex: number, direction: string) => void;
	sectionOnEnter?: (index: number, prevIndex: number) => void;
	afterLoad?: (anchorLink: string, index: number) => void;
	scrollingSection?: any;
	location: any;
	scrollType?: 'pagepiling' | 'parallax';
	isMobile: boolean;
	isProduct?: boolean;
}

interface IPageState {
	sectionIndex: number;
	loadingSectionIndex: number;
	scrollDirection: string;
	opacity: number;
	redraw: boolean;
}

class Page extends React.PureComponent<IPageProps, IPageState> {
	public _isMounted = false;
	public scrollingSectionImg = null;
	public scrollingSectionVideo = null;
	public pageContent = null;
	private pageStartAnchor: Element | null;
	static contextType = AppContext;
	constructor(props: IPageProps) {
		super(props);
		this.state = {
			sectionIndex: 1,
			loadingSectionIndex: 1,
			scrollDirection: 'down',
			opacity: 0,
			redraw: false
		};
		this.pageStartAnchor = null;
	}

	public componentDidUpdate(prevProps: Readonly<IPageProps>, prevState: Readonly<IPageState>, snapshot?: any): void {
		if (!isMobile() && this.props.location.pathname === prevProps.location.pathname && this.props.location.key !== prevProps.location.key ) {
			$.fn.pagepiling.moveTo(1);
		}
		if (isMobile() && this.props.location.pathname === prevProps.location.pathname && this.props.location.key !== prevProps.location.key ) {
			window.scrollTo(0,0)
		}
		if(this.props.location.pathname !== prevProps.location.pathname) {
			this.resizeMobile()
		}
		if(prevState.loadingSectionIndex !== this.state.loadingSectionIndex) {
			this.context.setCurrentSection(this.state.loadingSectionIndex);
		}
	}

	public componentDidMount(): void {
		if(isMobile()){
			setTimeout(() => this.pageStartAnchor?.scrollIntoView(), 100)
		}
		this._isMounted = true;
		setTimeout(() => this.initPagePiling(), 100);
		setTimeout(() => this.setState({ opacity: 1 }), 100);
		this.resizeMobile()
		window.addEventListener('resize', ()=>this.resizeMobile(), false);
		this.context.setCurrentSection(1)

	}
	private resizeMobile() {
		/*if(this.props.isMobile) {
			$('section.mobile-website-section').css({
				//height: (window.innerHeight - 60) + 'px',
				minHeight: (window.innerHeight - 60) + 'px',
			})
		}*/
	}
	public initPagePiling() {
		// initLax()
		const normalScrollElements = [];
		this.props.sections.forEach((s) => {
			if (s.subscroll && ((typeof s.subscroll !== 'boolean' && s.subscroll.children && s.subscroll.parent) || typeof s.subscroll === 'boolean')) {
				normalScrollElements.push('#' + this.props.id + '-' + s.id);
			}
		});
		if (isMobile()) { return; }

		$('.page-content').pagepiling({
			menu: null,
			direction: 'vertical',
			animationDelay: (window as any).scrollAnimationDelay,
			verticalCentered: true,
			sectionsColor: [],
			anchors: [],
			scrollingSpeed: (window as any).scrollingSpeed,
			easing: 'linear',
			loopBottom: false,
			loopTop: false,
			css3: true,
			navigation: false,
			normalScrollElements: normalScrollElements.join(','),
			normalScrollElementTouchThreshold: 5,
			touchSensitivity: 5,
			keyboardScrolling: true,
			sectionSelector: 'section.website-section',
			animateAnchor: true,

			//events
			onLeave: (index, nextIndex, direction) => {
				if (!this._isMounted) { return; }
				if (typeof this.props.sectionOnLeave === 'function') {
					this.props.sectionOnLeave(index, nextIndex, direction);
				}
				this.setState({
					scrollDirection: direction,
				});

				if (nextIndex === this.props.sections.length) {
					$('main.page').addClass('withFooter');
					$('footer#main-footer').addClass('visible');
				} else {
					$('main.page').removeClass('withFooter');
					$('footer#main-footer').removeClass('visible');
				}

				this.showHideScrollingSectionImg(index, nextIndex, direction);
			},
			onEnter: (index, prevIndex) => {
				if(typeof this.props.sectionOnEnter === 'function'){
					this.props.sectionOnEnter(index, prevIndex);
				}
				const ActiveSectionChangeEvent = new CustomEvent('ActiveSectionChange', { detail: { activeSection: index } });
				window.dispatchEvent(ActiveSectionChangeEvent);
				//loadingSectionIndex
				this.setState({
					loadingSectionIndex: index,
				});
			},
			afterLoad: (anchorLink, index) => {
				if (!this._isMounted) { return; }
				this.setState({
					sectionIndex: index,
				});
			},
			afterRender: () => {

			},
		});
	}
	public showHideScrollingSectionImg(index, nextIndex, direction) {
		if (!this.props.scrollingSection || !this.props.scrollingSection.texts || this.props.scrollingSection.texts === 0) { return; }
		if (!this.scrollingSectionImg) { return; }
		if (nextIndex >= 3 && nextIndex <= this.props.scrollingSection.texts.length + 2) {
			const isVisible = $(this.scrollingSectionImg).hasClass('visible');
			if (!isVisible) {
				$(this.scrollingSectionImg).css({
					transform: 'translateY(' + (direction === 'up' ? '-100vh' : '100vh') + ')',
				});
				setTimeout(() => {
					$(this.scrollingSectionImg).addClass('visible');
					if (this.scrollingSectionVideo && this.scrollingSectionVideo.play) {
						this.scrollingSectionVideo.load();
						this.scrollingSectionVideo.play();
					}
				}, 50);
			}
		} else {
			$(this.scrollingSectionImg).addClass('exiting').css({
				transform: 'translateY(' + (direction === 'up' ? '100vh' : '-100vh') + ')',
			});
			setTimeout(() => {
				$(this.scrollingSectionImg).removeClass('visible');
				if (this.scrollingSectionVideo && this.scrollingSectionVideo.pause) {
					this.scrollingSectionVideo.pause();
				}
				setTimeout(() => {
					$(this.scrollingSectionImg).removeClass('exiting');
				}, 700);
			}, 50);

		}
	}
	public componentWillUnmount() {
		this._isMounted = false;
	}
	public render() {
		const classes = Classet({
			page: true,
			[this.props.classes]: true,
			withFooter: this.props.sections.length === 1,
			singleSection: this.props.sections.length === 1,
			'product-page': this.props.isProduct,
		});

		const content = this.props.sections && Array.isArray(this.props.sections) && this.props.sections.map((s, i) => {
			const classes = Classet({
				['section-' + (i + 1)]: true,
				[this.props.id + '-section']: true,
				[(s.className as any)]: true
			})
			return (
				<Section
					active={(this.state.sectionIndex === i + 1)}
					activating={(this.state.loadingSectionIndex === i + 1)}
					key={'section'+i}
					color={s.color}
					content={s.content}
					id={s.id}
					subscroll={s.subscroll}
					pageId={this.props.id}
					title={s.title}
					withFixedImage={s.withFixedImage}
					index={i}
					className={classes}
					style={s.style}
					withFooter={(i === this.props.sections.length - 1)}
				/>
			);
		});
		const color = this.props.sections && Array.isArray(this.props.sections) && this.state.sectionIndex && this.props.sections[this.state.sectionIndex - 1] && this.props.sections[this.state.sectionIndex - 1].color ? this.props.sections[this.state.sectionIndex - 1].color : 'white';
		const sectionIndicator = this.props.sections && this.props.sections.length !== 1  ?
			<SectionIndicator goTo={() => true} {...this.state} sectionsCount={this.props.sections.length} orientation={'vertical'} sectionColor={color} size={16}/> : null;


		let scrollingSectionAsset = null;
		if(this.props.scrollingSection){
			if(this.props.scrollingSection.img && (isIE() || isMobile())){
				scrollingSectionAsset = (
					<div ref={(ref) => this.scrollingSectionImg = ref} className={'solutions-scrolling-section-image '+this.props.scrollingSection.classes} id={'solutions-' + this.props.id + '-scrolling-section-image'}>
						<img src={this.props.scrollingSection.img}/>
					</div>
				)
			}
			if(this.props.scrollingSection.video && !(isIE() || isMobile())) {
				scrollingSectionAsset = (
					<div ref={(ref) => this.scrollingSectionImg = ref} className={'solutions-scrolling-section-image '+ this.props.scrollingSection.classes} id={'solutions-' + this.props.id + '-scrolling-section-image'}>
						<video ref={(ref) => this.scrollingSectionVideo = ref} className={'bg-video normal-size'} autoPlay={isMobile() ? false : false} loop={true} id="solutions-video" muted={true} playsInline={true}>
							{
								this.props.scrollingSection.video.map((source:any, count:number) => <source key={count} src={source.file} type={'video/' + source.type}/>)
							}

						</video>
					</div>
				)
			}
		}

		return <>
			<main tabIndex={-1} style={{ opacity: this.state.opacity }} id="main-content" className={classes}>
				<div ref={ref => this.pageStartAnchor = ref} id={'page-start-anchor'}></div>
				<div id={this.props.id} className={'page-content'} ref={ref => this.pageContent = ref}>{content}</div>
				{sectionIndicator}
				<div id="navigation-portal-container"></div>
				{scrollingSectionAsset}
			</main>
			<Footer className={this.props.sections.length === 1 ? ' visible' : ''}/>
			<Tracker/>
		</>;
	}
}

export default withRouter(PageContainer(Page));
