import * as React from 'react';
import ImageTag from '../../components/image-tag/image-tag';
import { H3, H4 } from '../../components/animated-container/h';
interface InfoSectionProps {
	data: any;
}
export default class InfoSection extends React.Component<InfoSectionProps> {
	public section = undefined;
	constructor(props) {
		super(props);
	}

	componentDidMount(): void {

	}

	public render():
		| React.ReactElement<any, string | React.JSXElementConstructor<any>>
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {
		return (
			<div className={'info-section ' + this.props.data.key} 		ref={(ref) => this.section = ref}>
				<div className={"image-container " + this.props.data.key}></div>
				<div className="info-section-child description-box">
					<div className={"name"}>
						<H3 className="">
							{this.props.data.fullName}
						</H3>
						<H4 className={'xsmall'}>{this.props.data.position}</H4>
					</div>
					<div className="description-box-child longtext">
						<p className="description">
							{this.props.data.description}
						</p>
					</div>
				</div>
			</div>
		);
	}
}
