export function isMobile() {
	if(window.innerWidth < 768){
		return true;
	}
	if(window.innerWidth < 900 && window.innerHeight < 500){
		return true;
	}

	return false
}
export function isIPad() {
	//console.error(navigator.userAgent)
	return !isIPhone() && (navigator.platform.match(/iPad/i) !== null || (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2));
}
export function isFirefox() {
	return navigator.userAgent.match(/Firefox/i) !== null

}
export function isTouch() {
	return 'ontouchstart' in document;
}
export function isIPhone() {
	return navigator.platform.match(/iPhone/i) !== null || isMobile();
}

export function isPortrait() {
	return window.innerWidth < window.innerHeight;
}

export function isSquare() {
	return window.innerWidth === window.innerHeight;
}

export function isIE() {
	return !!(window as any).msCrypto || (window as any).fromAxcept === true;
}
export function isSafari() {
	return !!(window as any).safari;
}
export const hasWindow = () => typeof window !== 'undefined';
