export interface IProductData {
	key: string;
	title: string;
	mTitle?: string;
	description: string;
	mobile?: string;
	video?: Array<{
		file: any;
		type: 'mp4' | 'webm'
	}>;
	img?: any;
}

export interface IScrollingSection {
	classes?:string;
	video?: Array<{
		file: any;
		type: 'mp4' | 'webm'
	}>;
	img?: any;
	texts:Array<{
		key: string;
		title: string;
		description: string;
		mobile: string;

	}>;

}
type EPdocutName = 'ARTHOS' | 'MATRIX' | 'AXCEPT' | 'ANALYTIX' | 'INTRO-X' | 'NEXUS';
export interface IProduct {
	product: EPdocutName;
	description: string[];
	color: string;
	hasVideoPlayer?:boolean;
	data?: IProductData[];
	scrollingSection?:IScrollingSection;
}

export const Products: IProduct[] = [
	{
	product: 'ARTHOS',
	description: ['The workspace built', 'for the enterprise.'],
	color: 'dark-blue',
	data: [
		{
			key: 'combination',
			title: 'Consolidate disjointed applications',
			mTitle: 'Combine the best of the desktop and the browser',
			description: 'Central access point to immersive interactions with your entire application portfolio - legacy, new and third-party web apps, corporate intranet and the web, all in one.',
			mobile: 'Build and run web apps as native applications on Windows, Mac and Linux. Unify your internet, intranet and application portfolio.',
			video: [
				{
					file: require('../assets/images/product/Arthos/01-Combine-the-best-of-the-desktop-and-the-browser.mp4'),
					type: 'mp4',
				},
				{
					file: require('../assets/images/product/Arthos/01-Combine-the-best-of-the-desktop-and-the-browser.webm'),
					type: 'webm',
				},
			],
			img: require('../assets/images/product/Arthos/01-Combine-the-best-of-the-desktop-and-the-browser.jpg')
		},

		{
			key: 'connection',
			title: 'Create workspaces to fit your needs',
			mTitle: 'Connect the old and the new',
			description: 'Highly customisable, 3 tier UX architecture combining the best of the desktop and web. Create personalised workspaces that are role-based, process-focussed and context aware.',
			mobile: 'Whether you have new, legacy or third party applications, Arthos connects them into one unified, cross-functional experience. Share context between applications and build logical links for a fully interconnected workspace. ',
			video: [
				{
					file: require('../assets/images/product/Arthos/02-Connect-the-old-and-the-new.mp4'),
					type: 'mp4',
				},
				{
					file: require('../assets/images/product/Arthos/02-Connect-the-old-and-the-new.webm'),
					type: 'webm',
				},
			],
			img: require('../assets/images/product/Arthos/02-Connect-the-old-and-the-new.jpg')
		},
		{
			key: 'tailor',
			title: 'Establish logical and optical bindings',
			mTitle: 'Tailor Arthos the way you want',
			description: 'Create instant added utility from disparate, monolithic applications by connecting and transforming them into meaningful, purpose built workspaces.',
			mobile: 'Whether it’s your workspace or tray icon, brand Arthos the way you want. Create different themes and views depending on the role or use case.',
			video: [
				{
					file: require('../assets/images/product/Arthos/03-Tailor-Arthos-the-way-you-want.mp4'),
					type: 'mp4',
				},
				{
					file: require('../assets/images/product/Arthos/03-Tailor-Arthos-the-way-you-want.webm'),
					type: 'webm',
				},
			],
			img: require('../assets/images/product/Arthos/03-Tailor-Arthos-the-way-you-want.jpg')
		},

		{
			key: 'streamline',
			title: 'Develop once, run everywhere',
			mTitle: 'Streamline development and distribution',
			description: 'Run applications natively on multiple OS - Windows, Mac, Linux and Web - with extensions to mobile.',
			mobile: 'Unify development processes with a centralised design system and component library. Equip teams with all the DevOps tools to ensure continuous optimisation and innovation.',
			video: [
				{
					file: require('../assets/images/product/Arthos/04-Streamline-your-development-and-distribution.mp4'),
					type: 'mp4',
				},
				{
					file: require('../assets/images/product/Arthos/04-Streamline-your-development-and-distribution.webm'),
					type: 'webm',
				},
			],
			img: require('../assets/images/product/Arthos/04-Streamline-your-development-and-distribution.jpg')
		},

		{
			key: 'protect',
			title: 'Connect your legacy to the future',
			mTitle: 'Protect your desktop',
			description: 'Eliminate technical debt by integrating legacy, new and third party applications instantly. Adopt a pragmatic, sustainable approach to digital transformation.',
			mobile: 'Arthos SecureGuard constantly monitors, detects and resolves any potential security threats to the highest standard. Benefit from built-in sandboxing, advanced permission settings and regular security updates.',
			video: [
				{
					file: require('../assets/images/product/Arthos/05-Protect-your-desktop.mp4'),
					type: 'mp4',
				},
				{
					file: require('../assets/images/product/Arthos/05-Protect-your-desktop.webm'),
					type: 'webm',
				},
			],
			img: require('../assets/images/product/Arthos/05-Protect-your-desktop.jpg')
		},
		{
			key: 'protect',
			title: 'Be guided by data-driven insights',
			description: 'Capture, analyse and monitor user behaviour. Base development and technology investment decisions around empirical data rather than opinion and guess work.',
		},
		{
			key: 'protect',
			title: 'Configure with ease',
			description: 'Manages hundreds of applications and tens of thousands of users. Create custom configurations on the fly, satisfying bespoke needs.',
		},
		{
			key: 'protect',
			title: '360 approach to transformation',
			description: 'Harness the benefits of advanced features, enhanced security, accessibility compliance and development toolkits.	',
		}
	],
},
	{
		product: 'MATRIX',
		description: ['Data interaction', 'of the future.'],
		color: 'yellow',
		data: [
			{
				key: 'stream',
				title: 'Stream and load data instantly',
				description: 'Matrix is optimised for handling large, real-time data sets at maximum performance. Load millions of data records per second with live stream updates.',
				mobile: 'Load millions of data records per second with live stream updates.',
				img: require('../assets/videos/matrix/Stream-and-Load-Data-Instantly.jpg'),
				video: [
					{
						file: require('../assets/videos/matrix/Stream-and-Load-Data-Instantly.mp4'),
						type: 'mp4',
					}
				]},
			{
				key: 'filter',
				title: 'Real-time report builder',
				description: 'Search, sort, pin, expand/collapse and reorder columns. Group, aggregate data, apply multiple filters and create custom reports with live updates. Save and export to Excel when required.',
				mobile: 'Filter, save and export custom data sets to access information you need.',
				img: require('../assets/videos/matrix/Realtime-Report-Builder.jpg'),
				video: [
					{
						file: require('../assets/videos/matrix/Realtime-Report-Builder.mp4'),
						type: 'mp4',
					}
				]},
			{
				key: 'fast',
				title: 'Fast-track manual processes',
				description: 'Access the information you need fast with intuitive, straightforward controls. Use actionable items and drill down functionality to streamline workflows and processes.',
				mobile: 'Fast-track manual processes with actionable items and drill-down functionality.',
				img: require('../assets/videos/matrix/Fast-Track-Manual-Processes.jpg'),
				video: [
					{
						file: require('../assets/videos/matrix/Fast-Track-Manual-Processes.mp4'),
						type: 'mp4',
					}
				]},
			{
				key: 'actionable',
				title: 'Actionable insights',
				description: 'Gain real, valuable intelligence rapidly with machine learning insights, key summaries and data visualisation.',
				mobile: 'Gain intelligence from your data with the Matrix machine-learning optimised insights panel.',
				img: require('../assets/videos/matrix/Actionable-Insights.jpg'),
				video: [
					{
						file: require('../assets/videos/matrix/Actionable-Insights.mp4'),
						type: 'mp4',
					}
				]},
			{
				key: 'yourown',
				title: 'Make Matrix your own',
				description: 'Customise views the way you want with highly flexible UI theming and branding. Create and save custom grid layouts to get straight to the insights you need, when you need.',
				mobile: 'Customise Matrix with highly flexible UI theming and branding.',
				img: require('../assets/videos/matrix/Make-Matrix-your-Own.jpg'),
				video: [
					{
						file: require('../assets/videos/matrix/Make-Matrix-your-Own.mp4'),
						type: 'mp4',
					}
				]},
		],
	},
	{
	product: 'AXCEPT',
	description: ['Automated UI', 'testing at scale.'],
	color: 'green',
		data: [
			{
				key: 'build',
				title: 'Build automated tests ',
				description: 'Build UI tests using simple building blocks. No coding or programming required. Preview and inspect while you build to ensure your tests run seamlessly.',
				mobile: 'Build UI tests using simple building blocks. No coding or programming required.',
				video: [
					{
						file: require('../assets/videos/axcept/BuildAutomatedTests.mp4'),
						type: 'mp4',
					},
					{
						file: require('../assets/videos/axcept/BuildAutomatedTests.webm'),
						type: 'webm',
					},
				],
				img: require('../assets/videos/axcept/BuildAutomatedTests.jpg')
			},
			{
				key: 'execute',
				title: 'Execute at enterprise level',
				description: 'Run thousands of automated tests simultaneously across multiple resolutions, screens and languages. ',
				mobile: 'Run thousands of automated tests simultaneously across multiple resolutions, screens and languages. ',
				video: [
					{
						file: require('../assets/videos/axcept/ExecuteAtEnterpriseLevel.mp4'),
						type: 'mp4',
					},
					{
						file: require('../assets/videos/axcept/ExecuteAtEnterpriseLevel.webm'),
						type: 'webm',
					},
				],
				img: require('../assets/videos/axcept/ExecuteAtEnterpriseLevel.jpg')
			},
			{
				key: 'detect',
				title: 'Detect any unwanted change instantly',
				description: 'Detect, inspect and accept any changes or broken features at high speed. Eliminate visual bugs before they’re released to your users.',
				mobile: 'Detect, inspect and accept any changes or broken features at high speed.',
				video: [
					{
						file: require('../assets/videos/axcept/DetectAnyUnwantedChanges.mp4'),
						type: 'mp4',
					},
					{
						file: require('../assets/videos/axcept/DetectAnyUnwantedChanges.webm'),
						type: 'webm',
					},
				],
				img: require('../assets/videos/axcept/DetectAnyUnwantedChanges.jpg')
			},
			{
				key: 'deliver',
				title: 'Deploy with confidence',
				description: 'Confidently deliver and deploy pixel perfect software and a consistent UI. Maintain high code standards with code coverage reports. ',
				mobile: 'Confidently deliver and deploy pixel perfect software and a consistent UI.',
				video: [
					{
						file: require('../assets/videos/axcept/DeployWithConfidence.mp4'),
						type: 'mp4',
					},
					{
						file: require('../assets/videos/axcept/DeployWithConfidence.webm'),
						type: 'webm',
					},
				],
				img: require('../assets/videos/axcept/DeployWithConfidence.jpg')
			},
		]




},
	{
		product: 'ANALYTIX',
		description: ['Real-time analytics', 'and insights.'],
		color: 'purple',
		hasVideoPlayer:false,
		data: [
			{
				key: 'gain',
				title: 'Gain the insights you need',
				description: 'Utilise built-in insights and usage metrics. Detect unused applications, hidden obstacles and unusual user behaviour patterns. ',
				mobile: 'Utilise built-in insights and usage metrics. Detect unused applications, hidden obstacles and unusual user behaviour patterns.',
				img: require('../assets/videos/analytix/Gain-the-Insights-you-Need.jpg'),
				video: [{
					file: require('../assets/videos/analytix/Gain-the-Insights-you-Need.mp4'),
					type: 'mp4',
				}],
			},
			{
				key: 'dashboards',
				title: 'Create custom dashboards',
				description:  'Gain a holistic view of user behaviour, application interaction and performance using customised dashboards built to your needs. ',
				mobile:  'Gain a holistic view of user behaviour, application interaction and performance using customised dashboards built to your needs. ',
				img: require('../assets/videos/analytix/Create-Custom-Dashboards.jpg'),
				video: [{
					file: require('../assets/videos/analytix/Create-Custom-Dashboards.mp4'),
					type: 'mp4',
				}],
			},
			{
				key: 'decisions',
				title: 'Make informed decisions',
				description: 'Save time and resources by basing future investments around your user and business needs. Understand where to adapt and improve.',
				mobile: 'Save time and resources by basing future investments around your user and business needs.',
				img: require('../assets/videos/analytix/Make-Informed-Decisions.jpg'),
				video: [{
					file: require('../assets/videos/analytix/Make-Informed-Decisions.mp4'),
					type: 'mp4',
				}],
			},
			{
				key: 'integrate',
				title: 'Integrate the way you want',
				description: 'Send Analytix metrics straight to your existing reporting tools, or harness the cloud hosted solution for fast, cost-effective access to the insights you need.',
				mobile: 'Send Analytix metrics straight to your existing reporting tools, or harness the cloud hosted solution for fast, cost-effective access to the insights you need.',
				img: require('../assets/videos/analytix/Integrate-the-Way-you-Want.jpg'),
				video: [{
					file: require('../assets/videos/analytix/Integrate-the-Way-you-Want.mp4'),
					type: 'mp4',
				}],
			},
		],
	},
	{
	product: 'INTRO-X',
	description: ['Digital adoption', 'platform.'],
	color: 'blue',
	hasVideoPlayer:false,
	data: [
		{
			key: 'fasttrack',
			title: 'Fast-track users’ learning processes',
			description: 'Guide users through applications and processes via intuitive, step-by-step onboarding flows, videos and tool-tips. ',
			mobile: 'Guide users through applications and processes via intuitive, step-by-step onboarding flows, videos and tool-tips. ',
			img: require('../assets/videos/introx/Fast-Track-Your-Users-Learning-Processes.jpg'),
			video: [
				{
					file: require('../assets/videos/introx/Fast-Track-Your-Users-Learning-Processes.mp4'),
					type: 'mp4',
				},
			]
		},
		{
			key: 'tutorials',
			title: 'Create custom tutorials and onboarding experiences',
			description: 'Give business and product owners the ability to create custom, interactive tutorials. Personalise experiences and target specific user groups.',
			mobile: 'Give business and product owners the ability to create custom, interactive tutorials. Personalise experiences and target specific user groups.',
			img: require('../assets/videos/introx/Create-Custom-Tutorials-and-Onboarding-Experiences.jpg'),
			video: [
				{
					file: require('../assets/videos/introx/Create-Custom-Tutorials-and-Onboarding-Experiences.mp4'),
					type: 'mp4',
				},
			],
		},
		{
			key: 'insights',
			title: 'Gain valuable insights into adoption rates',
			description: 'Measure success rates of new software and applications with built-in usage metrics. Understand where users need more guidance and training.',
			mobile: 'Measure success rates of new software and applications with built-in usage metrics. Understand where users need more guidance and training.',
			img: require('../assets/videos/introx/Gain-Valuable-Insights-into-Adoption-Rates.jpg'),
			video: [
				{
					file: require('../assets/videos/introx/Gain-Valuable-Insights-into-Adoption-Rates.mp4'),
					type: 'mp4',
				},
			],
		},
		{
			key: 'accelerate',
			title: 'Accelerate digital buy-in',
			description: 'Onboard and activate users by educating and guiding them to value quickly. Standardise and centralise training processes to train new employees rapidly.',
			mobile: 'Onboard and activate users by educating and guiding them to value quickly. Standardise and centralise training processes to train new employees instantly.',
			img: require('../assets/videos/introx/Accelerate-your-Digital-Buy-In.jpg'),
			video: [{
				file: require('../assets/videos/introx/Accelerate-your-Digital-Buy-In.mp4'),
				type: 'mp4',
			}],
		},
		{
			key: 'time',
			title: 'Save time and resources',
			description: 'Avoid tedious, unsuccessful software training sessions and poor adoption rates with practical and relevant user training. Push new features and applications instantly with all the necessary training. ',
			mobile: 'Avoid tedious, unsuccessful software training sessions and poor adoption rates with practical and relevant user training. Push new features and applications instantly with all the necessary training. ',
			img: require('../assets/videos/introx/Save-Time-and-Resources.jpg'),
			video: [
				{
					file: require('../assets/videos/introx/Save-Time-and-Resources.mp4'),
					type: 'mp4',
				}
			],
		},
	],
},
	{
		product: 'NEXUS',
		description: ['Deployment', 'approval platform.'],
		color: 'orange',
		data: [
			{
				key: 'ensure',
				title: 'Ensure all applications meet your standards',
				description: 'Allow teams to easily upload and submit applications to be checked against your corporate standards before going into production.',
				mobile: 'Allow teams to submit applications to be checked against corporate standards.',
				img: require('../assets/videos/nexus/Ensure-all-Applications-meet-your-Standards.jpg'),
				video: [{
					file: require('../assets/videos/nexus/Ensure-all-Applications-meet-your-Standards.mp4'),
					type: 'mp4',
				}],
			},
			{
				key: 'guarantee',
				title: 'Guarantee a consistent user experience',
				description: 'Run checks against your design system and component library. Ensure all applications maintain your unique brand and style guidelines.',
				mobile: 'Run checks against your design system and component library. Ensure all applications maintain your unique brand and style guidelines.',
				img: require('../assets/videos/nexus/Guarantee-a-consistent-User-Experience.jpg'),
				video: [{
					file: require('../assets/videos/nexus/Guarantee-a-consistent-User-Experience.mp4'),
					type: 'mp4',
				}],
			},
			{
				key: 'provide',
				title: 'Provide actionable feedback',
				description: 'Review, accept and reject applications with actionable feedback. Fix any discrepancies quickly and efficiently.',
				mobile: 'Review, accept and reject applications with actionable feedback. Fix any discrepancies quickly and efficiently.',
				img: require('../assets/videos/nexus/Provide-Actionable-Feedback.jpg'),
				video: [{
					file: require('../assets/videos/nexus/Provide-Actionable-Feedback.mp4'),
					type: 'mp4',
				}],
			},
		],
	},
];

export const productSort = ['ARTHOS', 'MATRIX', 'INTRO-X', 'ANALYTIX', 'AXCEPT', 'NEXUS']
export const arrayToDictonary = (dict: IProduct[]) => (dict || []).reduce((result, n: IProduct) => {
	result[n.product] = n;
	return result;
}, {} as { [key: string]: IProduct });


// @ts-ignore
export const ProductsMap: Record<EPdocutName,IProduct> = arrayToDictonary(Products);