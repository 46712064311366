import * as React from 'react';
import Page from '../../components/page';
import './index.less';
import Button from '../../components/button';
import { withRouter } from 'react-router-dom';
import { H1 } from '../../components/animated-container/h';

const PrivacyPolicyContent = () =>
	<div className={'privacy-policy-content'}>
		<div className={'last-modified'}>Last modified: 20.11.2019</div>
		<ol className={'main-ol'}>
			<li>
				<span className={'bold'}>Introduction</span>
				<p>d:code:it Ltd (“d:code:it”, “we”, “us”, or “our”) are committed to protecting your privacy. This Privacy Policy applies to all information we collect when you access or use our websites (www.dcodeit.com, www.arthos.io, www.axcept.io), applications, services or interact with us. This Privacy Policy provides you with important information regarding the collection, processing and use of your personal information. All personal information is subject to legal safeguards specified in the Data Protection Act 1998 and GDPR.</p>
				<p>We reserve the right, from time to time, to modify or update our data processing, collection and usage practices to reflect changes in regulation or our business processes and therefore advise you to review this Privacy Policy periodically. If we change the policy in a material manner, we will notify you and update the ‘Last Modified' date at the top of this page.</p>
				<p>We take our obligations regarding your privacy seriously and we will not use or share your information with anyone except as described in this Privacy Policy. By visiting our websites, or accessing or using any of our products or services, you are agreeing to the terms of this Privacy Policy. </p>
				<p className={'last'}></p>
			</li>
			<li>
				<span className={'bold'}>Information we obtain from you</span>
				<ol className={'sub-ol'}>
					<li>We collect personal information about you when you voluntarily submit this information to us via the website, contact form or any other means of communication.</li>
					<li>The types of information we may collect include your company name, first and last name, email address and other contact or identifying information you choose to provide.</li>
					<li>We collect and process this information with your consent and/or as necessary to provide the products you use, operate our business, meet our contractual and legal obligations, protect the security of our systems and our customers, or fulfil other legitimate interests.</li>
				</ol>
			</li>
			<li>
				<span className={'bold'}>Information collected automatically</span>
				<ol className={'sub-ol'}>
					<li>When you use our services, some information is collected automatically. For example, when you visit our websites, our web servers automatically log your computer operation system, IP address, access time, browser type, website crashes and language. A full list of the information collected automatically is available upon request.</li>
					<li>We may use cookies or other similar tools including, but not limited to, device identifiers within our services. In addition, when you use our services, our servers will automatically record certain information that your device sends. </li>
					<li>Clear GIFS Information: When you use our Service, we may employ technology such as “clear GIFs” which are used to track the online usage patterns of our users. In addition, we may also use clear GIFs in HTML-based emails sent to our users to track which emails are opened by specific users.</li>
				</ol>
			</li>
			<li>
				<span className={'bold'}>Cookies</span>
				<ol className={'sub-ol'}>
					<li>When you visit our websites, we may collect usage information by using cookies. Cookies are small packets of data that a website stores on your computer’s hard drive so that your computer “remembers” information about your visit. You can reject cookies by following the directions provided by your browser. We use cookies to track your online usage patterns so to improve and analyse our services.
						<br/><br/>
						We may also collect information using web beacons (also known as "tracking pixels"). Web beacons are electronic images that may be used in our services or emails and to track count visits or understand usage and campaign effectiveness.
					</li>
				</ol>
			</li>
			<li>
				<span className={'bold'}>Third-party analytics and advertising</span>
				<ol className={'sub-ol'}>
					<li>We work with trusted third-party partners which use cookies to collect usage information about how visitors use our Websites and Services. The trusted third-party partners include Google Analytics, LinkedIn and Hubspot.</li>
					<li>We may share usage information for adapting, managing and analysing advertising and interaction with what you see on our websites and services. These third parties may use cookies, web beacons or other technologies to collect such usage information for such purposes.</li>
					<li>We collect information from devices you use to access our services in line with Google Analytics reporting and other applicable communication data.</li>
				</ol>
			</li>
			<li>
				<span className={'bold'}>Use of your data</span>
				<ol className={'sub-ol'}>
					<li>
						We use information about you for various purposes as outlined below:
						<ul className={'sub-ul'}>
							<li>To track your use of our services to provide you with a seamless experience and to identify any user experience issue;</li>
							<li>Provide, deliver, maintain and improve our services and related products;</li>
							<li>Operate our business including but not limited to securing our systems and detecting fraudulent and illegal activity;</li>
							<li>Provide services you request, process transactions and to send you related information;</li>
							<li>Send you technical notices, updates and security alerts;</li>
							<li>Respond to your comments, questions, requests and provide you with necessary support;</li>
							<li>Monitor and analayse, usage and activities in connection with our services;</li>
							<li>Personalise and improve your experience and our services.</li>

						</ul>
					</li>
				</ol>
			</li>

			<li>
				<span className={'bold'}>Sharing your data for business purposes</span>
				<ol className={'sub-ol'}>
					<li>We may share your personal data with external organisations that carry out a range of services on our behalf. We ensure that every third party we work with complies with relevant data protection laws and information security standards. Our agreements with these service providers limit the information they can use, process or hold to ensure they keep your personal information secure.</li>
					<li>In the event of a merger, dissolution or similar corporate event, the information we have collected, may be transferred to the applicable merger or acquiring entity. All transfers will be subject to our Privacy Policy and will respect the confidentiality of your information.</li>
				</ol>
			</li>
			<li>
				<span className={'bold'}>Retaining your information</span>
				<ol className={'sub-ol'}>
					<li>We will hold your personal information in a form that identifies you only for the time reasonably necessary to achieve the purposes(s) for which it was initially collected as stated in this Privacy Policy or if we have a valid legal reason to do so.</li>
					<li>After such time periods have expired, we may delete your personal information or retain it in a form that does not identify you personally.</li>
				</ol>
			</li>
			<li>
				<span className={'bold'}>Keeping your data secure</span>
				<ol className={'sub-ol'}>
					<li>We take the security and privacy of your information very seriously. We take all reasonable measures to help protect personal information from loss, theft, misuse and unauthorised access, disclosure, alteration and destruction.</li>
					<li>We adhere to GDPR principles and ensure all third-party providers adhere to similar Data Protection legislation.</li>
				</ol>
			</li>
			<li>
				<span className={'bold'}>Your rights under European Data Protection Regulations (GDPR)</span>
				<ol className={'sub-ol'}>
					<li>The processing of information about you is subject to European Data Protection Regulations (GDPR). According to such regulation, you have certain rights with respect to your data outlined below:
						<ul className={'sub-ul'}>
							<li>You can request access to, and rectification or deletion of, certain information;</li>
							<li>If any automated processing of information is based on your consent or a contract with you, you have a right to transfer or receive a copy of your information in a usable and portable format;</li>
							<li>If the processing of information is based on your consent, you can withdraw and opt out at any time;</li>
							<li>You may opt out of receiving any promotional emails from us by following the instructions in those emails. If you opt out, we may still send you non-promotional communications, such as those about our ongoing business relations.</li>
							<li>You can object to, or obtain a restriction of, the processing of information under certain circumstances.</li>
						</ul>
					</li>
					<li>To make such requests, please contact us at contact@dcodeit.com.</li>
					<li>If you believe your privacy rights have been infringed, or you disagree with a decision, you have the right to complain to the regulator but we would encourage that you first contact us with any queries or concerns.</li>
				</ol>
			</li>
			<li>
				<span className={'bold'}>Governing law</span>
				<ol className={'sub-ol'}>
					<li>This Privacy Policy is governed by the laws of England and Wales.</li>
				</ol>
			</li>
		</ol>
		<p>If you have any questions about this privacy policy, please contact us at: contact@dcodeit.com</p>
	</div>;

class PrivacyPolicy extends React.Component {
	constructor(props) {
		super(props);
	}

	public sectionOnLeave(index, nextIndex, direction) {}

	public render():
		| React.ReactElement<any, string | React.JSXElementConstructor<any>>
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {

		const privacyPolicy = (window as any).__PAGES__.find(
			(el) => el.id === 'privacy-policy',
		);
		return (
			<Page
				isMobile={this.props.isMobile}
				id="privacy-policy"
				sectionOnLeave={(index, nextIndex, direction) =>
					this.sectionOnLeave(index, nextIndex, direction)
				}
				sections={[
					{
						id: 'main',
						title: privacyPolicy.h1,
						color: 'white',
						vlign: 'center',
						subscroll: true,
						content: (
							<>
								<div className="main-section">
									<H1 s={'separator m blue'} tabIndex={-1}>{privacyPolicy.h1}</H1>
									<PrivacyPolicyContent/>
									<Button
										color={'white'}
										className={'small back-to-home'}
										label={'Back to Home Screen'}
										onClick={() =>
											this.props.history.push('/')
										}
									/>
								</div>
							</>
						),
					},
				]}
			/>
		);
	}
}

export default withRouter(PrivacyPolicy);
