import * as React from 'react';
import {isMobile} from "../utils";
//import '../lib/pagepiling/jquery.pagepiling';
export default class Wheel extends React.PureComponent<{isMobile: boolean},{}> {
    private wheelStart: number = 0;
    private eventsCount: number = 0;
    private deltaTime: number = 0;
    private prevEventTime: number = 0;
    private touchStarted: boolean = false;
    private touchStartY: number = 0;
    private touchStartX: number = 0;
    private canScrollUp: boolean = false;
    private canScrollDown: boolean = false;
    constructor(props: any) {
        super(props);
        this.handleWheel = this.handleWheel.bind(this)
        this.touchStart = this.touchStart.bind(this)
        this.touchMove = this.touchMove.bind(this)


    }
    public componentDidMount(): void {
        if(isMobile())return;
        window.addEventListener('wheel', this.handleWheel, {capture: true, passive:false})
        window.addEventListener('touchstart', this.touchStart, {capture: true, passive:false})
        window.addEventListener('touchmove', this.touchMove, {capture: true, passive:false})
    }
    public componentWillUnmount(): void {
        window.removeEventListener('wheel', this.handleWheel, {capture: true, passive:false})

    }

    private touchStart(e) {
        this.touchStartY = e.touches[0].pageY;
        this.touchStartX = e.touches[0].pageX;
        this.touchStarted = true;
    }
    private swipeDirection(x: number, y: number) {
        const delta = this.touchStartY - y;
        const deltaX = this.touchStartX - x;
        const isHorizontal = Math.abs(deltaX) > Math.abs(2 * delta) || Math.abs(Math.floor(delta)) === 0;
        const isVertical = Math.abs(delta) > Math.abs(2 * deltaX) || Math.abs(Math.floor(deltaX)) === 0;
        let dir = undefined;
        if(isHorizontal && deltaX > 0) {
            dir = 'left'
        } else if(isHorizontal && deltaX < 0) {
            dir = 'right'
        }
         else if(!isHorizontal && delta < 0) {
            dir = 'up'
        }
         else if(!isHorizontal && delta < 0) {
            dir = 'down'
        }
        return {
            startX: this.touchStartX,
            startY: this.touchStartY,
            x,
            y,
            dir,
            deltaY: delta,
            deltaX,
            isHorizontal,
            isVertical,
        }
    }
    private touchMove(e) {
        const delta = this.touchStartY - e.touches[0].pageY;
        const deltaX = this.touchStartX - e.touches[0].pageX;

        const activeSection = document.querySelector('section.active')
        let dir = 0;
        const sd = this.swipeDirection(e.touches[0].pageX, e.touches[0].pageY);
        if(this.touchStarted === true) {
            const event = new CustomEvent('swipe', {detail: {dir: sd.dir}})
            window.dispatchEvent(event);
            if(activeSection && !sd.isHorizontal ) {
                dir = delta
                this.handleSectionMove(delta > 0 ? 'down' : 'up', activeSection)


            }
            this.touchStarted = false

        } else {
            if(activeSection && !sd.isHorizontal) {
                this.setCanScroll(delta, activeSection);
            }
        }

    }

    private setCanScroll(deltaY: number, section: Element) {
        //console.error(deltaY, section)
        if(section) {
            const scrollBottom = section.scrollHeight - section.clientHeight - section.scrollTop;

            if(section.scrollTop < 2 && deltaY < 0) {
                this.canScrollUp = true;
            } else {
                this.canScrollUp = false;
            }


            if(scrollBottom < 2 && deltaY > 0) {
                this.canScrollDown = true;
            } else {
                this.canScrollDown = false;
            }

        }
    }
    private handleWheel(e:any){
        if(isMobile())return;
        const activeSection = document.querySelector('section.active')


        if(this.eventsCount === 0 && this.deltaTime !== 0) {
            this.eventsCount ++;
            this.wheelStart = (new Date()).getTime();

            if(activeSection) {
                this.handleSectionMove(e.deltaY > 0 ? 'down' : 'up', activeSection)
            }

        } else {
            this.eventsCount ++;
            if(activeSection) {
                this.setCanScroll(e.deltaY, activeSection);
            }
        }

        this.deltaTime = e.timeStamp - this.prevEventTime;

        //reset counter if time between 2 events bigger than 300
        if(this.deltaTime > 300) {
            this.eventsCount = 0;
        }
        if((new Date()).getTime() - this.wheelStart > 1800) {
            this.eventsCount = 0;
        }
        //console.error();
        this.prevEventTime = e.timeStamp;
        //reset counter if there was no interval of 300 ms between 2 events and the wheel is going on for at least 2 sec


    }
    private handleSectionMove(dir: "up"|"down", section: Element) {
        if(isMobile())return;
        const isServices = section.classList.contains('website-section-services');
        const direction = isServices ? 'services' + dir : dir;
        const moveHandler =  {
            up: $.fn.pagepiling.moveSectionUp,
            down: $.fn.pagepiling.moveSectionDown,
            servicesup: (window as any).servicesMoveToPrevSection,
            servicesdown: (window as any).servicesMoveToNextSection
        }
        let overflowY = undefined;
        if(window.getComputedStyle) {
            const style = window.getComputedStyle(section)
            if(style && style["overflow-y"]) {
                overflowY = style["overflow-y"]
            }
        }
        if(section.scrollHeight === section.clientHeight || (section.scrollTop === 0 && dir === 'up') || overflowY === 'hidden') {

            moveHandler[direction]();
        } else {
            if(this.canScrollUp) {
                moveHandler[(isServices ? 'services': '')+"up"]()
            }
            if(this.canScrollDown) {
                moveHandler[(isServices ? 'services': '')+"down"]()
            }
        }
    }
    public render(){
        return <></>
    }
}