import * as React from 'react';
import './index.less';
import Logo from '../../assets/svg/dcodeit-strocke.svg';
import { Link } from 'react-router-dom';
import {isIE} from "../../utils";
import SVG from "react-inlinesvg";
interface IFooterProps {
	className: string;
}
class Footer extends React.PureComponent<IFooterProps, {}> {
	constructor(props) {
		super(props);
	}

	public render():
		| React.ReactElement<any, string | React.JSXElementConstructor<any>>
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {
		const year = new Date().getFullYear();
		return (
			<footer id="main-footer" className={'footer' + this.props.className}>
				<div className="logo">
					{isIE() ? <img style={{width: '91px', height: '23.3px'}} width={91} height={23.3} src={Logo} className={'footer-logo'}/> :<SVG alt={'d:code:it'} width={91} src={Logo}/> }
					<Link className="privacy-policy mobile" to={'/privacy-policy'}>Privacy Policy</Link>
				</div>
				<div className="footer-links">
					<Link className="privacy-policy desktop" to={'/privacy-policy'}>Privacy Policy</Link>
					<div className="copyright desktop">© {year} d:code:it</div>
				</div>

				<div className="copyright mobile">© {year} d:code:it</div>
			</footer>
		);
	}
}

export default Footer;
