import * as React from 'react';
import '../../lib/styles/plyr.css';
import { withRouter } from 'react-router-dom';
import Classet from '../../utils/classSet';

import ParticleImage from '../../components/particle-image';
import './index.less';
import Footer from '../../components/footer';
import { isIE, isIPad, isPortrait, isSafari, isMobile } from '../../utils';
import SVG from 'react-inlinesvg';
import { H1, H2 } from '../../components/animated-container/h';
import Tracker from '../../components/tracker';
import { debounce } from 'lodash'
import SectionIndicator from "../../components/page/section-indicator";
const Fragment = React.Fragment;

interface IServicesState {
	currentSection: number;
	opacity: number;
	headerBehavior: 'fixed' | 'scroll' | 'scroll-new';
	headingSize: 'big' | 'small';
}

class Services extends React.PureComponent<any, IServicesState> {
	public selectedPage = (window as any).__PAGES__.find((el: any) => el.id === 'services');
	public pageRef: React.RefObject<HTMLDivElement>;
	public scrollIndex = 0;
	public scrolling = false;
	private pageStartAnchor: Element | null;

	constructor(props: any) {
		super(props);

		this.handleScroll	= this.handleScroll.bind(this);
		this.onKey 			= this.onKey.bind(this);
		this.onResize 		= this.onResize.bind(this);
		(window as any).servicesMoveToNextSection = this.moveToNextSection.bind(this);
		(window as any).servicesMoveToPrevSection = this.moveToPreviousSection.bind(this);
		this.pageRef = React.createRef();
		this.pageStartAnchor = null;
		this.state = {
			currentSection: 0,
			opacity: 0,
			headerBehavior: 'scroll',
			headingSize: 'big',
		};
	}
	public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<IServicesState>, snapshot?: any): void {
		if (this.props.location.pathname === prevProps.location.pathname && this.props.location.key !== prevProps.location.key ) {
			this.scrollToSection(0)
		}
	}

	public componentDidMount() {
		if(isMobile()){
			setTimeout(() => this.pageStartAnchor?.scrollIntoView(), 100)
		}
		if (!isMobile()) {
			document.onkeydown = this.onKey;


		}

		setTimeout(() => this.setState({ opacity: 1 }), 100);

		setTimeout(() => document.querySelector('#section-0')?.classList.add('active'), 100);

		window.addEventListener('resize', this.onResize);
	}

	public componentWillUnmount() {
		const handleScrolling = debounce(this.handleScroll, 50, {
			'leading': true,
			'trailing': false
		});
		window.addEventListener('mousewheelstart', this.handleScroll);
		window.removeEventListener('resize', this.onResize);
	}

	public handleScroll({detail: {dir}}) {

		if (dir === 'down') {
			this.moveToNextSection();
		} else if (dir === 'up') {
			this.moveToPreviousSection();
		}
		return false;
	}

	public onKey(event: KeyboardEvent) {
		switch (event.code) {
			case 'ArrowDown':
			case 'PageDown':
				this.moveToNextSection();
				break;
			case 'ArrowUp':
			case 'PageUp':
				this.moveToPreviousSection();
				break;
		}
	}

	public onResize() {
		if(isMobile()) return;
		document.querySelector(`#section-${this.state.currentSection}`)?.scrollIntoView();
	}

	public moveToNextSection() {
		const nextSection = this.state.currentSection + 1;

		if (nextSection <= this.selectedPage.additionalSections.length + 2) {

			if (nextSection === this.selectedPage.additionalSections.length  + 2 || nextSection < 2) {
				this.setState({
					headerBehavior: 'scroll',
					headingSize: 'small',
				});
			} else {
				if (this.selectedPage.additionalSections[this.pageSectionIndex(this.state.currentSection)]?.h1 === this.selectedPage.additionalSections[this.pageSectionIndex(nextSection)]?.h1) {
					this.setState({
						headerBehavior: 'fixed',
						headingSize: 'small',
					});
				} else {
					this.setState({
						headerBehavior: 'scroll',
						headingSize: 'big',
					});
				}
			}

			this.scrollToSection(nextSection);
		}
	}

	public moveToPreviousSection() {
		const nextSection = this.state.currentSection - 1;

		if (nextSection >= 0) {
			if (nextSection < 2) {
				this.setState({
					headerBehavior: 'scroll',
					headingSize: 'small',
				});
			} else {
				const currentSectionIndex = this.pageSectionIndex(this.state.currentSection);
				const nextSectionIndex = this.pageSectionIndex(nextSection);
				const currentSectionContent = this.selectedPage.additionalSections[currentSectionIndex];
				const nextSectionContent =  this.selectedPage.additionalSections[nextSectionIndex];



				if (currentSectionContent?.h1 === nextSectionContent?.h1) {
					this.setState({
						headerBehavior: 'fixed',
					});

					if (currentSectionContent?.h1 !== this.selectedPage.additionalSections[nextSectionIndex - 1]?.h1 || nextSection <= 0) {
						this.setState({
							headingSize: 'big',
						});
					} else {
						this.setState({
							headingSize: 'small',
						});
					}
				} else {
					this.setState({
						headerBehavior: 'scroll',
						headingSize: 'small',
					});
				}
			}

			if (!this.scrolling) {
				this.scrollToSection(nextSection);
			}
		}
	}
	public scrollToSection(nextSection: number) {
		if(this.scrolling) return;

		this.scrolling = true;
		this.scrollIndex = 0;
		let addScroll = 0;


		const nextBB = document.getElementById(`section-${nextSection}`)?.getBoundingClientRect();
		if(!nextBB) return;
		const headerHeight = document.getElementById('main-header')?.offsetHeight;




		$(this.pageRef.current).animate({scrollTop: document.getElementById(`section-${nextSection}`)?.offsetTop}, 800, () => {
			document.getElementById(`section-${nextSection}`)?.querySelector('video')?.play();
			this.scrolling = false;
		});


		setTimeout(()=>this.setState({
			currentSection: nextSection,
		}), 500)


	}
	private calculateScroll() {

	}
	public pageSectionIndex(i: number) {
		return i - 2;
	}

	public sectionHeadingSize(i: number) {
		// first is always big
		if (i === 0) {
			return 'big';
		}

		// last is always small
		if (i >= this.selectedPage.additionalSections.length - 1) {
			return 'small';
		}

		if (this.selectedPage.additionalSections[i - 1]?.h1 !== this.selectedPage.additionalSections[i]?.h1) {
			return 'big';
		} else {
			return 'small';
		}
	}
	private getActiveClass(id:number):string | null {
		if(this.state.currentSection === id){
			return 'active'
		}
		return null;
	}
	public render() {
		const page = Classet({
			'page-content': true,
			[`selected-${this.state.currentSection}`]: false,
		});

		const global = Classet({
			'global': true,
			[this.state.headerBehavior]: true,
			[this.state.headingSize]: true,
			'flow': true,
			'l': true
		});

		const timeline = Classet({
			'timeline': true,
			[this.state.headerBehavior]: true,
			[this.state.headingSize]: true,
			[this.selectedPage.additionalSections[this.state.currentSection - 2]?.color]: true,
		});

		const sectionTimeline = Classet({
			'timeline': true,
			[this.state.headerBehavior]: true,
			[this.state.headingSize]: true,
		});

		const image = isIPad() || isSafari() || isMobile() ? <SVG style={{marginRight: '40px'}} width={'100%'} height={'100%'} className="hover" src={this.selectedPage.h1Image}/> : isIE() ? <img src={this.selectedPage.h1Image} className={'ng42-svg-icon '}/> : <ParticleImage className={'hero-image'} imageUrl={this.selectedPage.h1Image}/>;
		const sectionIndicator = <SectionIndicator
			goTo={() => true}
			sectionIndex={this.state.currentSection + 1}
			{...this.state}
			sectionsCount={11}
			orientation={'vertical'}
			customOnClick={(sectionNo: number)=>{
				this.scrollToSection(sectionNo-1)
			}}
			size={16}/>;
		const sectionClass = isMobile() ? 'mobile-website-section' : 'website-section-services website-section'

		const classLeft = isPortrait() ? 'section-right perc50' : 'section-left perc50';
		const classRight = isPortrait() ? 'section-left perc50' : 'section-right perc50';
		const flowSections = this.selectedPage.additionalSections.map((section: any, i: number) => {
			const classes = Classet({
				'flow-section': true,
				[section.contentPosition]: true,
				[this.state.headerBehavior]: true,
				[`line-color-${section.color}`]: true,
				['hide-point']: i + 2 < this.state.currentSection,
				'in-timeline': true,
				[this.sectionHeadingSize(i)]: true,
				['desktop']: true,
				'website-section-services': true,
				'active': this.getActiveClass(i+2) ? true : false
			});

			const heading = Classet({
				'l': true,
				'flow': true,
				[this.state.headerBehavior]: true,
				[this.sectionHeadingSize(i)]: true,
			});
			const presentation = isIPad() || isIE() || isMobile() ? null : <video poster={section.video[2]} autoPlay={true} loop={false} className={'bg-video'} muted={true} playsInline={true}>
				<source src={section.video[0]} type="video/mp4"/>
				<source src={section.video[1]} type="video/webm"/>
			</video>
			const presentationStyle = isIPad() || isIE() || isMobile() ? {
				background: `url("${section.video[2]}") no-repeat center`,
				backgroundSize: 'cover',
				opacity: 0.5
			} : null;
			return (
				<section key={i} id={'section-' + String(i + 2)} className={classes}>
					<div className={'services-video-container'} style={presentationStyle}>
						{presentation}
					</div>
					<div className={sectionTimeline + ' top'}></div>
					<h1 className={heading}><span className={"heading-content"}>{section.h1}</span></h1>
					<div className="flow-section-content">
						<h2 className={'l'}>{section.h2}</h2>
						<h3 className={'sl'}>{section.h3}</h3>
						{section.text.map((t: string, ti: number) => <p key={ti}>{t}</p>)}
					</div>
				</section>
			);
		})
		const mobileSections = isMobile() ? this.selectedPage.additionalSectionsMobile.map((section: any, i: number) => {
			return <section id={`section-${(i+2)}`} className={`${sectionClass} simple split white`}>
				<div><H1 s={'separator l '+(i == 1 ? 'green' : 'blue')} tabIndex={-1}><Fragment>{section.h1}</Fragment></H1>
					<H2 s={'l'} className={' text-transform-unset'}>{section.h2}</H2></div>
			</section>
		}) : null;
		return (
			<main tabIndex={-1} style={{ opacity: this.state.opacity, overflow: 'hidden' }} id="main-content" className={'page'}>
				<div ref={ref => this.pageStartAnchor = ref} id={'page-start-anchor'}></div>
				{sectionIndicator}
				<div id="navigation-portal-container"></div>
				<div id="services-page" className={page} ref={this.pageRef} style={{overflow: 'hidden'}}>

					<section id={`section-0`} className={`${sectionClass} simple split white ${this.getActiveClass(0)}`}>
						<div className={"solutions-intro-text " + classLeft}>
							<H1 s={'separator l blue'} tabIndex={-1}><Fragment>{this.selectedPage.h1}</Fragment></H1>
						</div>
						<div className={'image '+classRight} id={'solutions-intro-image'}>
							{ isMobile() ? null : image }
						</div>
					</section>
					<section id={`section-1`} className={`${sectionClass} simple split white ${this.getActiveClass(1)}`}>
						<div>
							<H2 s={'separator l blue'}>{this.selectedPage.s1h1}</H2>
						</div>
					</section>
					{ mobileSections }
					{flowSections}
					<section id={`section-${this.selectedPage.additionalSections.length + 2}`} className={'website-section-services simple '+(sectionClass+' ')+this.getActiveClass(this.selectedPage.additionalSections.length + 2)}>
						<h2 className={'wwd-work-with-us'}>Fixed time, fixed budget.</h2>
						<a className={'wwd-work-with-us'} href={'/contact'}>Work with us</a>
						{ !isMobile() &&
						<Footer className={' visible'} />
						}
					</section>
					{ isMobile() &&
						<Footer className={' visible'} />
					}
					
					<Tracker/>

					{this.selectedPage.additionalSections[this.pageSectionIndex(this.state.currentSection)] &&
						<>
							<div className={timeline + ' top global'}></div>
							<h1 className={global}><span className={"heading-content"}>{this.selectedPage.additionalSections[this.pageSectionIndex(this.state.currentSection)].h1}</span></h1>
							<div className={timeline + ' global'}></div>
						</>
					}
				</div>
			</main >
		);
	}
}

export default withRouter(Services);
