import * as React from 'react';
import { products } from "../../data/pages";
import Page from '../../components/page';
import '../solutions/index.less';
import './products.less';
import { Products } from '../products/homepage/products';
import { H1, H2, H3, H4, H5, H6 } from '../../components/animated-container/h';
import {isIE, isIPad, isMobile, isPortrait, isSafari} from "../../utils";
import SVG from "react-inlinesvg";
import ParticleImage from "../../components/particle-image";
import {SectionProps} from "../../lib/types/section";
import Explore from "../../components/explore-products";


class ProductsLandingPage extends React.PureComponent {

    constructor(props: any) {
        super(props);
    }
    componentDidMount(): void {
        (window as any).closeAllNavigationLayers();
    }

    public render() {
        const classLeft = isPortrait() ? 'section-right perc50' : 'section-left perc50';
        const classRight = isPortrait() ? 'section-left perc50' : 'section-right perc50';
        console.log(products.h1Image, 'IS IE');
        const image = isMobile() ? null : isIPad() || isSafari() ? <SVG style={{marginRight: '40px'}} width={'100%'} height={'100%'} className="hover" src={products.h1Image}/> : isIE() ? <img	src={products.h1Image} className={'ng42-svg-icon '}/> : <ParticleImage className={'hero-image'} imageUrl={products.h1Image}/>;

        const sectionMain: SectionProps = {
            id: 'main',
            title: 'We change the way enterprises work',
            color: 'white',
            content: <>

                <div className={classRight} id={'solutions-intro-image'}>
                    {image}
                </div>
                <div className={classLeft} id={'solutions-intro-text'}>
                    <H1 s={'separator l blue'} tabIndex={-1}>{products.h1}</H1>
                </div>

            </>,
        };
        const sectionSubtitle: SectionProps = {
            id: 'subtitle',
            title: '',
            color: 'white',
            content: <>
                <H2 s={'separator l blue'} className={'text-transform-unset'}>{products.s1h1}</H2>
            </>,
        };
        return <Page
            classes={'products-landing-page'}
            isMobile={this.props.isMobile}
            id={'solutions-page'}
            sections={[
                sectionMain,
                sectionSubtitle,
                {
                    id: 'products-all',
                    title: 'Products',
                    color: 'white',
                    valign: 'center',
                    subscroll: true,
                    content: <div id={'arthos-explore'}>
                        <Explore isMobile={isMobile()} product={'introx'} title={false}  allProducts={true} noWidth/>
                    </div>
                }
            ]}
        >

        </Page>;
    }
}

export default ProductsLandingPage;
