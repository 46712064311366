export function getBreakPoints() {
	let className = ' ';
	if (window.innerWidth < 768) {
		className = ' sm-screen ';
	} else if (window.innerWidth >= 768 && window.innerWidth <= 992) {
		className = ' md-screen ';
	} else if (window.innerWidth > 992 && window.innerWidth <= 1200) {
		className = ' lg-screen ';
	} else if (window.innerWidth > 1200 && window.innerWidth < 1920) {
		className = ' xl-screen ';
	} else {
		className = ' xxl-screen ';
	}
	const isPortrait = window.matchMedia('(orientation: portrait)').matches;

	if (isPortrait) {
		className += ' portrait ';
	} else {
		className += ' landscape ';
	}
	return className;
}
