import * as React from 'react';
import { Link } from 'react-router-dom';
import { TweenLite, Linear } from 'gsap';
// @ts-ignore
import './index.less';

interface SolutionCardProps {
	id: string;
	description: string;
	icon: string;
	text: string;
	count: number;
	nav?: boolean;
}

export default class SolutionCard extends React.PureComponent<SolutionCardProps, {}> {
	public container = null;
	public card = null;
	public textDiv = null;
	public textContent = null;
	constructor(props: SolutionCardProps) {
		super(props);

	}
	public mouseLeave() {
		TweenLite.to((this.props.nav ? '.sub-nav ' : '')+'#solution-card-text-' + this.props.id, 0.7, {
			height: 0,
		});
	}
	public mouseEnter() {
		TweenLite.to((this.props.nav ? '.sub-nav ' : '')+'#solution-card-text-' + this.props.id, 0.7, {
			height: this.textContent.clientHeight,
		});

	}
	public render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

		const { id, description, text } = this.props;

		return <Link to={'/solutions/' + id}>
			<div onMouseLeave={() => this.mouseLeave()} onMouseEnter={() => this.mouseEnter()} id={'solution-card-' + id} ref={(ref) => this.card = ref} className={"solution-card solution-card-"+this.props.count}>
				<div ref={(ref) => this.container = ref} className={'card-content'}>
					<div className={'solutions-card-logo ' + id}/>
					<h3 className={'description blue'}>{description}</h3>
					<p id={'solution-card-text-' + id} style={{height: '0px'}} ref={(ref) => this.textDiv = ref} className={'solutions-card-text'}><span style={{display: 'block'}} ref={(ref) => this.textContent = ref} className={'solutions-card-text-content'}>{text}</span></p>
				</div>
			</div>
		</Link>;
	}
}
