import IPageData from "../lib/types/pagedata";
import * as React from "react";
const metaTitle = "D:Code:It";
const metaDescription = "DCodeIt - Some description goes here";

export const home: IPageData = {
    id: "home",
    name: "home",
    comp: require("../pages/home").default,
    path: "/",
    h1: "CHANGING THE WAY ENTERPRISES WORK.",
    s1h1: "We're a digital product studio fundamentally transforming the way enterprises work, think and perform.",
    hideInNav: true,
    metaTitle: "d:code:it | Changing the way enterprises work",
    metaDescription:
        "Digital product studio helping global banks and enterprises transform the way they work, think and perform.",
};

export const arthos: IPageData = {
    id: "arthos",
    name: "Arthos",
    path: "/arthos",
    comp: require("../pages/products/arthos").default,
    icon: require("../assets/svg/arthos-logo.svg"),
    navigationBgImage: require("../assets/svg/arthos-logo.svg"),
    h1: "We change the way enterprises work",
    metaTitle: "Arthos | The workspace built for the enterprise",
    metaDescription:
        "Axcept is an automated visual testing tool that provides a simple, intuitive way to build and execute thousands of tests in a matter of minutes.",
    metaImage: require("../assets/images/open-graph/dcodeit-share-006-Arthos@2x.jpg"),
};
export const axcept: IPageData = {
    id: "axcept",
    path: "/axcept",
    name: "Axcept",
    comp: require("../pages/products/axcept").default,
    icon: require("../assets/svg/axcept-logo.svg"),
    navigationBgImage: require("../assets/svg/axcept-logo.svg"),
    h1: "We change the way enterprises work",
    metaTitle: "Axcept | Automated UI testing at scale",
    metaDescription:
        "Axcept is the fastest automated UI testing tool that detects changes by visually comparing snapshots. Execute thousands of tests in a matter of minutes.",
    metaImage: require("../assets/images/open-graph/dcodeit-share-007-Axcept@2x.jpg"),
};
export const matrix: IPageData = {
    id: "matrix",
    path: "/matrix",
    name: "Matrix",
    comp: require("../pages/products/matrix").default,
    icon: require("../assets/svg/matrix-logo.svg"),
    navigationBgImage: require("../assets/svg/matrix-logo.svg"),
    h1: "We change the way enterprises work",
    metaTitle: "Matrix | Data interaction of the future",
    metaDescription:
        "Matrix is a feature-rich and highly customisable Javascript data grid equipped with an extensive range of premium features designed for the enterprise.",
    metaImage: require("../assets/images/open-graph/dcodeit-share-008-Matrix@2x.jpg"),
};
export const introx: IPageData = {
    id: "intro-x",
    name: "introx",
    path: "/intro-x",
    comp: require("../pages/products/introx").default,
    icon: require("../assets/svg/intro-x-logo.svg"),
    navigationBgImage: require("../assets/svg/intro-x-logo.svg"),
    h1: "We change the way enterprises work",
    metaTitle: "Intro-X | Digital adoption platform",
    metaDescription:
        "Intro-X is the digital adoption platform streamlining user learning and driving transformation initiatives via interactive, step-by-step software training.",
};

export const analytix: IPageData = {
    id: "analytix",
    name: "analytix",
    path: "/analytix",
    comp: require("../pages/products/analytix").default,
    icon: require("../assets/svg/intro-x-logo.svg"),
    navigationBgImage: require("../assets/svg/intro-x-logo.svg"),
    h1: "We change the way enterprises work",
    metaTitle: "Analytix | Real-time analytics and insights",
    metaDescription:
        "Analytix provides enterprises with the insights they need to employ a scientific, data-driven approach to innovation.",
    appsSection: {
        title: "Supporting all major analytics tools",
        apps: [
            { id: "splunk", title: "Splunk" },
            { id: "search", title: "Elastic Search" },
            { id: "tableau", title: "Tableau" },
            { id: "kibana", title: "Kibana" },
        ],
    },
};

export const nexus: IPageData = {
    id: "nexus",
    name: "nexus",
    path: "/nexus",
    comp: require("../pages/products/nexus").default,
    icon: require("../assets/svg/intro-x-logo.svg"),
    navigationBgImage: require("../assets/svg/intro-x-logo.svg"),
    h1: "Deployment approval platform.",
    metaTitle: "Nexus | Deployment approval platform",
    metaDescription:
        "Nexus is a deployment approval platform connecting teams to deliver unified results at a global scale.",
};

export const products: IPageData = {
    id: "products",
    name: "Products",
    comp: require("../pages/products").default,
    h1: "PRODUCTS THAT MAKE A DIFFERENCE.",
    s1h1: "When we realised the products we needed didn’t exist, we decided to build them ourselves.",
    //comp: require('../pages/products').default,
    metaTitle: "Products | d:code:it",
    h1Image: require("../assets/svg/product-icon.svg"),
    metaDescription,
    //items: [arthos,  matrix, axcept, analytix,introx, nexus],
};

export const services: IPageData = {
    id: "services",
    name: "Services",
    comp: require("../pages/services").default,
    compMobile: require("../pages/services/mobile").default,
    //metaTitle: 'Services',
    h1: "A fusion of art and technology.",
    h1Image: require("../assets/svg/solutions/services.svg"),
    s1h1: "Combining latest technologies with intuitive design, we deliver applications that generate instant utility in record time.",
    metaTitle: "Services | d:code:it",
    metaDescription:
        "Combining latest technologies with intuitive design, we deliver applications that generate instant utility in record time.",
    additionalSectionsMobile: [
        {
            h1: "DESIGN",
            h2: "Our product and design team work with you to understand and break down your unique business challenge. Within a 6-8 week period we deliver a pixel perfect design and prototype that acts as the blueprint for development.",
        },
        {
            h1: "Develop",
            h2: "Together with your technology teams, we define service requirements and agree the service contracts. We embark on a rapid development phase with automated testing and frequent release cycles.",
        },
        {
            h1: "Deliver",
            h2: "We don’t deliver MVPs. Within 6 weeks, we deliver the final code and a product that provides instant utility to your users.",
        },
    ],
    additionalSections: [
        {
            color: "blue",
            h1: "DESIGN",
            contentPosition: "right",
            h2: "week 1",
            h3: "Identifying the challenge",
            video: [
                require(`../assets/videos/services/Design - Week 1 - Problem Statement.mp4`),
                require(`../assets/videos/services/Design - Week 1 - Problem Statement.webm`),
                require(`../assets/videos/services/Design - Week 1 - Problem Statement.jpg`),
            ],
            text: [
                "Every project starts with breaking down your unique business challenge, defining what the problem is, why it matters, and how it can be resolved.",
            ],
            sectionNumber: 1,
        },
        {
            color: "blue",
            h1: "DESIGN",
            h1Size: "s",
            contentPosition: "left",
            h2: (
                <>
                    Week 2<span className={"arrow"}></span>3
                </>
            ),
            h3: "Exploring  solutions",
            video: [
                require(`../assets/videos/services/Design - Week 2-3 - Exploration.mp4`),
                require(`../assets/videos/services/Design - Week 2-3 - Exploration.webm`),
                require(`../assets/videos/services/Design - Week 2-3 - Exploration.jpg`),
            ],
            text: [
                "Working with you, our product and design team explore innovative and valuable concepts. We produce wireframes and prototypes with the tech team’s expertise on possibilities.",
            ],
            sectionNumber: 1,
        },
        {
            color: "blue",
            h1: "DESIGN",
            h1Size: "s",
            contentPosition: "right",
            h2: (
                <>
                    Week 3<span className={"arrow"}></span>6
                </>
            ),
            h3: "Iteration, iteration, iteration",
            video: [
                require(`../assets/videos/services/Design Week 3-6 - Iterative.mp4`),
                require(`../assets/videos/services/Design Week 3-6 - Iterative.webm`),
                require(`../assets/videos/services/Design Week 3-6 - Iterative.jpg`),
            ],
            text: [
                "We embark on a rigorous fine design process where every pixel matters. With your continued input, we finalise a design that outlines the ultimate solution to your business problem.\n",
            ],
            sectionNumber: 2,
        },
        {
            color: "blue",
            h1: "DESIGN",
            h1Size: "s",
            contentPosition: "left",
            h2: (
                <>
                    Week 6<span className={"arrow"}></span>8
                </>
            ),
            h3: "Bringing the design to life",
            video: [
                require(`../assets/videos/services/Design Week 6-8 - Interaction + Blueprint.mp4`),
                require(`../assets/videos/services/Design Week 6-8 - Interaction + Blueprint.webm`),
                require(`../assets/videos/services/Design Week 6-8 - Interaction + Blueprint.jpg`),
            ],
            text: [
                "Our motion team brings the designs to life and we deliver the final blueprint to kick-start development.",
            ],
            sectionNumber: 2,
        },
        {
            color: "green",
            contentPosition: "right",
            h1: "DEVELOPMENT",
            h2: "Week 1",
            h3: "Defining requirements",
            video: [
                require(`../assets/videos/services/Development Week 1 - Defining Requirements.mp4`),
                require(`../assets/videos/services/Development Week 1 - Defining Requirements.webm`),
                require(`../assets/videos/services/Development Week 1 - Defining Requirements.jpg`),
            ],
            text: [
                "Together with your technology teams, we define data and service requirements and agree the service contracts.",
            ],
            sectionNumber: 1,
        },
        {
            color: "green",
            contentPosition: "left",
            h1: "DEVELOPMENT",
            h1Size: "s",
            h2: (
                <>
                    Week 2<span className={"arrow"}></span>5
                </>
            ),
            h3: "Fast, agile development",
            video: [
                require(`../assets/videos/services/Development Week 2-5 - Fast Agile.mp4`),
                require(`../assets/videos/services/Development Week 2-5 - Fast Agile.webm`),
                require(`../assets/videos/services/Development Week 2-5 - Fast Agile.jpg`),
            ],
            text: [
                "We embark on a rapid development phase with automated testing and frequent release cycles.",
            ],
            sectionNumber: 1,
        },
        {
            color: "green",
            contentPosition: "right",
            h1: "DEVELOPMENT",
            h1Size: "s",
            h2: (
                <>
                    Week 5<span className={"arrow"}></span>6
                </>
            ),
            h3: "Initial Integration",
            video: [
                require(`../assets/videos/services/Development Week 5-6 - Initial Integration.mp4`),
                require(`../assets/videos/services/Development Week 5-6 - Initial Integration.webm`),
                require(`../assets/videos/services/Development Week 5-6 - Initial Integration.jpg`),
            ],
            text: [
                "We provide an initial integration preview in parallel to the final development and testing period.",
            ],
            sectionNumber: 2,
        },
        {
            color: "green",
            contentPosition: "left",
            h1: "DEVELOPMENT",
            h1Size: "s",
            h2: (
                <>
                    Week 6<span className={"arrow"}></span>7
                </>
            ),
            h3: "Delivering instant utility",
            video: [
                require(`../assets/videos/services/Development Week 6-7 - Deliver Utility.mp4`),
                require(`../assets/videos/services/Development Week 6-7 - Deliver Utility.webm`),
                require(`../assets/videos/services/Development Week 6-7 - Deliver Utility.jpg`),
            ],
            text: [
                "We don’t deliver MVPs. We deliver final code and a product that provides instant utility to your users. ",
            ],
            sectionNumber: 2,
        },
    ],
};

/*********** solution pages ***************************/
export const microservice: IPageData = {
    id: "microservice-micro-app-architecture",
    name: "Microservice, micro-app architecture",
    comp: require("../pages/solutions").default,
    compMobile: require("../pages/solutions/mobile").default,
    icon: require("../assets/svg/solutions/microservice.svg"),
    iconHover: require("../assets/svg/solutions/microservice-white.svg"),
    h1: "Transform your technology stack.",
    h1Image: require("../assets/svg/solutions/microservice-big.svg"),
    s1h1: "90% of all new applications feature a microservice and micro frontend architecture. Enterprises need to revolutionise monolithic structures to stay ahead. We provide the development and deployment framework to make this possible.",
    metaTitle: "Microservice, Micro-App Architecture | d:code:it",
    metaDescription:
        "Divide large enterprise systems into smaller, manageable and independent micro-applications. Transform your technology stack to stay ahead.",
    scrollingSection: {
        img: require("../assets/images/solutions/microservice-micro-app-architecture.png"),
        video: [
            {
                file: require("../assets/solutions/01-microapparchitecture/05-MicroService-MicroApp-Architecture.mp4"),
                type: "mp4",
            },
            // {
            //     file: require('../assets/solutions/01-microapparchitecture/1286x720/video.webm'),
            //     type: 'webm'
            // }
        ],
        //img: require('../assets/solutions/01-Solutions-Microservice-MicroApp-Architerture.png'),
        texts: [
            {
                title: "Break Down Monolithic Structures",
                paragraphs: [
                    "Divide large enterprise systems into a number of smaller, manageable and independent micro-applications.",
                ],
            },
            {
                title: "Build and Maintain Applications Easier",
                paragraphs: [
                    "Enable developers to build and maintain simply and incrementally by applying modularity to complex structures.",
                ],
            },
            {
                title: "Improve Productivity and Speed",
                paragraphs: [
                    "Develop and test new features quickly and efficiently within a modular, flexible architecture.",
                ],
            },
            {
                title: "Empower distributed teams",
                paragraphs: [
                    "Give developers the independence to work in small groups, making technical decision quickly and autonomously.",
                ],
            },
        ],
    },
};

export const interoperability: IPageData = {
    id: "interoperability-and-legacy-integration",
    name: "Interoperability and legacy integration",
    comp: require("../pages/solutions").default,
    compMobile: require("../pages/solutions/mobile").default,
    icon: require("../assets/svg/solutions/interoperability.svg"),
    iconHover: require("../assets/svg/solutions/interoperability-white.svg"),
    h1: "Connect your legacy to the future.",
    h1Image: require("../assets/svg/solutions/interoperability-big.svg"),
    metaTitle: "Interoperability and Legacy Integration | d:code:it",
    s1h1: "Applications that cannot work together create fragmented workspaces that demand complex manual processes. The interoperability solution enables seamless communication between new, legacy and third party applications.",
    scrollingSection: {
        img: require("../assets/images/solutions/interoperability-and-legacy-integration.png"),
        video: [
            {
                file: require("../assets/solutions/02-interoperabilityandlegacyintegration/02-Interoperability+LegacyIntegration.mp4"),
                type: "mp4",
            },
            // {
            //     file: require('../assets/solutions/02-interoperabilityandlegacyintegration/1286x720/video.webm'),
            //     type: 'webm'
            // }
        ],
        //img: require('../assets/solutions/02-Interoperability&legacyintegration.png'),
        texts: [
            {
                title: "Make your applications work together",
                paragraphs: [
                    "Connect apps logically and optically. Enable context sharing across new, legacy and third party applications, regardless of the languages and technologies they are build with. Provide users with a unified, cross-functional experience.",
                ],
            },
            // {
            // 	title: 'Unify context setting',
            // 	paragraphs: ['Enable all of your applications to share context with minimum development effort. Arthos provides a built-in context sharing function, connecting your legacy, third party and new applications.'],
            // },
            {
                title: "Support legacy languages",
                paragraphs: [
                    "Enable external native apps to communicate with apps running in Arthos. Support legacy languages and integrate legacy web applications running on Internet Explorer.",
                ],
            },
            {
                title: "Streamline workflows",
                paragraphs: [
                    "Speed up manual processes and streamline workflows with logical and optical bindings. Transform fragmented applications into role-based, process aware and results-driven experiences.",
                ],
            },
        ],
    },
    appsSection: {
        title: "Two way integration with essential applications",
        apps: [
            { id: "word", title: "Microsoft Word" },
            { id: "excel", title: "Excel" },
            { id: "salesforce", title: "Salesforce" },
            { id: "reuters", title: "Reuters" },
        ],
    },
    metaDescription:
        "Connect your legacy to the future. Enable seamless communication between new, legacy and third party applications.",
};
export const digitalTransformation: IPageData = {
    id: "digital-transformation",
    name: "Digital Transformation",
    comp: require("../pages/solutions").default,
    compMobile: require("../pages/solutions/mobile").default,
    icon: require("../assets/svg/solutions/digital-transformation.svg"),
    iconHover: require("../assets/svg/solutions/digital-transformation-white.svg"),
    h1: "Drive continuous innovation.",
    h1Image: require("../assets/svg/solutions/digital-transformation-big.svg"),
    s1h1: "Our approach to digital transformation is pragmatic. Successful businesses don’t ever stop evolving, and transformation is continuous - it is not a project with a distinct start and end. Our suite of enterprise products empower businesses to rapidly adapt, constantly innovate and sustain market leadership.",
    metaTitle: "Digital Transformation | d:code:it",
    scrollingSection: {
        img: require("../assets/images/solutions/digital-transformation.png"),
        video: [
            {
                file: require("../assets/solutions/08-digitaltransformation/01-Digital-Transformation.mp4"),
                type: "mp4",
            },
            // {
            //     file: require('../assets/solutions/02-interoperabilityandlegacyintegration/1286x720/video.webm'),
            //     type: 'webm'
            // }
        ],
        //img: require('../assets/solutions/08-digital-transformation.png'),
        texts: [
            {
                title: "Transform legacy infrastructures",
                paragraphs: [
                    "We provide the tools to transform monolithic systems into highly performant microservice applications with instant utility.",
                ],
            },
            {
                title: "Update incrementally and continuously",
                paragraphs: [
                    "Digital transformation doesn’t have to be a disruptive process. Our transformation tool-kit enables you to gradually transform your systems while still working with existing software.",
                ],
            },
            {
                title: "Introduce new software seamlessly",
                paragraphs: [
                    "Adoption rates are vital for successful transformation. Intro-X helps organisations execute sustained digital transformation strategies by seamlessly guiding employees through new applications and processes.",
                ],
            },
            {
                title: "Employ a new way of working",
                paragraphs: [
                    "A rapid, iterative and flexible approach to innovation. Our methodology enables businesses to break out of outdated processes and establish digital roadmaps with clarity and consistency.",
                ],
            },
            {
                title: "Keep pace with evolving technology",
                paragraphs: [
                    "Rapidly adapt, upgrade and optimise technologies to stay ahead of your competition.",
                ],
            },
        ],
    },
    metaDescription:
        "Transform your application architecture to rapidly adapt, constantly innovate and sustain market leadership.",
};
// export const analytics: IPageData = {
// 	id: 'user-and-performance-analytics',
// 	name: 'User and performance analytics',
// 	comp: require('../pages/solutions').default,
// 	icon: require('../assets/svg/solutions/user-performance.svg'),
// 	iconHover: require('../assets/svg/solutions/user-performance-white.svg'),
// 	h1: 'Be data driven.',
// 	h1Image: require('../assets/svg/solutions/user-performance-big.svg'),
// 	s1h1:
// 		'The modern business is data-driven. Data empowers companies to make decisions based on facts, trends and empirical evidence, rather than guess-work and opinion. Arthos provides an analytics solution that gives clients the insights they need to continously improve and adapt. ',
// 	metaTitle: 'Arthos | User and performance analytics | d:code:it',
// 	metaDescription: 'Make informed decisions. Gain a holistic view of user behaviour, application interaction and performance via custom dashboards built to your needs.',
// 	scrollingSection: {
// 		/*video: [
//             {
//                 file: require('../assets/solutions/03-insightsandanalytics/1286x720/video.mp4'),
//                 type: 'mp4'
//             },
//             {
//                 file: require('../assets/solutions/03-insightsandanalytics/1286x720/video.webm'),
//                 type: 'webm'
//             }
//         ],*/
// 		img: require('../assets/solutions/03-User-Performance-Analitics.png'),
// 		texts: [
// 			{
// 				title: 'Build with metrics in mind',
// 				paragraphs: ['Develop your applications with built-in custom metrics to ensure you gain the insight you need. Define your end goal with core KPIs and measure the success of your applications.'],
// 			},
// 			{
// 				title: 'Create custom dashboards',
// 				paragraphs: ['Gain a holistic view of user behaviour, application interaction and performance using custom dashboards built to your needs.'],
// 			},
// 			{
// 				title: 'Make informed decisions',
// 				paragraphs: ['Save time and resources by leveraging data to base future investments securely around your user and business needs. Understand where to adapt and improve. '],
// 			},
//
// 		],
// 	},
// 	appsSection: {
// 		title: 'Supporting all major analytics tools',
// 		apps: [{id: 'splunk', title: 'Splunk'}, {id: 'search', title: 'Elastic Search'}, {id: 'tableau', title: 'Tableau'}, {id: 'kibana', title: 'Kibana'}],
// 	},
// };
export const assessibility: IPageData = {
    id: "accessibility-compliance",
    name: "Accessibility compliance",
    comp: require("../pages/solutions").default,
    compMobile: require("../pages/solutions/mobile").default,
    icon: require("../assets/svg/solutions/assessibility.svg"),
    iconHover: require("../assets/svg/solutions/assessibility-white.svg"),
    h1: "Make apps accessible.",
    h1Image: require("../assets/svg/solutions/assessibility-big.svg"),
    s1h1: "Adapting applications to comply with changing accessibility legislation can be costly and complex. The accessibility solution helps you meet the needs of every end-user with straightforward adherence to ADA and all major compliance regulations with minimum to no development effort.",
    metaTitle: "Accessibility Compliance | d:code:it",
    metaDescription:
        "Meet the needs of every end-user with straightforward adherence to ADA and all major compliance regulations with minimum to no development effort.",
    scrollingSection: {
        img: require("../assets/images/solutions/accessibility-compliance.png"),
        video: [
            {
                file: require("../assets/solutions/04-accessibility/03-Accessibility-Compliance.mp4"),
                type: "mp4",
            },
            // {
            //     file: require('../assets/solutions/04-accessibility/1286x720/video.webm'),
            //     type: 'webm'
            // }
        ],
        //img: require('../assets/solutions/04-Accessibility-Compliance.png'),
        texts: [
            {
                title: "Adapt Legacy And New Applications",
                paragraphs: [
                    "Whether your applications are brand new, legacy or third party, our advanced accessibility solution instantly adapts them to meet all ADA compliance regulations with AAA accreditation.",
                ],
            },
            {
                title: "Fast, straightforward compliance",
                paragraphs: [
                    "Ensure your applications comply with changing legislation instantly and without development effort via a range of accessibility themes and features.",
                ],
            },
            {
                title: "Accessibility for every need ",
                paragraphs: [
                    "Empower your users to customise the way they view their applications. Launch accessibility mode to activate high contrast themes, adjustable font size, zoom and complete machine readability.",
                ],
            },
        ],
    },
};
// export const selfservice: IPageData = {
// 	id: 'user-training-toolkit',
// 	name: 'User training toolkit ',
// 	comp: require('../pages/solutions').default,
// 	icon: require('../assets/svg/solutions/training.svg'),
// 	iconHover: require('../assets/svg/solutions/training-white.svg'),
// 	h1: 'Increase adoption rates.',
// 	h1Image: require('../assets/svg/solutions/training-big.svg'),
// 	s1h1:
// 		'Adapting to new technology and fully leveraging its value is imperative to the modern organisation. Arthos\' user training toolkit fast-tracks learning processes to ensure users take full advantage of the latest tools and features.',
// 	metaTitle: 'Arthos | User Training Toolkit | d:code:it',
// 	metaDescription: 'Fast-track learning processes and increase adoption rates with Arthos’ user trainer tool-kit. Create custom videos, tool tips and onboarding experiences.',
// 	scrollingSection: {
// 		/*video: [
//             {
//                 file: require('../assets/solutions/05-usertrainingtoolkit/1286x720/video.mp4'),
//                 type: 'mp4'
//             },
//             {
//                 file: require('../assets/solutions/05-usertrainingtoolkit/1286x720/video.webm'),
//                 type: 'webm'
//             }
//         ],*/
// 		img: require('../assets/solutions/05-User-Training-Toolkit.png'),
// 		texts: [
// 			{
// 				title: 'Customise your onboarding experience',
// 				paragraphs: ['Guide your users with tailored onboarding experiences. Create tool tips and videos to introduce new features and processes. Ensure your teams gain maximum utility from your applications. '],
// 			},
// 			{
// 				title: 'Ensure widespread success of new tools',
// 				paragraphs: ['Deliver new applications with full confidence in utility and usability. Increase widespread adoption rates and drive the modernisation of processes and workflows. '],
// 			},
// 			{
// 				title: 'Save time and resources',
// 				paragraphs: ['Avoid tedious, unsuccessful software training sessions and poor adoption rates with practical and relevant user training. Push new features and applications instantly with all the necessary training. '],
// 			},
//
// 		],
// 	},
// };
// export const designdevelopmentgovernance: IPageData = {
// 	id: 'design-and-development-governance',
// 	name: 'Design and development governance',
// 	comp: require('../pages/solutions').default,
// 	icon: require('../assets/svg/solutions/design.svg'),
// 	iconHover: require('../assets/svg/solutions/design-white.svg'),
// 	h1: 'Unify teams around the world.',
// 	h1Image: require('../assets/svg/solutions/design-big.svg'),
// 	s1h1:
// 		'Dispersed teams without centralised processes independently reinvent products and components, creating inconsistent, disjointed experiences. Arthos\' design and development governance solution connects teams to deliver unified results at a global scale.',
// 	metaTitle: 'Arthos | Design and Development Governance | d:code:it ',
// 	metaDescription: 'Unify teams around the world with a design system and ready-to-use component library. Reduce repetitive work and deliver on-brand experiences at scale.',
// 	scrollingSection: {
// 		/*video: [
//             {
//                 file: require('../assets/solutions/06-designanddevgovernance/1286x720/video.mp4'),
//                 type: 'mp4'
//             },
//             {
//                 file: require('../assets/solutions/06-designanddevgovernance/1286x720/video.webm'),
//                 type: 'webm'
//             }
//         ],*/
// 		img: require('../assets/solutions/06-Design&Development-Complience.png'),
// 		texts: [
// 			{
// 				title: 'Single source of truth: The design system',
// 				paragraphs: ['Design systems are a living set of guidelines that unify design and development. Arthos provides a design system solution that ensures all applications and products follow and maintain the same set of brand and style guidelines.'],
// 			},
// 			{
// 				title: 'Reduce repetitive work using a component library',
// 				paragraphs: ['Reusing established, tried and tested components allows developers to focus on solving real problems. Arthos’ customisable, ready-to-use component library streamlines development and ensures consistent delivery at scale. '],
// 			},
// 			{
// 				title: 'Support multiple custom themes and branding',
// 				paragraphs: ['Whether it’s your worskpace or tray icon, brand Arthos the way you want. Create different themes and views depending on the role or use case. Automatically update your brand and theme when required using the design system. '],
// 			},
// 			{
// 				title: 'Ensure consistency at scale',
// 				paragraphs: ['Perform expert quality assurance and acceptance. Ensure your design and components are always up to date and on-brand by running rapid, automated UI tests against design system guidelines.'],
// 			},
// 			{
// 				title: 'Give your developers what they need',
// 				paragraphs: ['Arthos comes with a built-in DevOps toolkit to ensure rapid and efficient development and deployment. Dynamically configure user-specific workspaces or introduce new applications instantly with the Arthos Configuration Manager. Guarantee high performance and reliability.'],
// 			},
//
// 		],
// 	},
// };
export const advancedworkspacemanagement: IPageData = {
    id: "advanced-workspace-management",
    name: "Advanced workspace management",
    comp: require("../pages/solutions").default,
    compMobile: require("../pages/solutions/mobile").default,
    icon: require("../assets/svg/solutions/advanced-workspace.svg"),
    iconHover: require("../assets/svg/solutions/advanced-workspace-white.svg"),
    h1: "Create seamless user experiences.",
    h1Image: require("../assets/svg/solutions/advanced-workspace-big.svg"),
    s1h1: "Enterprise desktops are unable to deliver the speed and ease we have come to expect from technology. The advanced workspace management and layouting solution revolutionises the end-user experience, streamlines processes and drives business output.",
    metaTitle: "Advanced Workspace Management | d:code:it",
    metaDescription:
        "Create custom layouts for every business line and role. Revolutionise the end-user experience, streamline processes and drive business output.",
    scrollingSection: {
        img: require("../assets/images/solutions/advanced-workspace-management.png"),
        video: [
            {
                file: require("../assets/solutions/07-advancedlayouting/04-Advanced-Workspace-Management.mp4"),
                type: "mp4",
            },
            // {
            //     file: require('../assets/solutions/07-advancedlayouting/1286x720/video.webm'),
            //     type: 'webm'
            // }
        ],
        //img: require('../assets/solutions/07-Advanced-Workspace-Management.png'),
        texts: [
            {
                title: "Industry-leading layouting features",
                paragraphs: [
                    "Create multiple customised layouts for every business role and need. Empower users to create their own fully flexible layouts and organise applications the way they want. ",
                ],
            },
            {
                title: "Manage multiple workspaces",
                paragraphs: [
                    "Pre-configure, save and restore layouts with related context. Detect new configurations and adapt workspaces to be accessed from any device, anywhere with automatic device and resolution adaption. ",
                ],
            },
            {
                title: "Streamline workflows",
                paragraphs: [
                    "Streamline workflows and consolidate fragmented processes. Save time and maximise productivity.",
                ],
            },
        ],
    },
};

export const solutions: IPageData = {
    id: "solutions",
    name: "Solutions",
    comp: require("../pages/solutions").default,
    compMobile: require("../pages/solutions/mobile").default,
    metaTitle,
    metaDescription,
    items: [
        digitalTransformation,
        interoperability,
        assessibility,
        advancedworkspacemanagement,
        microservice,
        //analytics,
        //selfservice,
        //designdevelopmentgovernance,
    ],
};

/*********** About Us Page ***************************/

export const aboutus: IPageData = {
    id: "about-us",
    name: "About Us",
    comp: require("../pages/about-us/aboutV4").default,
    h1: "We see and do things differently.",
    h1Image: require("../assets/svg/combined-shape.svg"),
    metaTitle: "About us | d:code:it",
    metaDescription:
        "We are a diverse team of engineers, designers and industry specialists who share a fascination with new technologies and the opportunities they present.",
    s1h1: "We are a diverse team of engineers, designers and industry specialists who share a fascination with new technologies and the opportunities they present.",
    additionalSections: [
        {
            id: "how-started",
            title: "How it all started",
            description:
                "We began as a small team of individuals who had worked together for many years across various industries. Our shared frustrations and desire to do things the right way led us to brave it alone with no business plan, no mission statement, and no funding.\n",
            mobile: "We began as a small team of individuals who had worked together for many years across various industries. Our shared frustrations and desire to do things the right way led us to brave it alone with no business plan, no mission statement, and no funding.\n",
        },
        {
            id: "the-journey",
            title: "The journey",
            description:
                "Since 2011, we’ve explored and pioneered new technologies, spearheading micro-application design and development in HTML5, iOS and Android since their infancy. We understand what’s a fad, what has promise, and what is here to stay.",
            mobile: "Since 2011, we’ve explored and pioneered new technologies, spearheading micro-application design and development in HTML5, iOS and Android since their infancy. We understand what’s a fad, what has promise, and what is here to stay.",
        },
        {
            id: "what-achieved",
            title: "What we’ve achieved",
            description:
                "We have built numerous applications that nobody imagined could’ve existed. Applications that are live, in use and powering multi-billion dollar daily turnover. Each solution has been, and continues to be, built in record time and in our own unorthodox way. ",
            mobile: "We have built numerous applications that nobody imagined could’ve existed. Applications that are live, in use and powering multi-billion dollar daily turnover. Each solution has been, and continues to be, built in record time and in our own unorthodox way. ",
        },
        {
            id: "where-today",
            title: "Where we are today",
            description:
                "We are self funded, with offices in London and Vienna. Our experience in mission critical application development has led us to build ground-breaking enterprise products designed to drive digital transformation and continuous innovation. ",
            mobile: "We are self funded, with offices in London and Vienna. Our experience in mission critical application development has led us to build ground-breaking enterprise products designed to drive digital transformation and continuous innovation. ",
        },
    ],
};

export const aboutUsData = {
    leadership: [
        {
            key: "kerem",
            fullName: "Kerem Ozelli",
            firstName: "Kerem",
            lastName: "Ozelli",
            position: "Founder, Director",
            description:
                "Kerem has held senior positions in financial services for 30+ years around the globe creating and managing numerous digital businesses. His eccentricity and creativity drive the team to constantly innovate, discover and question the status quo.",
            image: {
                //large: require('../assets/images/about-us/kozelli/v2/kerem@3x.jpg'), TODO for v4
                large: require("../assets/images/about-us/kozelli/kerem@3x.jpg"),
                medium: require("../assets/images/about-us/kozelli/kerem@2x.jpg"),
                small: require("../assets/images/about-us/kozelli/kerem.jpg"),
            },
        },
        {
            key: "joanne",
            fullName: "Joanne Gibson",
            firstName: "Joanne",
            lastName: "Gibson",
            position: "CEO",
            description:
                "Jo held senior leadership roles in financial services for 18+ years managing numerous transformation and productivity initiatives. She is the anchor of the company. Her pragmatism and reason guide and unify the team.",
            image: {
                large: require("../assets/images/about-us/jgibson/jo@3x.jpg"),
                medium: require("../assets/images/about-us/jgibson/jo@2x.jpg"),
                small: require("../assets/images/about-us/jgibson/jo.jpg"),
            },
        },
        {
            key: "bernhard",
            fullName: "Bernhard Strasser",
            firstName: "Bernhard",
            lastName: "Strasser",
            position: "CTO",
            description:
                "Bernhard has developed full stack software solutions for a number of industries from gaming to finance. He is the one who makes the impossible, possible. He pushes us to use new technologies and break boundaries in what we do.",
            image: {
                large: require("../assets/images/about-us/bstrasser/bernhard@3x.jpg"),
                medium: require("../assets/images/about-us/bstrasser/bernhard@2x.jpg"),
                small: require("../assets/images/about-us/bstrasser/bernhard.jpg"),
            },
        },
        {
            key: "harald",
            fullName: "Harald Fresacher",
            firstName: "Harald",
            lastName: "Fresacher",
            position: "CIO",
            description:
                "Harald has 25 years experience architecting real-time, mission critical technologies and has founded multiple tech startups. A pioneer of microservice architectures, he is an impeccable engineer whose knowledge drives our vision.",
            image: {
                large: require("../assets/images/about-us/harald/harald@3x.jpg"),
                medium: require("../assets/images/about-us/harald/harald@2x.jpg"),
                small: require("../assets/images/about-us/harald/harald.jpg"),
            },
        },
        {
            key: "mark",
            fullName: "Mark Hodges",
            firstName: "Mark",
            lastName: "Hodges",
            position: "CFO",
            description:
                "Mark has 30+ years financial management experience holding senior positions in major accounting firms, assisting start ups and overseeing mergers and acquisitions. He has been with us from the start, and keeps us on track through our journey.",
            image: {
                large: require("../assets/images/about-us/mark/mark@3x.jpg"),
                medium: require("../assets/images/about-us/mark/mark@2x.jpg"),
                small: require("../assets/images/about-us/mark/mark.jpg"),
            },
        },
        /* person removed 27.11.2023
				{
					key: "team6",
					fullName: "First Lastname",
					firstName: "Firstname",
					lastName: "Lastname",
					position: "TITLE",
					description:
							"Description.",
					image: {
							large: require("../assets/images/about-us/team6/team6@3x.jpg"),
							medium: require("../assets/images/about-us/team6/team6@2x.jpg"),
							small: require("../assets/images/about-us/team6/team6.jpg"),
					},
			},
			*/
    ],
    backgroundApproach: [
        {
            title: "Our Background",
            description:
                "We are the pioneers of HTML5 and microservice, micro-app architectures. For 10+ years we have worked with major financial institutions and global enterprises to help them unleash their digital potential. ",
        },
        {
            title: "Our Approach",
            description:
                "Every project, product and solution is unique and tailored to the specific need. Our approach is a fusion of latest technologies, intuitive design and out-of-the-box thinking. We support businesses in creating, innovating and evolving.",
        },
    ],
};

/*********** About Us Page ***************************/

/*********** Contact Us Page ***************************/
export const contact: IPageData = {
    id: "contact",
    name: "Contact",
    comp: require("../pages/contact").default,
    h1: "Get in touch.",
    metaTitle: "Contact | d:code:it",
    metaDescription: "We see and do things differently. Get in touch.",
};

export const contactData = {
    emails: ["hello@dcodeit.com"],
    addresses: [
        {
            title: "London",
            lines: [
                "5a Colbeck Mews",
                "London, SW7 4LX",
                "United Kingdom",
                "Tel. +44 203 1264 951",
            ],
        },
        {
            title: "Vienna",
            lines: [
                "Rotenturmstraße 16-18",
                "1010 Vienna",
                "Austria",
                "Tel. +43 1 3430152",
            ],
        },
    ],
};

/*********** Contact Us Page ***************************/

/*********** Privacy Policy Page ***************************/

export const privacyPolicy: IPageData = {
    id: "privacy-policy",
    name: "Privacy Policy",
    comp: require("../pages/privacy-policy").default,
    h1: "Privacy Policy",
    hideInNav: true,
    metaTitle: "Privacy Policy | d:code:it",
    metaDescription:
        "Digital product studio helping global banks and enterprises transform the way they work, think and perform.",
};

/*********** Privacy Policy Page ***************************/

export const clientportal: IPageData = {
    id: "client-portal",
    name: "Client Portal",
    comp: require("../pages/client-portal").default,
    metaTitle: "Client Portal | d:code:it",
    metaDescription:
        "Digital product studio helping global banks and enterprises transform the way they work, think and perform.",
};

const Pages: IPageData[] = [
    home,
    products,
    //	solutions,
    //	services,
    services,
    aboutus,
    contact,
    privacyPolicy,
    clientportal,
];
export const Products = [arthos, matrix, axcept, analytix, introx, nexus];
export const routeData = [...Pages, ...Products];
export default Pages;
