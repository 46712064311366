import * as React from "react"
import { isMobile } from "../../utils";
export interface IAppContextValue {
    currentSection: number;
    setCurrentSection: (section: number) => void;
    isMobile: boolean;
}
export const AppContext = React.createContext<IAppContextValue>({
    currentSection: 0,
    setCurrentSection: () => void 0,
    isMobile: isMobile()
})