import * as React from 'react';

export default (InnerComponent) =>

	class MountManager extends React.Component<any, any> {
		public state = {
			resolution: {
				width: 0,
				height: 0,
			},
			isChanging: false,
		};
		constructor(props) {
			super(props);
			this.resize = this.resize.bind(this);
		}
		private async resize() {
			await this.setState({
				isChanging: true,
			});
			await this.setState({
				width: window.innerWidth,
				height: window.innerHeight,
			});
			await this.setState({
				isChanging: false,
			});
		}

		public componentDidMount(): void {
			window.addEventListener('resize', this.resize, false);
		}
		public componentWillUnmount(): void {
			window.removeEventListener('resize', this.resize, false);
		}

		public render() {
			return this.state.isChanging ? null : <InnerComponent {...this.props}  resolution={this.state.resolution}/>;
		}
	};
