import * as React from 'react';

import Page from '../../components/page';
import { SectionProps } from '../../lib/types/section';

import isEqual from 'lodash/fp/isEqual';
import './index.less';
import '../../lib/styles/plyr.css';
import { isIPad, isPortrait, isSafari, isIE } from '../../utils';

import SVG from 'react-inlinesvg';

import ParticleImage from '../../components/particle-image';
import ExploreSolutions from '../../components/explore-solutions';
import Apps from './apps';

import { withRouter } from 'react-router-dom';
import { H1, H2, H3 } from '../../components/animated-container/h';
import IPageData from '../../lib/types/pagedata';

class Solutions extends React.Component<any, {}> {
	public _isMounted: boolean = false;
	public scrollingSection = undefined;

	constructor(props: any) {
		super(props);
	}
	public shouldComponentUpdate(nextProps, nextState) {
		if (isEqual(this.props, nextProps)) {
			return false;
		}
		return true;
	}
	public componentDidMount(): void {
		this._isMounted = true;
	}
	public componentWillUnmount() {
		this._isMounted = false;
	}
	public getSelectedPage() {
		const solutions: IPageData = (window as any).__PAGES__.find((el:any) => el.id === 'solutions');
		const pathArray = this.props.location.pathname.split('/');
		const selectedId = pathArray[pathArray.length - 1];
		const selected = solutions?.items?.find((el) => el.id === selectedId);
		if (!selected) { return solutions?.items[0]; }
		return selected;
	}
	public render() {
		const selectedPage = this.getSelectedPage();
		const classLeft = isPortrait() ? 'section-right perc50' : 'section-left perc50';
		const classRight = isPortrait() ? 'section-left perc50' : 'section-right perc50';
		const image = isIPad() || isSafari() ? <SVG style={{marginRight: '40px'}} width={'100%'} height={'100%'} className="hover" src={selectedPage.h1Image}/> : isIE() ? <img	src={selectedPage.h1Image} className={'ng42-svg-icon '}/> : <ParticleImage className={'hero-image'} imageUrl={selectedPage.h1Image}/>;

		const sectionMain: SectionProps = {
			id: 'main',
			title: 'We change the way enterprises work',
			color: 'white',
			content: <>

				<div className={classRight} id={'solutions-intro-image'}>
					{image}
				</div>
				<div className={classLeft} id={'solutions-intro-text'}>
					<H2 className={'xs color-gray'}>{selectedPage.name}</H2>
					<H1 s={'separator l blue'} tabIndex={-1}>{selectedPage.h1}</H1>
				</div>

			</>,
		};
		const sectionSubtitle: SectionProps = {
			id: 'subtitle',
			title: '',
			color: 'white',
			content: <>
				<H2 s={'separator l blue'} className={'text-transform-unset'}>{selectedPage.s1h1}</H2>
			</>,
		};

		const sections: SectionProps[] = [sectionMain, sectionSubtitle];
		if (selectedPage.scrollingSection) {

			selectedPage.scrollingSection.texts.forEach((t, i) => {
				const section: SectionProps = {
					id: 'solutions-scrollingSection-' + i,
					title: '',
					color: 'white',
					content: <>
						<div ref={(ref) => this.scrollingSection = ref} className={'section-right perc35 solutions-scrolling-section-content'} id={'solutions-' + selectedPage.id + '-scrolling-section-content'}>
							<div className={'solutions-scrolling-section-content-part scrolling-subsection-' + i}>
								<H2 className={'xxs color-gray'}>{selectedPage.name}</H2>
								<H3 s={'separator s blue'}>{t.title}</H3>
								{t.paragraphs.map((p, j) => <p key={'p' + j}>{p}</p>)}
							</div>
						</div>
					</>,
				};
				sections.push(section);
			});

		}

		if (selectedPage.appsSection) {
			const appSection = {
				id: 'appSections',
				title: '',
				subscroll: true,
				color: 'white',
				content: <><Apps page={selectedPage}/></>,
			};
			sections.push(appSection);
		}


		const sectionFinal: SectionProps = {
			id: 'exploreSolutions',
			title: '',
			subscroll: false,
			color: 'white',
			content: <><ExploreSolutions selected={selectedPage.id} hideTitle/>
			</>,
		};
		sections.push(sectionFinal);

		return <Page
			isMobile={this.props.isMobile}
			id={'solutions-page'}
			sections={sections}
			scrollingSection={selectedPage.scrollingSection}
		/>;
	}
}

export default withRouter(Solutions);
