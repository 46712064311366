import * as React from 'react';
import classSet from '../../utils/classSet';
import './index.less';
import { SectionProps } from '../../lib/types/section';
import { isMobile } from '../../utils';

interface ISectionState {
	activeSubSection: number;
	continueScrolling: boolean;
}

class Section extends React.PureComponent<SectionProps, ISectionState> {
	private swipeStartY = 0
	public readonly state = {
		activeSubSection: 0,
		continueScrolling: false
	};
	public section: HTMLElement | null = null;
	public static defaultProps = {
		active: false,
		color: 'white',
	};
	public letterSelector = null;
	constructor(props: SectionProps) {
		super(props);

	}
	public componentDidMount(): void {

		$('section').addClass('initial-loading');
		setTimeout(()=>{
			$('section').removeClass('initial-loading');
		}, 2000);
	}

	public onActivate() {
		this.section?.focus();
		const videos = $(this.section).length && $(this.section).find('video');
		if (videos && videos.length) {
			videos.each((i, el) => {
				if($(el).hasClass('no-auto-play'))return;
				el.play();
			});
		}
	}

	public onDeactivate() {
		const videos = $(this.section).length && $(this.section).find('video');
		if (videos && videos.length) {
			videos.each((i, el) => {
				el.load();
				el.pause();
			});
		}
	}
	public componentDidUpdate(prevProps: Readonly<SectionProps>, prevState: Readonly<{}>, snapshot?: any): void {
		if (!prevProps.active && this.props.active) { //loading section
			this.onActivate();
		}
		if (prevProps.active && !this.props.active) { //unloading section
			this.onDeactivate();
		}

		if (this.props.subscroll && this.props.subscroll.parent && this.props.subscroll.children) {
			const children = $(this.props.subscroll.parent).find(this.props.subscroll.children).removeClass('active');
			$(children[this.state.activeSubSection]).addClass('active');
			children.each((i, el) => {
				const translate = (i - this.state.activeSubSection) * 100;
				$(el).addClass('subsection-scrolling').css({transform: 'translateY(' + translate + '%)'});
			});
			$('body').addClass('no-pointer-events');
			window.noPointerEventsTimeout = setTimeout(() => {
				$('body').removeClass('no-pointer-events');
				clearTimeout(window.noPointerEventsTimeout);
			}, 800);
		}

	}
	public render() {
		const allowScrolling = this.props.subscroll && typeof this.props.subscroll === 'boolean';
		const classes = classSet({
			'website-section': window.innerWidth > 767 ? true : false,
			'mobile-website-section': window.innerWidth < 768 ? true : false,
			[this.props.className] : true,
			[this.props.color] : true,
			'overflow-y-auto': allowScrolling,
			withFooter: this.props.withFooter
		});
		const content = typeof this.props.content === 'function' ? this.props.content(this.props) : this.props.content;
		return <section
			data-index={this.props.index}
			ref={(ref: HTMLElement) => {
				return this.section = ref
			}}
			style={this.props.style ? {...this.props.style, border: this.state.continueScrolling ? "5px solid red" : "0px"} : {border: this.state.continueScrolling ? "5px solid red" : "0px"}}
			id={this.props.pageId + '-' + this.props.id}
			className={classes}
			>
			{content}
			</section>;
	}
}
export default Section;
